/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import api from 'helpers/api';
import { get } from 'lodash';
import { checkAccess } from 'industry/helpers';
import {
  updateQuantity,
  getBookings,
  addManualEntry,
  addManualEntryComponents,
  getBookingsUrl,
  getListOfWarehouses,
  updateBatch,
  updateToBook,
  duplicateBookingItem,
  deleteBookingItem,
  editBooking,
  checkBookingSap,
} from '../../actions';
import OrderBookingsTable from './components/OrderBookingsTable';
import OrderBookingItemsTable from './components/OrderBookingItemsTable';
import OrderBookingsEditModal from './components/OrderBookingsEditModal';
import OrderBookingsEditToBookModal from './components/OrderBookingsEditToBookModal';
import ManualEntryModal from './components/ManualEntryModal';
import ManualEntryComponentsModal from './components/ManualEntryComponentsModal';
import SAPResponseModal from './components/SAPResponseModal';
import EditBatchAndWarehouseModal from './components/EditBatchAndWarehouseModal';
import GenerateForERPResponseModal from './components/GenerateForERPResponseModal';
import BookTTResponseModal from './components/BookTTResponseModal';
import BookGoodsResponseModal from './components/BookGoodsResponseModal';
import FinalBookingModal from './components/FinalBookingModal';
import { TableButtons } from '../../../../../shared/index';

class OrderBookings extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      bookings: [],
      isLoadingBookings: true,
      bookingItems: [],
      isEditModalOpen: false,
      isEditToBookModalOpen: false,
      isManualEntryModalOpen: false,
      isManualEntryComponentsModalOpen: false,
      selectedBooking: null,
      selectedBookingItem: null,
      currentUrl: null,
      isLoadingPostInSAP: false,
      isLoadingRequest: false,
      postInSAPResponse: {},
      isSAPResponseModalOpen: false,
      isEditBatchAndWarehouseModalOpen: false,
      listOfWarehouses: [],
      isGenerateForERPResponseModalOpen: false,
      generateForERPResponse: {},
      bookTTResponse: {},
      isBookTTModalOpen: false,
      bookGoodsResponse: {},
      isBookGoodsModalOpen: false,
      selectedBookingIds: [],
      selectedBookingsPool: [],
      joinBookingsModalStatus: false,
      bookingSapStatus: '',
      loadingSapStatus: true,
      count: 0,
      isOrderBookingAdmin: false,
      selectedAscDescOrderBooking: 'desc',
      selectedSortOrderBooking: 'booked_at',
      selectedBookingType: '',
      hasParentBooking: false,
      isOrderBookingOnlyEdit: false,
      canEditFinishedBookingDates: false,
    };
  }

  componentDidMount() {
    const { companyId, locationId, order } = this.props;

    checkAccess('order-bookings-admin', companyId)
      .then((re) => {
        const access = re?.data?.access;
        if (access === 10) {
          this.setState({
            isOrderBookingAdmin: true,
          });
        } else {
          checkAccess('order-bookings-only-edit', companyId)
            .then((res) => {
              const onlyEditAccess = res?.data?.access;
              if (onlyEditAccess === 10) {
                this.setState({
                  isOrderBookingOnlyEdit: true,
                });
              }
            });
        }
      });

    checkAccess('x-edit-finished-booking-date', companyId)
      .then((res) => {
        const response = get(res, 'data.access', 0);
        if (response === 10) this.setState((prevState) => ({ ...prevState, canEditFinishedBookingDates: true }));
      });

    let warehouseFilters = '';
    if (order && order.id) {
      warehouseFilters += `&order=${order.id}`;
    }

    this.fetchBookings();
    this.fetchBookingSap();

    getListOfWarehouses(locationId, warehouseFilters)
      .then((res) => {
        this.setState({
          listOfWarehouses: res.data.results || [],
        });
      });
  }

  onSaveBookingsModal = (quantity_booked_new) => {
    const { selectedBookingItem, selectedBooking, currentUrl } = this.state;
    const { getOrderId, refreshOrderTabs } = this.props;

    if (quantity_booked_new === null || Number.isNaN(quantity_booked_new)) {
      this.closeEditModal();
    } else {
      this.setState({
        isEditModalOpen: false,
        selectedBookingItem: null,
        isLoadingBookings: true,
      });

      const orderId = getOrderId();

      const id = selectedBookingItem.id || null;
      const data = {
        quantity_booked_new,
      };

      updateQuantity(id, data)
        .then(() => {
          if (currentUrl) {
            getBookingsUrl(currentUrl)
              .then((res) => {
                refreshOrderTabs();
                // eslint-disable-next-line eqeqeq
                const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
                this.setState({
                  selectedBooking: updatedSelectedBooking || null,
                  bookingItems: updatedSelectedBooking.order_item_bookings || null,
                  bookings: get(res, 'data.results') || [],
                  count: get(res, 'data.count'),
                  nextBookings: get(res, 'data.next'),
                  previousBookings: get(res, 'data.previous'),
                  isLoadingBookings: false,
                });
              })
              .catch((e) => console.error('Error while fetching bookings', e));
          } else {
            const filter = this.bookingsFilters();
            getBookings(orderId, filter)
              .then((res) => {
                refreshOrderTabs();
                // eslint-disable-next-line eqeqeq
                const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
                this.setState({
                  selectedBooking: updatedSelectedBooking || null,
                  bookingItems: updatedSelectedBooking.order_item_bookings || null,
                  bookings: get(res, 'data.results') || [],
                  count: get(res, 'data.count'),
                  nextBookings: get(res, 'data.next'),
                  previousBookings: get(res, 'data.previous'),
                  isLoadingBookings: false,
                });
              })
              .catch((e) => console.error('Error while fetching bookings', e));
          }
        });
    }
  }

  onSaveBookingsToBookModal = (quantity_total_booked) => {
    const { selectedBookingItem, selectedBooking, currentUrl } = this.state;
    const { getOrderId, refreshOrderTabs } = this.props;

    if (quantity_total_booked === null || Number.isNaN(quantity_total_booked)) {
      this.closeEditToBookModal();
    } else {
      this.setState({
        isEditToBookModalOpen: false,
        selectedBookingItem: null,
        isLoadingBookings: true,
      });

      const orderId = getOrderId();

      const id = selectedBookingItem.id || null;
      const data = {
        quantity_total_booked,
      };

      updateToBook(id, data)
        .then(() => {
          if (currentUrl) {
            getBookingsUrl(currentUrl)
              .then((res) => {
                refreshOrderTabs();
                // eslint-disable-next-line eqeqeq
                const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
                this.setState({
                  selectedBooking: updatedSelectedBooking || null,
                  bookingItems: updatedSelectedBooking.order_item_bookings || null,
                  bookings: get(res, 'data.results') || [],
                  count: get(res, 'data.count'),
                  nextBookings: get(res, 'data.next'),
                  previousBookings: get(res, 'data.previous'),
                  isLoadingBookings: false,
                });
              })
              .catch((e) => console.error('Error while fetching bookings', e));
          } else {
            const filter = this.bookingsFilters();
            getBookings(orderId, filter)
              .then((res) => {
                refreshOrderTabs();
                // eslint-disable-next-line eqeqeq
                const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
                this.setState({
                  selectedBooking: updatedSelectedBooking || null,
                  bookingItems: updatedSelectedBooking.order_item_bookings || null,
                  bookings: get(res, 'data.results') || [],
                  count: get(res, 'data.count'),
                  nextBookings: get(res, 'data.next'),
                  previousBookings: get(res, 'data.previous'),
                  isLoadingBookings: false,
                });
              })
              .catch((e) => console.error('Error while fetching bookings', e));
          }
        });
    }
  }

  onSaveManualEntryModal = (bookings_entry, custom_values = {}) => {
    const { getOrderId, refreshOrderTabs } = this.props;

    if (!bookings_entry.length) {
      this.closeManualEntryModal();
    } else {
      this.setState({
        isManualEntryModalOpen: false,
        isLoadingBookings: true,
      });

      const orderId = getOrderId();

      const data = {
        order_item_bookings: bookings_entry,
        ...custom_values,
      };

      addManualEntry(data)
        .then(() => {
          const filter = this.bookingsFilters();
          getBookings(orderId, filter)
            .then((res) => {
              refreshOrderTabs();
              this.setState({
                page: 1,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        });
    }
  }

  onSaveManualEntryComponentsModal = (bookings_entry, custom_values = {}) => {
    const { getOrderId, refreshOrderTabs } = this.props;

    if (!bookings_entry.length) {
      this.closeManualEntryComponentsModal();
    } else {
      this.setState({
        isManualEntryComponentsModalOpen: false,
        isLoadingBookings: true,
      });

      const orderId = getOrderId();

      const data = {
        order_item_bookings: bookings_entry,
        ...custom_values,
      };

      addManualEntryComponents(data)
        .then(() => {
          const filter = this.bookingsFilters();
          getBookings(orderId, filter)
            .then((res) => {
              refreshOrderTabs();
              this.setState({
                page: 1,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        });
    }
  }

  onSaveBatchOrWarehouse = (orderItemBookingId, data) => {
    const { selectedBooking, currentUrl } = this.state;
    const { getOrderId, refreshOrderTabs } = this.props;

    this.setState({
      isEditBatchAndWarehouseModalOpen: false,
      selectedBookingItem: null,
      isLoadingBookings: true,
    });

    const orderId = getOrderId();

    updateBatch(orderItemBookingId, data)
      .then(() => {
        if (currentUrl) {
          getBookingsUrl(currentUrl)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        } else {
          const filter = this.bookingsFilters();
          getBookings(orderId, filter)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        }
      });
  }

  getPaginatedBookings = (url) => {
    this.setState({
      isLoadingBookings: true,
    });
    api.get(url)
      .then((res) => {
        this.setState({
          bookings: get(res, 'data.results') || [],
          count: get(res, 'data.count'),
          nextBookings: get(res, 'data.next'),
          previousBookings: get(res, 'data.previous'),
          isLoadingBookings: false,
          currentUrl: url,
        });
      })
      .catch((e) => console.log('Error while fetching bookings data', e));
  }

  bookingsFilters = () => {
    const { locationConfig } = this.props;
    const { selectedAscDescOrderBooking, selectedSortOrderBooking, selectedBookingType, hasParentBooking } = this.state;
    let filters = '';

    const asc = selectedAscDescOrderBooking === 'desc' ? '-' : '';
    let sortKey = selectedSortOrderBooking;
    if (selectedSortOrderBooking === 'booked_goods_user') {
      sortKey = 'booked_goods_user__last_name';
    }
    filters += `&order_by=${asc}${sortKey}`;

    if (selectedBookingType) {
      filters += `&booking_type=${selectedBookingType}`;
    }

    const checkForBookingsJoin = locationConfig?.join_bookings || false;

    if (checkForBookingsJoin) {
      filters += `&has_parent_booking=${hasParentBooking || false}`;
    }
    return filters;
  };

  fetchBookings = () => {
    const { getOrderId } = this.props;
    this.setState({
      isLoadingBookings: true,
    });

    const orderId = getOrderId();

    const filter = this.bookingsFilters();
    getBookings(orderId, filter)
      .then((res) => {
        this.setState({
          bookings: get(res, 'data.results') || [],
          count: get(res, 'data.count'),
          nextBookings: get(res, 'data.next'),
          previousBookings: get(res, 'data.previous'),
          isLoadingBookings: false,
        });
      })
      .catch((e) => console.error('Error while fetching bookings', e));
  }

  fetchBookingSap = () => {
    setTimeout(() => {
      checkBookingSap()
        .then((re) => {
          this.setState({
            bookingSapStatus: get(re, 'data'),
            loadingSapStatus: false,
          });
        });
    }, 3000);
  }

  exportSAPBookings = () => {
    const { selectedBooking } = this.state;

    api.get(`/api/v1/orders/order_bookings/${selectedBooking?.id}/sap_export/`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'SAP');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  handleDuplicate = (orderItemBookingId, data) => {
    const { selectedBooking, currentUrl } = this.state;
    const { getOrderId, refreshOrderTabs } = this.props;

    this.setState({
      selectedBookingItem: null,
      isLoadingBookings: true,
    });

    const orderId = getOrderId();

    duplicateBookingItem(orderItemBookingId, data)
      .then(() => {
        if (currentUrl) {
          getBookingsUrl(currentUrl)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        } else {
          const filter = this.bookingsFilters();
          getBookings(orderId, filter)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        }
      });
  }

  handleDelete = (orderItemBookingId, data) => {
    const { selectedBooking, currentUrl } = this.state;
    const { getOrderId, refreshOrderTabs } = this.props;

    this.setState({
      selectedBookingItem: null,
      isLoadingBookings: true,
    });

    const orderId = getOrderId();

    deleteBookingItem(orderItemBookingId, data)
      .then(() => {
        if (currentUrl) {
          getBookingsUrl(currentUrl)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        } else {
          const filter = this.bookingsFilters();
          getBookings(orderId, filter)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        }
      });
  }

  handleSelectBooking = (row) => {
    this.setState({
      selectedBooking: row,
      bookingItems: row.order_item_bookings,
      page: 2,
    });
  }

  handleReturnButton = () => {
    const { currentUrl } = this.state;
    const { getOrderId } = this.props;

    this.setState({
      isLoadingBookings: true,
      page: 1,
    });

    const orderId = getOrderId();

    if (currentUrl) {
      getBookingsUrl(currentUrl)
        .then((res) => {
          this.setState({
            bookings: get(res, 'data.results') || [],
            count: get(res, 'data.count'),
            nextBookings: get(res, 'data.next'),
            previousBookings: get(res, 'data.previous'),
            isLoadingBookings: false,
            selectedBooking: null,
            bookingItems: [],
          });
        })
        .catch((e) => console.error('Error while fetching bookings', e));
    } else {
      const filter = this.bookingsFilters();
      getBookings(orderId, filter)
        .then((res) => {
          this.setState({
            bookings: get(res, 'data.results') || [],
            count: get(res, 'data.count'),
            nextBookings: get(res, 'data.next'),
            previousBookings: get(res, 'data.previous'),
            isLoadingBookings: false,
            selectedBooking: null,
            bookingItems: [],
          });
        })
        .catch((e) => console.error('Error while fetching bookings', e));
    }
  }

  handleEdit = (row) => {
    const { isReadOnly } = this.props;

    if (!isReadOnly) {
      const selectedBookingItem = row.original || null;

      this.setState({
        isEditModalOpen: true,
        selectedBookingItem,
      });
    } else {
      return '';
    }
  }

  handleEditToBook = (row) => {
    const { isReadOnly } = this.props;

    if (!isReadOnly) {
      const selectedBookingItem = row.original || null;

      this.setState({
        isEditToBookModalOpen: true,
        selectedBookingItem,
      });
    } else {
      return '';
    }
  }

  handleManualEntry = () => {
    this.setState({
      isManualEntryModalOpen: true,
    });
  }

  handleManualEntryComponents = () => {
    this.setState({
      isManualEntryComponentsModalOpen: true,
    });
  }

  closeEditBatchAndWarehouseModal = () => {
    this.setState({
      isEditBatchAndWarehouseModalOpen: false,
    });
  }

  handleEditBatchAndWarehouse = (row) => {
    const selectedBookingItem = row.original || null;

    this.setState({
      isEditBatchAndWarehouseModalOpen: true,
      selectedBookingItem,
      selectedBookingItemRow: row,
    });
  }

  closeManualEntryModal = () => {
    this.setState({
      isManualEntryModalOpen: false,
    });
  }

  closeManualEntryComponentsModal = () => {
    this.setState({
      isManualEntryComponentsModalOpen: false,
    });
  }

  closeEditModal = () => {
    this.setState({
      isEditModalOpen: false,
      selectedBookingItem: null,
    });
  }

  closeEditToBookModal = () => {
    this.setState({
      isEditToBookModalOpen: false,
      selectedBookingItem: null,
    });
  }

  exportToExcelBookings = () => {
    const { getOrderId } = this.props;

    const orderId = getOrderId();
    api.get(`/api/v1/orders/order_item_bookings/?order=${orderId}&format=xlsx&count=999999`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'bookings_list.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  generateForERP = () => {
    const { selectedBooking, currentUrl } = this.state;
    const { refreshOrderTabs, getOrderId } = this.props;

    const orderId = getOrderId();

    this.setState({
      isLoadingRequest: true,
      isLoadingBookings: true,
    });

    api.get(`/api/v1/orders/order_bookings/${selectedBooking.id}/generate_booking/`, {})
      .then((re) => {
        this.setState({
          isGenerateForERPResponseModalOpen: true,
          generateForERPResponse: re && re.data ? re.data : {},
        });
        if (currentUrl) {
          getBookingsUrl(currentUrl)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
                isLoadingRequest: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        } else {
          const filter = this.bookingsFilters();
          getBookings(orderId, filter)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
                isLoadingRequest: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoadingPostInSAP: false,
          isLoadingRequest: false,
          isLoadingBookings: false,
        });
      });
  }

  handleBookTT = (isFinalBooking) => {
    this.setState({
      isFinalBookingModalOpen: false,
    }, () => {
      this.bookTT(isFinalBooking);
    });
  }

  openFinalBookingModal = (booking_date) => {
    this.setState({
      isFinalBookingModalOpen: true,
      booking_date,
    });
  }

  bookTT = (isFinalBooking) => {
    const { selectedBooking, currentUrl, booking_date } = this.state;
    const { refreshOrderTabs, getOrderId } = this.props;

    const orderId = getOrderId();
    const data = {
      final_booking: isFinalBooking,
    };

    if (booking_date) {
      data.booking_date = booking_date;
    }

    this.setState({
      isLoadingPostInSAP: true,
      isLoadingRequest: true,
      isLoadingBookings: true,
    });

    api.post(`/api/v1/orders/order_bookings/${selectedBooking.id}/sap_book_tt/`, data)
      .then((re) => {
        this.setState({
          isLoadingPostInSAP: false,
          isLoadingRequest: false,
          bookTTResponse: re && re.data ? re.data : {},
          isBookTTModalOpen: true,
          booking_date: null,
        });

        if (currentUrl) {
          getBookingsUrl(currentUrl)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        } else {
          const filter = this.bookingsFilters();
          getBookings(orderId, filter)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoadingPostInSAP: false,
          isLoadingRequest: false,
        });
      });
  }

  bookGoods = (booking_date) => {
    const { selectedBooking, currentUrl } = this.state;
    const { refreshOrderTabs, getOrderId } = this.props;

    const orderId = getOrderId();

    this.setState({
      isLoadingPostInSAP: true,
      isLoadingRequest: true,
      isLoadingBookings: true,
    });

    const data = {};

    if (booking_date) {
      data.booking_date = booking_date;
    }
    api.post(`/api/v1/orders/order_bookings/${selectedBooking.id}/sap_book_goods/`, data)
      .then((re) => {
        this.setState({
          bookGoodsResponse: re && re.data ? re.data : {},
          isBookGoodsModalOpen: true,
          isLoadingPostInSAP: false,
          isLoadingRequest: false,
        });

        if (currentUrl) {
          getBookingsUrl(currentUrl)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        } else {
          const filter = this.bookingsFilters();
          getBookings(orderId, filter)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoadingPostInSAP: false,
          isLoadingRequest: false,
        });
      });
  }

  bookReceipt = (booking_date) => {
    const { selectedBooking, currentUrl } = this.state;
    const { refreshOrderTabs, getOrderId } = this.props;

    const orderId = getOrderId();

    this.setState({
      isLoadingPostInSAP: true,
      isLoadingRequest: true,
      isLoadingBookings: true,
    });

    const data = {};

    if (booking_date) {
      data.booking_date = booking_date;
    }
    api.post(`/api/v1/orders/order_bookings/${selectedBooking.id}/sap_book_receipt/`, data)
      .then((re) => {
        this.setState({
          bookGoodsResponse: re && re.data ? re.data : {},
          isBookGoodsModalOpen: true,
          isLoadingPostInSAP: false,
          isLoadingRequest: false,
        });

        if (currentUrl) {
          getBookingsUrl(currentUrl)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        } else {
          const filter = this.bookingsFilters();
          getBookings(orderId, filter)
            .then((res) => {
              refreshOrderTabs();
              // eslint-disable-next-line eqeqeq
              const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
              this.setState({
                selectedBooking: updatedSelectedBooking || null,
                bookingItems: updatedSelectedBooking.order_item_bookings || null,
                bookings: get(res, 'data.results') || [],
                count: get(res, 'data.count'),
                nextBookings: get(res, 'data.next'),
                previousBookings: get(res, 'data.previous'),
                isLoadingBookings: false,
              });
            })
            .catch((e) => console.error('Error while fetching bookings', e));
        }
      })
      .catch(() => {
        this.setState({
          isLoadingPostInSAP: false,
          isLoadingRequest: false,
        });
      });
  }

  closeBookGoodsResponseModal = () => {
    this.setState({
      isBookGoodsModalOpen: false,
    });
  }

  closeBookTTResponseModal = () => {
    this.setState({
      isBookTTModalOpen: false,
    });
  }

  closeSAPResponseModal = () => {
    this.setState({
      isSAPResponseModalOpen: false,
    });
  }

  closeGenerateForERPResponseModal = () => {
    this.setState({
      isGenerateForERPResponseModalOpen: false,
    });
  }

  selectBooking = (e, rowObject) => {
    const { selectedBookingIds, selectedBookingsPool } = this.state;
    let selectedIdsPool = selectedBookingIds.slice();
    let selectedBookings = selectedBookingsPool.slice();
    const bookingId = rowObject.id;
    if (e.target.checked === true) {
      if (selectedIdsPool.includes(bookingId) === false) {
        selectedIdsPool.push(bookingId);
        selectedBookings.push(rowObject);
      }
    } else {
      selectedIdsPool = selectedIdsPool.filter((x) => x !== bookingId);
      selectedBookings = selectedBookings.filter((x) => x.id !== bookingId);
    }
    this.setState({ selectedBookingIds: selectedIdsPool, selectedBookingsPool: selectedBookings });
  }

  handleBulkAsignModalOpen = () => {
    this.setState({
      joinBookingsModalStatus: true,
    });
  }

  handleBulkAsignModalClose = () => {
    this.setState({
      joinBookingsModalStatus: false,
    }, () => this.fetchBookings());
  }

  handleSelectedBookingsClear = () => {
    this.setState({
      selectedBookingIds: [],
      selectedBookingsPool: [],
    });
  }

  handleArchiveBooking = (row) => {
    const { currentUrl } = this.state;
    const { refreshOrderTabs } = this.props;

    this.setState({
      isLoadingBookings: true,
    });

    const id = row && row.original && row.original.id ? row.original.id : null;

    editBooking(id, { is_archived: true })
      .then(() => {
        if (currentUrl) {
          this.getPaginatedBookings(currentUrl);
          refreshOrderTabs();
        } else {
          this.fetchBookings();
          refreshOrderTabs();
        }
      });
  }

  handleSapStatusMessage = (status) => {
    switch (status) {
      case 'OK': return 'OK';
      case 'Timeout': return 'NOK';
      case 'Exception': return 'NOK';
      default: return '';
    }
  };

  handleSapStatusColor = (status) => {
    switch (status) {
      case 'OK': return 'success';
      case 'Timeout': return 'danger';
      case 'Exception': return 'danger';
      default: return 'dark-blue';
    }
  };

  handleSortingBookings = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSortOrderBooking: column,
      selectedAscDescOrderBooking: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchBookings();
    });
  }

  handleFilteringBookings = (key, value) => {
    this.setState({
      [key]: value,
    }, () => {
      this.fetchBookings();
    });
  }

  refetchOrderBookingItems = () => {
    const { selectedBooking, currentUrl } = this.state;
    const { getOrderId, refreshOrderTabs } = this.props;

    const orderId = getOrderId();

    if (currentUrl) {
      getBookingsUrl(currentUrl)
        .then((res) => {
          refreshOrderTabs();
          // eslint-disable-next-line eqeqeq
          const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
          this.setState({
            selectedBooking: updatedSelectedBooking || null,
            bookingItems: updatedSelectedBooking.order_item_bookings || null,
            bookings: get(res, 'data.results') || [],
            count: get(res, 'data.count'),
            nextBookings: get(res, 'data.next'),
            previousBookings: get(res, 'data.previous'),
            isLoadingBookings: false,
          });
        })
        .catch((e) => console.error('Error while fetching bookings', e));
    } else {
      const filter = this.bookingsFilters();
      getBookings(orderId, filter)
        .then((res) => {
          refreshOrderTabs();
          // eslint-disable-next-line eqeqeq
          const updatedSelectedBooking = res.data && res.data.results ? res.data.results.find((x) => x.id == selectedBooking.id) : null;
          this.setState({
            selectedBooking: updatedSelectedBooking || null,
            bookingItems: updatedSelectedBooking.order_item_bookings || null,
            bookings: get(res, 'data.results') || [],
            count: get(res, 'data.count'),
            nextBookings: get(res, 'data.next'),
            previousBookings: get(res, 'data.previous'),
            isLoadingBookings: false,
          });
        })
        .catch((e) => console.error('Error while fetching bookings', e));
    }
  }

  render() {
    const {
      isEditModalOpen,
      isEditToBookModalOpen,
      selectedBookingItem,
      nextBookings,
      previousBookings,
      bookings,
      isLoadingBookings,
      bookingItems,
      isManualEntryModalOpen,
      isManualEntryComponentsModalOpen,
      page,
      selectedBooking,
      isLoadingPostInSAP,
      isSAPResponseModalOpen,
      postInSAPResponse,
      isEditBatchAndWarehouseModalOpen,
      listOfWarehouses,
      isGenerateForERPResponseModalOpen,
      generateForERPResponse,
      bookTTResponse,
      isBookTTModalOpen,
      bookGoodsResponse,
      isBookGoodsModalOpen,
      isLoadingRequest,
      isFinalBookingModalOpen,
      selectedBookingIds,
      selectedBookingsPool,
      joinBookingsModalStatus,
      bookingSapStatus,
      loadingSapStatus,
      count,
      isOrderBookingAdmin,
      selectedBookingItemRow,
      selectedAscDescOrderBooking,
      selectedSortOrderBooking,
      selectedBookingType,
      isOrderBookingOnlyEdit,
      hasParentBooking,
      canEditFinishedBookingDates,
    } = this.state;

    const {
      t,
      company_short_code,
      locationId,
      isReadOnly,
      order,
      order_groups_hidden_columns,
      getUserByID,
      companyId,
      currentUser,
      locationConfig,
      locationCode,
    } = this.props;

    const groups = order && order.groups ? order.groups : [];
    const isAscaliaAdmin = currentUser && currentUser.is_admin ? currentUser.is_admin : false;
    const enable_book_tt_and_goods = locationConfig && locationConfig.enable_book_tt_and_goods ? locationConfig.enable_book_tt_and_goods : false;
    const checkForBookingsJoin = locationConfig && locationConfig.join_bookings ? locationConfig.join_bookings : false;
    const always_allow_booking_confirmation = locationConfig && locationConfig.always_allow_booking_confirmation ? locationConfig.always_allow_booking_confirmation : false;
    const bookings_archive = locationConfig && locationConfig.bookings_archive ? locationConfig.bookings_archive : false;
    const can_override_production_line = locationConfig && locationConfig.can_override_production_line ? locationConfig.can_override_production_line : false;

    const prodLineCode = selectedBooking?.production_line?.code;
    const isLocationConfigEnabled = locationConfig?.enable_book_receipt || false;
    const isLocationCodeValid = locationCode === 'djhrana';
    const isProdLineCodeValid = (prodLineCode === 'tdh_ov08' || prodLineCode === 'tdh_ov09' || prodLineCode === 'tdh_ov10');
    const enable_book_receipt = isLocationCodeValid ? (isLocationConfigEnabled && isProdLineCodeValid) : isLocationConfigEnabled;

    return (
      <React.Fragment>
        {
          isLoadingPostInSAP &&
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{t('page_content.orders.order_details.bookings_tab.loading_post_in_SAP')}</p>
            <div className="SAP_loader" />
          </div>
        }

        {
          page === 1 &&
          <OrderBookingsTable
            isReadOnly={isReadOnly}
            bookings={bookings}
            handleSelectBooking={this.handleSelectBooking}
            getUserByID={getUserByID}
            isLoadingBookings={isLoadingBookings}
            isLoadingPostInSAP={isLoadingPostInSAP}
            exportToExcelBookings={this.exportToExcelBookings}
            handleManualEntry={this.handleManualEntry}
            handleManualEntryComponents={this.handleManualEntryComponents}
            selectedBookingIds={selectedBookingIds}
            selectedBookingsPool={selectedBookingsPool}
            joinBookingsModalStatus={joinBookingsModalStatus}
            selectBooking={this.selectBooking}
            handleBulkAsignModalOpen={this.handleBulkAsignModalOpen}
            handleBulkAsignModalClose={this.handleBulkAsignModalClose}
            checkForBookingsJoin={checkForBookingsJoin}
            handleSelectedBookingsClear={this.handleSelectedBookingsClear}
            handleArchiveBooking={this.handleArchiveBooking}
            bookingSapStatus={bookingSapStatus}
            loadingSapStatus={loadingSapStatus}
            handleSapStatusMessage={this.handleSapStatusMessage}
            handleSapStatusColor={this.handleSapStatusColor}
            handleSortingBookings={this.handleSortingBookings}
            columnOrder={{ asc: selectedAscDescOrderBooking, column: selectedSortOrderBooking }}
            handleFilteringBookings={this.handleFilteringBookings}
            selectedBookingType={selectedBookingType}
            bookings_archive={bookings_archive}
            isOrderBookingOnlyEdit={isOrderBookingOnlyEdit}
            hasParentBooking={hasParentBooking}
          // postInSAP={this.postInSAP}
          />
        }

        {
          page === 2 &&
          <OrderBookingItemsTable
            locationId={locationId}
            t={t}
            bookingItems={bookingItems}
            isReadOnly={isReadOnly}
            handleEdit={this.handleEdit}
            handleEditToBook={this.handleEditToBook}
            handleManualEntry={this.handleManualEntry}
            selectedBooking={selectedBooking}
            handleEditBatchAndWarehouse={this.handleEditBatchAndWarehouse}
            getUserByID={getUserByID}
            handleReturnButton={this.handleReturnButton}
            company_short_code={company_short_code}
            exportSAPBookings={this.exportSAPBookings}
            generateForERP={this.generateForERP}
            openFinalBookingModal={this.openFinalBookingModal}
            bookGoods={this.bookGoods}
            isLoadingRequest={isLoadingRequest}
            handleDuplicate={this.handleDuplicate}
            handleDelete={this.handleDelete}
            enable_book_tt_and_goods={enable_book_tt_and_goods}
            isAscaliaAdmin={isAscaliaAdmin}
            always_allow_booking_confirmation={always_allow_booking_confirmation}
            bookingSapStatus={bookingSapStatus}
            loadingSapStatus={loadingSapStatus}
            handleSapStatusMessage={this.handleSapStatusMessage}
            handleSapStatusColor={this.handleSapStatusColor}
            checkForBookingsJoin={checkForBookingsJoin}
            isOrderBookingAdmin={isOrderBookingAdmin}
            enable_book_receipt={enable_book_receipt}
            bookReceipt={this.bookReceipt}
            isOrderBookingOnlyEdit={isOrderBookingOnlyEdit}
            canEditFinishedBookingDates={canEditFinishedBookingDates}
            can_override_production_line={can_override_production_line}
            refetchOrderBookingItems={this.refetchOrderBookingItems}
          />
        }

        <OrderBookingsEditModal
          isEditModalOpen={isEditModalOpen}
          selectedBookingItem={selectedBookingItem}
          closeEditModal={this.closeEditModal}
          onSaveBookingsModal={this.onSaveBookingsModal}
        />
        <OrderBookingsEditToBookModal
          isEditToBookModalOpen={isEditToBookModalOpen}
          selectedBookingItem={selectedBookingItem}
          closeEditToBookModal={this.closeEditToBookModal}
          onSaveBookingsToBookModal={this.onSaveBookingsToBookModal}
        />
        <ManualEntryModal
          isManualEntryModalOpen={isManualEntryModalOpen}
          onSaveManualEntryModal={this.onSaveManualEntryModal}
          closeManualEntryModal={this.closeManualEntryModal}
          groups={groups}
          order_groups_hidden_columns={order_groups_hidden_columns}
          company_short_code={company_short_code}
          locationId={locationId}
          companyId={companyId}
        />
        <ManualEntryComponentsModal
          isManualEntryComponentsModalOpen={isManualEntryComponentsModalOpen}
          onSaveManualEntryComponentsModal={this.onSaveManualEntryComponentsModal}
          closeManualEntryComponentsModal={this.closeManualEntryComponentsModal}
          groups={groups.filter((x) => x.material_code === 'BOM')}
          order_groups_hidden_columns={order_groups_hidden_columns}
          company_short_code={company_short_code}
          locationId={locationId}
        />
        <SAPResponseModal
          isSAPResponseModalOpen={isSAPResponseModalOpen}
          closeSAPResponseModal={this.closeSAPResponseModal}
          postInSAPResponse={postInSAPResponse}
        />
        {
          isEditBatchAndWarehouseModalOpen &&
          <EditBatchAndWarehouseModal
            isEditBatchAndWarehouseModalOpen={isEditBatchAndWarehouseModalOpen}
            selectedBookingItem={selectedBookingItem}
            selectedBookingItemRow={selectedBookingItemRow}
            closeEditBatchAndWarehouseModal={this.closeEditBatchAndWarehouseModal}
            onSaveBatchOrWarehouse={this.onSaveBatchOrWarehouse}
            listOfWarehouses={listOfWarehouses}
            companyId={companyId}
            order={order}
            enable_book_receipt={enable_book_receipt}
          />
        }

        <GenerateForERPResponseModal
          t={t}
          isGenerateForERPResponseModalOpen={isGenerateForERPResponseModalOpen}
          closeGenerateForERPResponseModal={this.closeGenerateForERPResponseModal}
          generateForERPResponse={generateForERPResponse}
        />
        <BookTTResponseModal
          t={t}
          isBookTTModalOpen={isBookTTModalOpen}
          closeBookTTResponseModal={this.closeBookTTResponseModal}
          bookTTResponse={bookTTResponse}
        />
        <BookGoodsResponseModal
          t={t}
          isBookGoodsModalOpen={isBookGoodsModalOpen}
          closeBookGoodsResponseModal={this.closeBookGoodsResponseModal}
          bookGoodsResponse={bookGoodsResponse}
        />
        <FinalBookingModal
          isFinalBookingModalOpen={isFinalBookingModalOpen}
          handleBookTT={this.handleBookTT}
        />
        {
          page === 1 &&
          <div style={{ float: 'right' }}>
            <TableButtons previous={previousBookings} next={nextBookings} fetchFunction={this.getPaginatedBookings} count={count} />
          </div>
        }
      </React.Fragment>
    );
  }
}

OrderBookings.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  t: PropTypes.func.isRequired,
  locationConfig: PropTypes.object.isRequired,
  getOrderId: PropTypes.func.isRequired,
  company_short_code: PropTypes.string.isRequired,
  locationCode: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  order: PropTypes.object,
  order_groups_hidden_columns: PropTypes.array,
  refreshOrderTabs: PropTypes.func,
  getUserByID: PropTypes.func,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    locationConfig: (state.app.locationConfig !== null && Object.prototype.hasOwnProperty.call(state.app.locationConfig, 'config'))
      ? state.app.locationConfig.config
      : {},
  };
};

export default connect(mapStateToProps, null)(withTranslation()(OrderBookings));
