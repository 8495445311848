import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Modal } from 'shared';
import { modalSizes } from 'shared/constants';
import '../styles.scss';

import RatingScreen from './RatingComponents/RatingScreen';

const RatingsModal = ({ workerDetails, isOpen, handleCloseModal }) => {
  return (
    <Modal
      title=""
      size={modalSizes.full}
      isOpen={isOpen}
      handleClose={handleCloseModal}
    >
      <div className="ratings_container">
        <RatingScreen selectedWorker={workerDetails} />
      </div>
    </Modal>
  );
};

RatingsModal.propTypes = {
  workerDetails: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default withTranslation()(RatingsModal);
