import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { SizeMe } from 'react-sizeme';
import { withTranslation } from 'react-i18next';
import {
  Bar,
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  BarChart,
  LineChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

// import { selectModalStyles } from 'styles/modules/reactSelect';
import { Table } from 'shared';
import './styles.scss';

const Recapitulation = ({ t }) => {
  const data = [
    { name: 'Sij', currentYear: 4500, previousYear: 3800, amt: 2300 },
    { name: 'Velj', currentYear: 4200, previousYear: 3600, amt: 2400 },
    { name: 'Ožu', currentYear: 4700, previousYear: 4000, amt: 2500 },
    { name: 'Tra', currentYear: 4900, previousYear: 4200, amt: 2600 },
    { name: 'Svi', currentYear: 5300, previousYear: 4400, amt: 2700 },
    { name: 'Lip', currentYear: 5700, previousYear: 4600, amt: 2800 },
    { name: 'Srp', currentYear: 6000, previousYear: 5000, amt: 2900 },
    { name: 'Kol', currentYear: 6200, previousYear: 5400, amt: 3000 },
    { name: 'Ruj', currentYear: 6100, previousYear: 5200, amt: 3100 },
    { name: 'Lis', currentYear: 5900, previousYear: 4800, amt: 3200 },
    { name: 'Stu', currentYear: 5500, previousYear: 4400, amt: 3300 },
    { name: 'Pro', currentYear: 5000, previousYear: 4000, amt: 3400 },
  ];

  const departments = [
    {
      id: 1,
      name: 'Dep 1',
      data: [
        { name: 'Sij', currentYear: 4500, previousYear: 3800, amt: 2300 },
        { name: 'Velj', currentYear: 4200, previousYear: 3600, amt: 2400 },
        { name: 'Ožu', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Tra', currentYear: 4900, previousYear: 4200, amt: 2600 },
        { name: 'Svi', currentYear: 5300, previousYear: 4400, amt: 2700 },
        { name: 'Lip', currentYear: 5700, previousYear: 4600, amt: 2800 },
        { name: 'Srp', currentYear: 6000, previousYear: 5000, amt: 2900 },
        { name: 'Kol', currentYear: 6200, previousYear: 5400, amt: 3000 },
        { name: 'Ruj', currentYear: 6100, previousYear: 5200, amt: 3100 },
        { name: 'Lis', currentYear: 5900, previousYear: 4800, amt: 3200 },
        { name: 'Stu', currentYear: 5500, previousYear: 4400, amt: 3300 },
        { name: 'Pro', currentYear: 5000, previousYear: 4000, amt: 3400 },
      ],
      currentTotal: 110000,
      plannedTotal: 130000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 2,
      name: 'Department 2',
      data: [
        { name: 'Sij', currentYear: 4200, previousYear: 3600, amt: 2400 },
        { name: 'Velj', currentYear: 3900, previousYear: 3400, amt: 2500 },
        { name: 'Ožu', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Tra', currentYear: 4600, previousYear: 4000, amt: 2700 },
        { name: 'Svi', currentYear: 5000, previousYear: 4200, amt: 2800 },
        { name: 'Lip', currentYear: 5400, previousYear: 4400, amt: 2900 },
        { name: 'Srp', currentYear: 5700, previousYear: 4800, amt: 3000 },
        { name: 'Kol', currentYear: 5900, previousYear: 5200, amt: 3100 },
        { name: 'Ruj', currentYear: 5800, previousYear: 5000, amt: 3200 },
        { name: 'Lis', currentYear: 5600, previousYear: 4600, amt: 3300 },
        { name: 'Stu', currentYear: 5200, previousYear: 4200, amt: 3400 },
        { name: 'Pro', currentYear: 4700, previousYear: 3800, amt: 3500 },
      ],
      currentTotal: 120000,
      plannedTotal: 140000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 3,
      name: 'Department 3',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 90000,
      plannedTotal: 100000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 4,
      name: 'Department 4',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 100000,
      plannedTotal: 120000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 5,
      name: 'Department 5',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 100000,
      plannedTotal: 120000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 6,
      name: 'Department 6',
      data: [
        { name: 'Sij', currentYear: 4500, previousYear: 3800, amt: 2300 },
        { name: 'Velj', currentYear: 4200, previousYear: 3600, amt: 2400 },
        { name: 'Ožu', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Tra', currentYear: 4900, previousYear: 4200, amt: 2600 },
        { name: 'Svi', currentYear: 5300, previousYear: 4400, amt: 2700 },
        { name: 'Lip', currentYear: 5700, previousYear: 4600, amt: 2800 },
        { name: 'Srp', currentYear: 6000, previousYear: 5000, amt: 2900 },
        { name: 'Kol', currentYear: 6200, previousYear: 5400, amt: 3000 },
        { name: 'Ruj', currentYear: 6100, previousYear: 5200, amt: 3100 },
        { name: 'Lis', currentYear: 5900, previousYear: 4800, amt: 3200 },
        { name: 'Stu', currentYear: 5500, previousYear: 4400, amt: 3300 },
        { name: 'Pro', currentYear: 5000, previousYear: 4000, amt: 3400 },
      ],
      currentTotal: 110000,
      plannedTotal: 130000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 7,
      name: 'Department 7',
      data: [
        { name: 'Sij', currentYear: 4200, previousYear: 3600, amt: 2400 },
        { name: 'Velj', currentYear: 3900, previousYear: 3400, amt: 2500 },
        { name: 'Ožu', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Tra', currentYear: 4600, previousYear: 4000, amt: 2700 },
        { name: 'Svi', currentYear: 5000, previousYear: 4200, amt: 2800 },
        { name: 'Lip', currentYear: 5400, previousYear: 4400, amt: 2900 },
        { name: 'Srp', currentYear: 5700, previousYear: 4800, amt: 3000 },
        { name: 'Kol', currentYear: 5900, previousYear: 5200, amt: 3100 },
        { name: 'Ruj', currentYear: 5800, previousYear: 5000, amt: 3200 },
        { name: 'Lis', currentYear: 5600, previousYear: 4600, amt: 3300 },
        { name: 'Stu', currentYear: 5200, previousYear: 4200, amt: 3400 },
        { name: 'Pro', currentYear: 4700, previousYear: 3800, amt: 3500 },
      ],
      currentTotal: 120000,
      plannedTotal: 140000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 8,
      name: 'Department 8',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 90000,
      plannedTotal: 100000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 9,
      name: 'Department 9',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 100000,
      plannedTotal: 120000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 10,
      name: 'Department 10',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 100000,
      plannedTotal: 120000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 11,
      name: 'Department 11',
      data: [
        { name: 'Sij', currentYear: 4500, previousYear: 3800, amt: 2300 },
        { name: 'Velj', currentYear: 4200, previousYear: 3600, amt: 2400 },
        { name: 'Ožu', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Tra', currentYear: 4900, previousYear: 4200, amt: 2600 },
        { name: 'Svi', currentYear: 5300, previousYear: 4400, amt: 2700 },
        { name: 'Lip', currentYear: 5700, previousYear: 4600, amt: 2800 },
        { name: 'Srp', currentYear: 6000, previousYear: 5000, amt: 2900 },
        { name: 'Kol', currentYear: 6200, previousYear: 5400, amt: 3000 },
        { name: 'Ruj', currentYear: 6100, previousYear: 5200, amt: 3100 },
        { name: 'Lis', currentYear: 5900, previousYear: 4800, amt: 3200 },
        { name: 'Stu', currentYear: 5500, previousYear: 4400, amt: 3300 },
        { name: 'Pro', currentYear: 5000, previousYear: 4000, amt: 3400 },
      ],
      currentTotal: 110000,
      plannedTotal: 130000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 12,
      name: 'Department 12',
      data: [
        { name: 'Sij', currentYear: 4200, previousYear: 3600, amt: 2400 },
        { name: 'Velj', currentYear: 3900, previousYear: 3400, amt: 2500 },
        { name: 'Ožu', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Tra', currentYear: 4600, previousYear: 4000, amt: 2700 },
        { name: 'Svi', currentYear: 5000, previousYear: 4200, amt: 2800 },
        { name: 'Lip', currentYear: 5400, previousYear: 4400, amt: 2900 },
        { name: 'Srp', currentYear: 5700, previousYear: 4800, amt: 3000 },
        { name: 'Kol', currentYear: 5900, previousYear: 5200, amt: 3100 },
        { name: 'Ruj', currentYear: 5800, previousYear: 5000, amt: 3200 },
        { name: 'Lis', currentYear: 5600, previousYear: 4600, amt: 3300 },
        { name: 'Stu', currentYear: 5200, previousYear: 4200, amt: 3400 },
        { name: 'Pro', currentYear: 4700, previousYear: 3800, amt: 3500 },
      ],
      currentTotal: 120000,
      plannedTotal: 140000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 13,
      name: 'Department 13',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 90000,
      plannedTotal: 100000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 14,
      name: 'Department 14',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 100000,
      plannedTotal: 120000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 15,
      name: 'Department 15',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 100000,
      plannedTotal: 120000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 16,
      name: 'Department 16',
      data: [
        { name: 'Sij', currentYear: 4500, previousYear: 3800, amt: 2300 },
        { name: 'Velj', currentYear: 4200, previousYear: 3600, amt: 2400 },
        { name: 'Ožu', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Tra', currentYear: 4900, previousYear: 4200, amt: 2600 },
        { name: 'Svi', currentYear: 5300, previousYear: 4400, amt: 2700 },
        { name: 'Lip', currentYear: 5700, previousYear: 4600, amt: 2800 },
        { name: 'Srp', currentYear: 6000, previousYear: 5000, amt: 2900 },
        { name: 'Kol', currentYear: 6200, previousYear: 5400, amt: 3000 },
        { name: 'Ruj', currentYear: 6100, previousYear: 5200, amt: 3100 },
        { name: 'Lis', currentYear: 5900, previousYear: 4800, amt: 3200 },
        { name: 'Stu', currentYear: 5500, previousYear: 4400, amt: 3300 },
        { name: 'Pro', currentYear: 5000, previousYear: 4000, amt: 3400 },
      ],
      currentTotal: 110000,
      plannedTotal: 130000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 17,
      name: 'Department 17',
      data: [
        { name: 'Sij', currentYear: 4200, previousYear: 3600, amt: 2400 },
        { name: 'Velj', currentYear: 3900, previousYear: 3400, amt: 2500 },
        { name: 'Ožu', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Tra', currentYear: 4600, previousYear: 4000, amt: 2700 },
        { name: 'Svi', currentYear: 5000, previousYear: 4200, amt: 2800 },
        { name: 'Lip', currentYear: 5400, previousYear: 4400, amt: 2900 },
        { name: 'Srp', currentYear: 5700, previousYear: 4800, amt: 3000 },
        { name: 'Kol', currentYear: 5900, previousYear: 5200, amt: 3100 },
        { name: 'Ruj', currentYear: 5800, previousYear: 5000, amt: 3200 },
        { name: 'Lis', currentYear: 5600, previousYear: 4600, amt: 3300 },
        { name: 'Stu', currentYear: 5200, previousYear: 4200, amt: 3400 },
        { name: 'Pro', currentYear: 4700, previousYear: 3800, amt: 3500 },
      ],
      currentTotal: 120000,
      plannedTotal: 140000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 18,
      name: 'Department 18',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 90000,
      plannedTotal: 100000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 19,
      name: 'Department 19',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 100000,
      plannedTotal: 120000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
    {
      id: 20,
      name: 'DepartmentDepartmentDepartment 20',
      data: [
        { name: 'Sij', currentYear: 4700, previousYear: 4000, amt: 2500 },
        { name: 'Velj', currentYear: 4400, previousYear: 3800, amt: 2600 },
        { name: 'Ožu', currentYear: 4900, previousYear: 4200, amt: 2700 },
        { name: 'Tra', currentYear: 5100, previousYear: 4400, amt: 2800 },
        { name: 'Svi', currentYear: 5500, previousYear: 4600, amt: 2900 },
        { name: 'Lip', currentYear: 5900, previousYear: 5000, amt: 3000 },
        { name: 'Srp', currentYear: 6200, previousYear: 5400, amt: 3100 },
        { name: 'Kol', currentYear: 6400, previousYear: 5600, amt: 3200 },
        { name: 'Ruj', currentYear: 6300, previousYear: 5400, amt: 3300 },
        { name: 'Lis', currentYear: 6100, previousYear: 5000, amt: 3400 },
        { name: 'Stu', currentYear: 5700, previousYear: 4600, amt: 3500 },
        { name: 'Pro', currentYear: 5200, previousYear: 4200, amt: 3600 },
      ],
      currentTotal: 100000,
      plannedTotal: 120000,
      numOfWorkers: 10,
      avgGross1: 10000,
      avgGross2: 11000,
      avgNetWithoutAdditional: 9000,
      avgNetWithAdditional: 10000,
      percentageFromCompanyBudget: 20,
    },
  ];

  const thisYearCompanyBudget = 8500;

  const months = ['Sij', 'Velj', 'Ožu', 'Tra', 'Svi', 'Lip', 'Srp', 'Kol', 'Ruj', 'Lis', 'Stu', 'Pro'];
  //   const graphColors = ['#A5D6A7', '#90CAF9', '#FFCC80', '#CE93D8', '#EF9A9A', '#80DEEA', '#C5E1A5', '#FFF59D', '#9FA8DA', '#F48FB1', '#BCAAA4', '#CFD8DC', '#FFE082', '#81D4FA', '#E6EE9C', '#80CBC4', '#B39DDB', '#FFAB91', '#9E9E9E', '#FFFFFF', '#F5F5F5', '#E0E0E0', '#AED581', '#FF8A65', '#EA80FC'];
  const graphColors = ['#4CAF50', '#2196F3', '#FF9800', '#9C27B0', '#F44336', '#00BCD4', '#8BC34A', '#FFEB3B', '#3F51B5', '#E91E63', '#795548', '#607D8B', '#FFC107', '#03A9F4', '#CDDC39', '#009688', '#673AB7', '#FF5722', '#000000', '#FFFFFF', '#EEEEEE', '#B0BEC5', '#00E676', '#FF3D00', '#D500F9'];

  const [selectedDepartments, setSelectedDepartments] = useState([departments[0], departments[1], departments[2], departments[3], departments[4], departments[5], departments[6], departments[7], departments[8]]);

  // eslint-disable-next-line no-unused-vars
  const [departmentColors, setDepartmentColors] = useState(
    departments.reduce((acc, dept, index) => {
      acc[dept.id] = graphColors[index % graphColors.length];
      return acc;
    }, {}),
  );

  const handleChangeDepartments = (selectedOptions) => {
    setSelectedDepartments(selectedOptions);
  };

  const multiSelectFixedOptionsAutoHeight = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      minWidth: '200px',
      height: '34px',
      padding: 0,
      fontSize: '13px',
      color: '#555',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: 34,
      padding: '0px 0px 0px 5px',
      overflowY: 'scroll',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0px 3px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      paddingRight: 10,
      color: 'black',
      svg: {
        width: '15px',
        height: '15px',
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      borderRadius: 0,
      zIndex: 2,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '13px',
      fontWeight: 500,
      padding: '6px 12px',
    }),
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
  };

  const renderCustomizedLegend = () => (
    <Select
      options={departments}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      placeholder={t('Odaberite odjele za usporedbu')}
      isClearable
      isMulti
      menuPosition="fixed"
      menuPlacement="top"
      onChange={handleChangeDepartments}
      value={selectedDepartments}
    //   hideSelectedOptions={false}
      styles={{
        ...multiSelectFixedOptionsAutoHeight,
        multiValue: (provided, state) => ({
          ...provided,
          backgroundColor: 'transparent',
          border: `5px solid ${departmentColors[state.data.id]}`,
          borderTop: 'none',
          borderRight: 'none',
          borderBottom: 'none',
          borderRadius: 2.5,
        }),
      }}
    />
  );

  const recalculatedData = months.map((month, index) => {
    const combined = { name: month };
    selectedDepartments.forEach((dept) => {
      const departmentData = dept.data[index];
      combined[dept.name] = departmentData?.currentYear;
    });
    return combined;
  });

  const customizedAxisTick = ({ x, y, payload }) => {
    const value = payload.value;
    const isLongName = value.length > 18;

    return (
      <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#666"
            transform="rotate(-25)"
            fontSize="11px"
          >
            {isLongName ? `${value.substring(0, 18)}...` : value}
          </text>
      </g>
    );
  };

  const tableColumnConfig = [
    {
      Header: () => <span>Ime</span>,
      accessor: 'name',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>Broj zaposlenih</span>,
      accessor: 'numOfWorkers',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>Prosječna bruto 1 plaća</span>,
      accessor: 'avgGross1',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>Prosječna bruto 2 plaća</span>,
      accessor: 'avgGross2',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>Prosječna neto plaća (bez dodataka)</span>,
      accessor: 'avgNetWithoutAdditional',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>Prosječna neto plaća (s dodacima)</span>,
      accessor: 'avgNetWithAdditional',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>Postotak od ukupnog planiranog budžeta</span>,
      accessor: 'percentageFromCompanyBudget',
      Cell: (row) => (row?.value ? `${row.value}%` : '-'),
      style: { cursor: 'default' },
    },
  ];

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const entriesPerColumn = 5;
      const columns = Math.ceil(payload.length / entriesPerColumn);

      return (
        <div
          style={{
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '10px',
            padding: '10px',
            position: 'relative',
            transform: 'translateY(-100%)',
            display: 'grid',
            gridTemplateColumns: `repeat(${columns}, auto)`,
            gap: '10px',
          }}
        >
          <p style={{ gridColumn: `span ${columns}`, fontWeight: 'bold' }}>{label}</p>
          {payload.map((value, index) => <p key={index} style={{ color: value.color, margin: 0 }}>{value.name}: {value.value}</p>)}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="recapitulation-container">
      <SizeMe>
        {({ size }) => (
            <>
                {/* Company budget line chart */}
                <div className="recapitulation-container__box">
                    <span className="recapitulation-container__box__title">{t('Budžet kompanije')}</span>
                    <ResponsiveContainer width="100%" height="100%" key={size.width}>
                        <LineChart
                          data={data}
                          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="name" axisLine={false} tickLine={false} interval={0} />
                            <YAxis axisLine={false} tickLine={false} />
                            <Tooltip contentStyle={{ borderRadius: '10px', border: '1px solid #ccc' }} />
                            <Legend
                              height={35}
                              iconSize={25}
                              verticalAlign="top"
                              iconType="plainline"
                            />
                            <ReferenceLine
                              isFront
                              y={thisYearCompanyBudget}
                              stroke="red"
                              strokeDasharray="3 3"
                              ifOverflow="extendDomain"
                              label={{
                                value: `${t('Ovogodišnji planirani budžet')} (${thisYearCompanyBudget})`,
                                position: 'insideBottomLeft',
                                fontSize: 14,
                                fill: 'red',
                              }}
                            />
                            <Line
                              type="monotone"
                              name={t('Prošla godina')}
                              dataKey="previousYear"
                              stroke="#8884d8"
                            />
                            <Line
                              type="monotone"
                              name={t('Trenutna godina')}
                              dataKey="currentYear"
                              stroke="#82ca9d"
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                {/* Stacked bar-chart */}
                <div className="recapitulation-container__box">
                    <span className="recapitulation-container__box__title">{t('Budžeti odjela')}</span>
                    <ResponsiveContainer width="100%" height="100%" key={size.width}>
                        <BarChart
                          data={departments}
                          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis tick={customizedAxisTick} height={75} dataKey="name" scale="band" interval={0} />
                            <YAxis axisLine={false} tickLine={false} />
                            <Tooltip contentStyle={{ borderRadius: '10px', border: '1px solid #ccc' }} />
                            <Legend
                              height={35}
                              iconSize={15}
                              verticalAlign="top"
                              iconType="square"
                            />
                            <Bar dataKey="currentTotal" stackId="a" fill="#8884d8" name={t('Trenutni budžet')} />
                            <Bar dataKey="plannedTotal" stackId="a" fill="#82ca9d" name={t('Planirani budget')} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                {/* Compare data line chart */}
                <div className="recapitulation-container__box">
                    <div className="recapitulation-container__box__title-and-select">
                        <div className="recapitulation-container__box__title-and-select__title-and-subtitle">
                            <span className="recapitulation-container__box__title-and-select__title-and-subtitle__title">
                                {t('Usporedite proračune odjela')}
                            </span>
                            {/* <span className="recapitulation-container__box__title-and-select__title-and-subtitle__subtitle">
                                {t('Ovi usporedni podaci temelje se na proračunima odjela')}
                            </span> */}
                        </div>
                        {/* <div className="recapitulation-container__box__title-and-select__select">
                            <Select
                              className="select-style"
                              options={[]}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              placeholder={t('Odaberite godinu za usporedbu')}
                              isClearable
                            //   onChange={(e) => handleFilterChange('activityFilter', e)}
                            //   value={activeFilterOptions.find((a) => a.id === filters?.activityFilter?.id) || ''}
                              styles={selectModalStyles}
                            />
                        </div> */}
                    </div>
                    <ResponsiveContainer width="100%" height="100%" key={size.width}>
                        <LineChart
                          data={recalculatedData}
                          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="name" axisLine={false} tickLine={false} interval={0} />
                            <YAxis axisLine={false} tickLine={false} />
                            <Tooltip content={customTooltip} />
                            <Legend
                              height={50}
                              verticalAlign="top"
                              content={renderCustomizedLegend}
                            />
                            {
                                selectedDepartments.map((department) => (
                                    <Line
                                      key={department.id}
                                      type="monotone"
                                      dataKey={department.name}
                                      name={department.name}
                                      stroke={departmentColors[department.id]}
                                    />
                                ))
                            }
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                {/* Departments table */}
                <div className="recapitulation-container__box__table">
                    <Table
                      style={{ userSelect: 'text' }}
                      columns={tableColumnConfig}
                      data={departments}
                      minRows={0}
                    //   stickyHeader
                      defaultPageSize={40}
                    //   loading={tableData.isLoading}
                    //   defaultSorted={[{ id: 'active_from', desc: true }]}
                    //   onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
                    //   handleClick={(rowInfo) => redirectToWorkerDetails(rowInfo)}
                    />
                    {/* <TableButtons
                      previous={tableData.previous}
                      next={tableData.next}
                      fetchFunction={fetchPaginatedWorkersList}
                      count={tableData.count}
                    /> */}
                </div>
            </>
        )}
      </SizeMe>
    </div>
  );
};

Recapitulation.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Recapitulation);
