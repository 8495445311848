import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Table, TableButtons } from 'shared';
import { defaultDateFormat } from 'shared/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { getProcurementItems, getPaginatedProcurementItems, getMaterialTypes } from '../actions';
import '../styles.scss';

const ProcurementItemsTable = ({ t, companyId, locationId, history }) => {
  const [tableData, setTableData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [filters, setFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'created_at',
    material_type: null,
    status: 'all',
  });
  const [materialTypes, setMaterialTypes] = useState({
    data: [],
    isLoading: false,
  });

  const fetchProcurementItems = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `company=${companyId}&limit=30&order_by=${asc}${filters?.selectedSort}`;

    if (filters?.materialType?.id) apiFilters += `&material_type=${filters?.materialType?.id}`;
    if (filters?.status && filters?.status !== 'all') apiFilters += `&delivery_date__isnull=${filters?.status !== 'delivered'}`;

    getProcurementItems(apiFilters)
      .then((res) => {
        const fetchedData = get(res, 'data.results', []);
        setTableData({
          data: fetchedData,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedProcurementItems = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedProcurementItems(url)
      .then((res) => {
        const fetchedData = get(res, 'data.results', []);
        setTableData({
          data: fetchedData,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchMaterialTypes = () => {
    const apiFilters = `company=${companyId}&limit=9999`;

    getMaterialTypes(apiFilters)
      .then((res) => {
        const fetchedData = get(res, 'data.results', []);
        setMaterialTypes({
          data: fetchedData,
          isLoading: false,
        });
      })
      .catch(() => {
        setMaterialTypes((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchMaterialTypes();
  }, []);

  useEffect(() => {
    fetchProcurementItems();
  }, [filters]);

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    sortKey = sortKey.replace('.', '__');

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const redirectToProcurementDetails = (id) => {
    if (id) history.push(`/${companyId}/industry/location/${locationId}/procurement/order/${id}`);
  };

  const installationLocationsOptions = [
    { id: 'factory', name: t('page_content.procurement.details.items_table.installation_location_factory') },
    { id: 'site', name: t('page_content.procurement.details.items_table.installation_location_site') },
  ];

  const statusDropdownOptions = [
    { value: 'all', label: t('page_content.procurement.details.items_table.all') },
    { value: 'delivered', label: t('page_content.procurement.details.items_table.delivered') },
    { value: 'not_delivered', label: t('page_content.procurement.details.items_table.not_delivered') },
  ];

  return (
    <div className="procurement-items">
          <div className="procurement-items__actions__filters">
            <Select
              className="select-style"
              options={materialTypes?.data || []}
              isLoading={materialTypes?.isLoading}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.procurement.details.items_table.table_column_material_type')}
              isClearable
              onChange={(e) => handleFilterChange('materialType', e || null)}
              value={filters?.materialType || null}
              styles={selectModalStyles}
            />

            <Select
              className="select-style"
              options={statusDropdownOptions || []}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(e) => handleFilterChange('status', e?.value || null)}
              value={statusDropdownOptions.find((o) => o.value === filters?.status) || null}
              styles={selectModalStyles}
            />
          </div>
          <div className="procurement-items__table">
            <Table
              style={{ userSelect: 'text' }}
              columns={[
                {
                  Header: () => <span>{t('page_content.procurement.table_column_label')}</span>,
                  accessor: 'procurement.external_id',
                  Cell: (row) => get(row, 'value.name', '-'),
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_item_name')}</span>,
                  accessor: 'name',
                  Cell: (row) => row?.value || '-',
                  width: 200,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_material_type')}</span>,
                  accessor: 'material_type.name',
                  Cell: (row) => row?.value || '-',
                  width: 125,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_unit')}</span>,
                  accessor: 'unit',
                  Cell: (row) => row?.value || '-',
                  width: 75,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_quantity')}</span>,
                  accessor: 'quantity',
                  Cell: (row) => row?.value || '-',
                  width: 90,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_included_reserve')}</span>,
                  // accessor: '',
                  //   Cell: (row) => row?.value || '-',
                  width: 90,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_installation_location')}</span>,
                  accessor: 'installation_location',
                  Cell: (row) => (row?.value ? installationLocationsOptions?.find((op) => op.id === row?.value)?.name : '-'),
                  width: 125,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_lot')}</span>,
                  accessor: 'lot',
                  Cell: (row) => row?.value || '-',
                  width: 125,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.planned_delivery_date')}</span>,
                  accessor: 'planned_delivery_date',
                  Cell: (row) => (row?.value ? moment(row?.value).format(defaultDateFormat) : '-'),
                  width: 100,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_delivery_date')}</span>,
                  accessor: 'delivery_date',
                  Cell: (row) => (row?.value ? moment(row?.value).format(defaultDateFormat) : '-'),
                  width: 100,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_note')}</span>,
                  accessor: 'note',
                  Cell: (row) => row?.value || '-',
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_free_stock_status')}</span>,
                  accessor: 'reserve_quantity',
                  Cell: (row) => row?.value || '-',
                  width: 90,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_ordered_quantity')}</span>,
                  accessor: 'ordered_quantity',
                  Cell: (row) => row?.value || '-',
                  width: 90,
                },
                {
                  Header: () => <span>{t('page_content.procurement.details.items_table.table_column_delivered_quantity')}</span>,
                  accessor: 'delivered_quantity',
                  Cell: (row) => row?.value || '-',
                  width: 90,
                },
              ]}
              minRows={0}
              defaultPageSize={100}
              data={tableData?.data || []}
              loading={tableData?.isLoading}
              defaultSorted={[{ id: 'created_at', desc: true }]}
              onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
              getTrProps={(state, rowInfo) => {
                if (rowInfo) {
                  const deliveryDate = get(rowInfo, 'original.delivery_date', null);
                  const isPastToday = deliveryDate ? moment().isAfter(moment(deliveryDate)) : false;

                  return {
                    style: { backgroundColor: isPastToday ? 'rgb(239, 195, 195)' : null },
                    onClick: () => redirectToProcurementDetails(get(rowInfo, 'original.procurement.id', null)),
                  };
                }
              }}
            />
            <TableButtons
              next={tableData?.next}
              count={tableData?.count}
              previous={tableData?.previous}
              fetchFunction={fetchPaginatedProcurementItems}
            />
          </div>
    </div>
  );
};

ProcurementItemsTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ProcurementItemsTable);
