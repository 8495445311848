import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class Legend extends Component {
  render() {
    const { t } = this.props;

    const statuses = [
      { status: t('page_content.microplanning.statuses.scheduled'), color: 'rgba(113, 125, 152, 0.7)' },
      { status: t('page_content.microplanning.statuses.in_progress'), color: 'rgba(95, 169, 238, 0.7)' },
      { status: t('page_content.microplanning.statuses.finished'), color: 'rgba(80, 150, 59, 0.7)' },
      { status: t('page_content.microplanning.statuses.paused'), color: 'rgba(175, 122, 179, 0.7)' },
      { status: t('page_content.microplanning.modal.changeover'), color: 'rgba(191, 191, 4, 0.7)' },
      { status: t('page_content.microplanning.modal.product_trial'), color: 'rgba(191, 191, 4, 0.7)' },
      { status: t('page_content.microplanning.modal.cleaning'), color: 'rgba(191, 191, 4, 0.7)' },
    ];

    return (
      <div className="legend-container">
        {statuses.map((status) => {
          return (
            <div className="legend-item" key={status.status}>
              <div
                className="color-box"
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '5px',
                  backgroundColor: status.color,
                }}
              />
              <span className="legend-label">{status.status}</span>
            </div>
          );
        })}
        <div className="legend-item">
          <div
            className="color-box"
            style={{
              width: '17px',
              height: '17px',
              marginRight: '5px',
              backgroundColor: 'transparent',
              border: '2px dashed rgb(236 86 203)',
            }}
          />
          <span className="legend-label">{t('page_content.microplanning.manual_entry')}</span>
        </div>
      </div>
    );
  }
}

Legend.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Legend);
