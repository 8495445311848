import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const ContentLoader = ({ text, theme, buttonVariant, rowVariant, onlySpinner }) => {
  if (buttonVariant) {
    return (
      <div className="buttonLoader">
        <div className="loader" />
      </div>

    );
  }

  if (rowVariant) {
    return (
      <div className="buttonLoader">
        <div className="rowLoader" />
        <div>{text}</div>
      </div>);
  }

  if (onlySpinner) {
    return (
      <div className={`content-loader theme-${theme}`} style={{ padding: 0 }}>
        <div className="loader" style={{ width: 24, height: 24 }} />
      </div>
    );
  }

  return (<div className={`content-loader theme-${theme}`}>
    <div className="loader" />
    <p>
      {text}
    </p>
  </div>);
};

ContentLoader.propTypes = {
  text: PropTypes.string,
  theme: PropTypes.string,
  buttonVariant: PropTypes.string,
  rowVariant: PropTypes.bool,
  onlySpinner: PropTypes.bool,
};

ContentLoader.defaultProps = {
  text: 'loading',
  theme: 'white',
};

export default ContentLoader;
