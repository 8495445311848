// eslint-disable-next-line import/prefer-default-export
export const transformNameOrSurnameString = (string) => {
  if (!string) {
    return '';
  }
  const deconstructedString = string.split(/\s+|-/);
  const formatedString = deconstructedString.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');

  return formatedString;
};
