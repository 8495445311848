import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { redirectToHomePage } from 'industry/helpers';
import { ContentLoader } from 'shared';
import './styles.scss';

import Workers from './components/Workers';
import ControlPanel from './components/ControlPanel';
import Recapitulation from './components/Recapitulation/index';

const IndustryLocationAdministration = ({ t, currentUser, companyId, locationId, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const checkRoles = async () => {
    setIsLoading(true);

    if (currentUser && !currentUser?.is_admin) {
      // TODO: razmisliti dal ostaviti ovako ili hardkodirati mailove tko ima pristup upravi jer ovako ostaje exposano da se moze dobiti pristup
      const uprava = currentUser?.roles.find((obj) => obj.role?.name?.toLowerCase() === 'uprava');

      if (uprava) {
        setIsLoading(false);
      } else {
        redirectToHomePage(companyId, locationId);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // TODO: staviti neki company/locaiton config s kojim cemo toglati dal da se provjere role ili klasicni check
    checkRoles();
  }, [companyId, locationId]);

  useEffect(() => {
    setSelectedTab(history?.location?.state?.tabIndex || 0);
  }, []);

  const handleSelectTab = (e) => {
    setSelectedTab(e);
  };

  return (
    <div className="industry-tab administration_container fullscreen">
      {
        isLoading
          ? <ContentLoader /> :
          <Tabs selectedIndex={selectedTab} onSelect={handleSelectTab}>
          <TabList>
            {currentUser?.is_admin && <Tab>{t('Rekapitulacija')}</Tab>}
            <Tab>{t('page_content.administration.workers')}</Tab>
            {currentUser?.is_admin && <Tab>{t('page_content.administration.control_panel')}</Tab>}
          </TabList>

          {
            currentUser?.is_admin &&
            <TabPanel>
              <Recapitulation locationId={locationId} companyId={companyId} />
            </TabPanel>
          }

            <TabPanel>
              <Workers locationId={locationId} companyId={companyId} />
            </TabPanel>

            {
              currentUser?.is_admin &&
              <TabPanel>
                <ControlPanel locationId={locationId} companyId={companyId} />
              </TabPanel>
            }
        </Tabs>
      }
    </div>
  );
};

IndustryLocationAdministration.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state) {
  return {
    currentUser: get(state, 'currentUser'),
    companyId: get(state, 'app.company.id'),
    locationId: get(state, 'app.location.id'),
  };
}

export default connect(mapStateToProps)(withRouter(withTranslation()(IndustryLocationAdministration)));
