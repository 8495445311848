import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import { ContentLoader } from 'shared';
import './styles.scss';
import ProcurementsTable from './components/ProcurementsTable';
import ProcurementItemsTab from './components/ProcurementItemsTab';

const IndustryLocationProcurements = ({ t, companyId, locationId, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const getAccess = async () => {
    setIsLoading(true);
    try {
      const access = await checkAccessOnPage(companyId);

      if (access === 0) {
        redirectToHomePage(companyId, locationId);
      } else if (access === 1) {
        // read only
        setIsReadOnly(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error checking access:', error);
    }
  };

  useEffect(() => {
    getAccess();
  }, []);

  useEffect(() => {
    setSelectedTab(history?.location?.state?.tabIndex || 0);
  }, []);

  const handleSelectTab = (e) => {
    setSelectedTab(e);
  };

  return (
    <div className="industry-tab administration_container fullscreen">
      {
        isLoading
          ? <ContentLoader /> :
          <Tabs selectedIndex={selectedTab} onSelect={handleSelectTab}>
          <TabList>
            <Tab>{t('page_content.procurement.procurements')}</Tab>
            <Tab>{t('page_content.procurement.procurement_items')}</Tab>
          </TabList>

            <TabPanel>
              <ProcurementsTable
                companyId={companyId}
                locationId={locationId}
                isReadOnly={isReadOnly}
              />
            </TabPanel>

            <TabPanel>
              <ProcurementItemsTab
                t={t}
                companyId={companyId}
                locationId={locationId}
                isReadOnly={isReadOnly}
              />
            </TabPanel>
        </Tabs>
      }
    </div>
  );
};

IndustryLocationProcurements.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state) {
  return {
    currentUser: get(state, 'currentUser'),
    companyId: get(state, 'app.company.id'),
    locationId: get(state, 'app.location.id'),
  };
}

export default connect(mapStateToProps)(withRouter(withTranslation()(IndustryLocationProcurements)));
