import React from 'react';
import PropTypes from 'prop-types';
import ParameterGoals from './ParameterGoals';
import ParameterValues from './ParameterValues';

const ScoringParameters = ({ t, isReadOnly, companyId, locationId }) => {
  return (
    <React.Fragment>
        <ParameterValues
          t={t}
          isReadOnly={isReadOnly}
          companyId={companyId}
          locationId={locationId}
        />
        <ParameterGoals
          t={t}
          isReadOnly={isReadOnly}
          companyId={companyId}
          locationId={locationId}
        />
    </React.Fragment>
  );
};

ScoringParameters.propTypes = {
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

export default ScoringParameters;
