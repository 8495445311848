import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { multiSelectFixedOptionsAutoHeightReadonly, selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, ContentLoader, Button, Table, LimitedTextarea } from 'shared';
import './styles.scss';

import { getQuestions, getQuestionOptions, getWorkerAnswers, updateWorkerSurvey, getSurveyFiles } from './actions';
import { getWorkerVacations } from '../../../IndustryLocationWorkerDetails/components/AbsencesAndVacations/actions';

const ViewFilledFormModal = ({ formData, showModal, handleClose, t, companyId, fetchFilledForms }) => {
  const [formQuestions, setFormQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    selectedStatus: '',
    note: '',
  });
  const [surveyFiles, setSurveyFiles] = useState([]);
  const [workerAbsencesAndVacations, setWorkerAbsencesAndVacations] = useState({
    usedDays: 0,
    assignedDays: 0,
  });

  const fetchWorkerAnswers = async () => {
    setIsLoading(true);
    const res = await getWorkerAnswers(formData?.id, formData?.worker?.id);
    const resp = await getQuestions(formData?.survey?.id);
    const questions = get(resp, 'data.results', []);
    const workerAnswers = get(res, 'data.results');
    const data = await Promise.all(questions.map(async (question) => {
      const temp = question;
      const respAnswers = await getQuestionOptions(question?.id);
      temp.answers = get(respAnswers, 'data.results', []);
      const foundQuestion = workerAnswers?.find((q) => q?.question?.id === question?.id);
      if (foundQuestion?.question?.question_type === 'choice') {
        const foundIndex = question?.answers.findIndex((a) => a?.value === foundQuestion?.answer);
        temp.answer = foundQuestion.answer;
        temp.answers[foundIndex].answer = true;
      }
      if (foundQuestion?.question?.question_type === 'select') {
        temp.answer = foundQuestion.answer;
      }
      if (foundQuestion?.question?.question_type === 'number') {
        temp.answer = foundQuestion.answer;
      }
      if (foundQuestion?.question?.question_type === 'free_text') {
        temp.text_answer = foundQuestion.text_answer;
      }
      if (foundQuestion?.question?.question_type === 'date') {
        temp.date_answer = foundQuestion.date_answer;
      }
      return temp;
    }));
    setFormQuestions(data);
    setIsLoading(false);
  };

  const fetchSurveyFiles = async () => {
    const res = await getSurveyFiles(companyId, formData?.id);
    setSurveyFiles(get(res, 'data.results', []));
  };

  const handleChangeFormValues = (key, value) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const fetchWorkerVacationDays = async () => {
    await getWorkerVacations(companyId, formData?.worker?.id, `&year=${moment().format('YYYY')}&order_by=-updated_at`)
      .then((res) => {
        const usedDays = get(res, 'data.results[0].used_days', 0);
        const assignedDays = get(res, 'data.results[0].assigned_days', 0);
        setWorkerAbsencesAndVacations((prevState) => ({
          ...prevState,
          usedDays,
          assignedDays,
        }));
      });
  };

  useEffect(() => {
    if (formData?.survey?.id) {
      if (formData?.survey?.data?.additionalInfo?.includes('vacationVsUsed')) fetchWorkerVacationDays();
      fetchWorkerAnswers();
      handleChangeFormValues('selectedStatus', formData?.action_status);
      if (formData.survey.file_upload) {
        fetchSurveyFiles();
      }
    }
  }, [formData]);

  const handleCloseModal = () => {
    setFormValues({ selectedStatus: '', note: '' });
    setFormQuestions([]);
    handleClose();
  };

  const handleSave = async () => {
    const data = {
      action_status: formValues?.selectedStatus,
      note: formValues?.note,
      survey: formData?.survey?.id,
      worker: formData?.worker?.id,
    };
    await updateWorkerSurvey(formData?.id, data);
    fetchFilledForms();
    handleCloseModal();
  };

  const formStatuses = [
    { id: 'submitted', label: t('page_content.human_resources.forms.status_submitted') },
    { id: 'on_hold', label: t('page_content.human_resources.forms.status_on_hold') },
    { id: 'rejected', label: t('page_content.human_resources.forms.status_rejected') },
    { id: 'granted', label: t('page_content.human_resources.forms.status_granted') },
  ];

  const handlePreviewFilledForm = () => {
    const currentUrl = window.origin;
    const url = `${currentUrl}/surveys/worker-survey/${formData?.id}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleDownloadFile = async (e, row) => {
    e.stopPropagation();

    const link = document.createElement('a');
    link.href = row.original.file;
    link.target = '_blank';
    link.setAttribute('download', row.original.name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const tableColumns = [
    {
      Header: () => <span>{t('page_content.human_resources.forms.files_table_column_name')}</span>,
      accessor: 'name',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.files_table_column_download')}</span>,
      width: 120,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button type="export" style={{ padding: '5px 8px' }} onClick={(e) => handleDownloadFile(e, row)}>
      {t('page_content.human_resources.forms.files_table_column_download')}
      </Button>
    </div>),
    },
  ];

  const renderAdditionalInfo = () => {
    const additionalInfo = formData?.survey?.data?.additionalInfo || [];

    if (additionalInfo?.includes('vacationVsUsed')) {
      return (
        <div className="worker_details_absence__table__display_days_survey">
          <div>{`${t('page_content.workforce_management.worker_details.worker_absence_vacation_card.remaining_total_vacation')}: ${workerAbsencesAndVacations?.usedDays || 0}/${workerAbsencesAndVacations?.assignedDays || 0}`}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <Modal
      title={formData?.survey?.name}
      isOpen={showModal}
      handleClose={handleCloseModal}
      closeText={t('shared.modal.close_button_alt')}
      handleSave={formData?.survey?.action_required ? handleSave : null}
    >
    <div>
        {isLoading ? <ContentLoader /> :
        <div className="form_preview_modal_content">

          <div className="export_pdf_button">
            <Button onClick={handlePreviewFilledForm} type="export"><div className="button_pdf">{t('page_content.dashboards.export_pdf_button')}</div></Button>
          </div>

          <div className="form_modal_user_info">
            <div className="user_info_name">
              {formData?.worker?.name} {formData?.worker?.last_name}
            </div>
            <div className="user_info_date">
            {t('page_content.human_resources.forms.finished_label')}: {moment(formData?.finish_time).format('DD.MM.YYYY, HH:mm')}
            </div>
          </div>

          {renderAdditionalInfo()}

          {
            formData?.survey?.action_required && (
              <div className="status_selection">
                {t('page_content.human_resources.forms.set_status_label')}
                <Select
                  className="select-style"
                  options={formStatuses}
                  getOptionLabel={(type) => type.label}
                  getOptionValue={(type) => type.id}
                  menuPosition="fixed"
                  placeholder={t('page_content.human_resources.forms.set_status_placeholder')}
                  onChange={(value) => handleChangeFormValues('selectedStatus', value?.id)}
                  value={formStatuses.find((s) => s.id === formValues?.selectedStatus)}
                  styles={selectModalStyles}
                />
              </div>
            )
          }

          {
            formData?.survey?.action_required && formValues?.selectedStatus !== 'submitted' && (
              <div className="status_note">
                <div className="note_label">{t('page_content.human_resources.forms.note')}</div>
                <LimitedTextarea
                  autoResize
                  value={formData?.note || ''}
                  onChange={(value) => handleChangeFormValues('note', value)}
                />
              </div>
            )
          }

          {
            formQuestions?.map((q) => {
              return <div className="question_filled_forms_container" key={q.id}>
                <div className="question_title">
                    <div className="question_index">{q?.order}</div>
                    <div className="question_question">{q?.question}</div>
                </div>
                <div className="question_answer_content_filled">
                    {q?.answers?.length ? <div>
                        {q?.question_type === 'choice' && <div>
                                {q.answers.map((a) => {
                                  return <div key={a.id} className="radio_answer_inline answer_label_style">
                                        <span className={a.answer ? 'choice_answer_selected' : 'choice_answer'}>{a.label}</span>
                                    </div>;
                                })}
                            </div>}
                        {q?.question_type === 'select' && <div>
                        <Select
                          options={q.answers}
                          getOptionLabel={(type) => type.label}
                          onChange={null}
                          isMulti
                          isDisabled
                          isClearable={false}
                          menuPosition="fixed"
                          value={q.answers.filter((a) => q?.answer?.split(',').some((ans) => a.value === ans)) || ''}
                          styles={multiSelectFixedOptionsAutoHeightReadonly}
                        />
                        </div>}
                    </div> :
                    <div className="answer_label_style">{q.question_type === 'number' ? q.answer : q.question_type === 'date' ? moment(q.date_answer).format('DD.MM.YYYY') : q.text_answer || <i>({t('page_content.human_resources.forms.empty_answer')})</i>}</div>}
                </div>
              </div>;
            })
          }

          {
            formData?.survey?.file_upload && (
              <div className="files_footer">
                <div className="files_footer_label">{t('page_content.human_resources.forms.upload_files_label')}</div>
                  <Table
                    style={{ userSelect: 'text' }}
                    columns={tableColumns}
                    data={surveyFiles || []}
                    noDataText=" "
                    showPagination={false}
                    sortable={false}
                    minRows={0}
                    defaultPageSize={surveyFiles?.length || 20}
                    selectedRow={null}
                  />
              </div>
            )
          }
        </div>}
    </div>
</Modal>
  );
};

ViewFilledFormModal.propTypes = {
  showModal: PropTypes.bool,
  formData: PropTypes.object,
  handleClose: PropTypes.func,
  companyId: PropTypes.number,
  t: PropTypes.func.isRequired,
  fetchFilledForms: PropTypes.func,
};

export default (withTranslation()(ViewFilledFormModal));
