import api from 'helpers/api';
import { Notification } from 'shared';

export const getLineGroups = async (company_short_code, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/planning/line_groups/?company=${company_short_code}&location=${locationId}&order_by=name`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching line groups', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addEvent = async (line, data) => {
  try {
    const res = await api
      .post(`/api/v1/planning/line_plans/?line=${line}`, data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while adding event', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editEvent = async (id, line, data) => {
  return api.patch(`/api/v1/planning/line_plans/${id}/`, data);
};

export const getCompanyData = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/companies/${companyId}/`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching companies', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getGroups = async (company_short_code, location = '', filter) => {
  try {
    const res = await api
      .get(`/api/v1/planning/lines/?company=${company_short_code}&location=${location}${filter}&is_hidden=false`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching groups', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getEvents = async (lineId, location = '', filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/planning/line_plans/?line=${lineId}&location=${location}${filters}&limit=1000000`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching line events', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const deleteEvent = async (id, line) => {
  try {
    const res = await api
      .delete(`/api/v1/planning/line_plans/${id}/?line=${line}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting event', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getMaintenanceReasons = async (companyId) => {
  try {
    const res = await api
      .get(`/api/v1/maintenance/reasons/?company=${companyId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching reasons', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrders = async (companyId, location = '', filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/orders/?company=${companyId}&location=${location}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching orders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const verifyFile = async (data) => {
  try {
    const res = await api
      .post('/api/v1/planning/verify_import/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred', error.response.data.message || 'We could not perform your request, please try again.');
  }
};

export const importFile = async (data) => {
  try {
    const res = await api
      .post('/api/v1/planning/import/', data);
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred', error.response.data.message || 'We could not perform your request, please try again.');
  }
};

export const getOrderLinePlans = async (orderId) => {
  const urlString = '/api/v1/planning/line_plans';
  let urlParams = '?limit=1000&simple_order=1';

  if (orderId) {
    urlParams += `&order=${orderId}`;
  }

  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order line plans', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addChangeoverEvent = async (data) => {
  try {
    const res = await api
      .post('/api/v1/planning/create_switchover/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occured while adding event', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addProductTrialEvent = async (data) => {
  try {
    const res = await api
      .post('/api/v1/planning/create_product_trial/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occured while adding event', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addCleaningEvent = async (data) => {
  try {
    const res = await api
      .post('/api/v1/planning/create_cleaning/', data);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occured while adding event', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
