import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import '../../styles.scss';

import IndividualGoalsRating from './IndividualGoalsRating';
import CompanyValuesRating from './CompanyValuesRating';
import WorkProcessesRating from './WorkProcessesRating';
import PersonnelDataRating from './PersonnelDataRating';

const RatingScreen = ({ t, selectedWorker }) => {
  return (
    <div className="ratings__screen">
      <Tabs>
        <TabList>
          <Tab>{t('page_content.administration.rating.individual_goals')}</Tab>
          <Tab>{t('page_content.administration.rating.company_values')}</Tab>
          <Tab>{t('page_content.administration.rating.work_processes')}</Tab>
          <Tab>{t('page_content.administration.rating.personnel_data')}</Tab>
        </TabList>

        <TabPanel>
            <IndividualGoalsRating selectedWorker={selectedWorker} />
        </TabPanel>

        <TabPanel>
            <CompanyValuesRating selectedWorker={selectedWorker} />
        </TabPanel>

        <TabPanel>
            <WorkProcessesRating selectedWorker={selectedWorker} />
        </TabPanel>

        <TabPanel>
            <PersonnelDataRating selectedWorker={selectedWorker} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

RatingScreen.propTypes = {
  t: PropTypes.func,
  selectedWorker: PropTypes.object.isRequired,
};

export default (withTranslation()(RatingScreen));
