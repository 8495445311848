import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import { Table, Modal, TableButtons } from 'shared';
import { defaultDateFormat, modalSizes } from 'shared/constants';
import { getWorkingPlaces, getPaginatedWorkingPlaces } from '../actions';
import '../styles.scss';

const WorkingPlacesModal = ({ t, companyId, workerId, isOpen, handleCloseModal }) => {
  const [tableData, setTableData] = useState({
    isLoading: true,
    workingPlaces: [],
    next: null,
    previous: null,
  });

  const [filters, setFilters] = useState({
    status: null,
    selectedSort: 'start_date',
    selectedAscDesc: 'desc',
  });

  const fetchWorkingPlaces = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let urlFilters = '';

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `order_by=${asc}${filters?.selectedSort}&company=${companyId}&is_current=true&worker=${workerId}&limit=20`;

    if (filters?.status) urlFilters += `&status=${filters?.status?.value}`;

    getWorkingPlaces(urlFilters)
      .then((re) => {
        setTableData({
          isLoading: false,
          workingPlaces: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  useEffect(() => {
    fetchWorkingPlaces();
  }, [companyId, filters]);

  const fetchPaginatedData = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedWorkingPlaces(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          workingPlaces: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;

    setFilters({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    });
  };

  const tableColumns = [
    {
      Header: t('page_content.administration.date'),
      accessor: 'start_date',
      Cell: (row) => (row && row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: t('page_content.administration.working_place'),
      accessor: 'working_place.name',
      Cell: (row) => (row && row.value ? row.value : '-'),
    },
    {
      Header: t('page_content.administration.current'),
      accessor: 'is_current',
      Cell: (row) => (row && row.value ?
        <img src={checkMarkTrue} width="18px" height="18px" alt="" /> :
        <img src={checkMarkFalse} width="18px" height="18px" alt="" />
      ),
    },
  ];

  return (
    <Modal
      title=""
      size={modalSizes.large}
      isOpen={isOpen}
      handleClose={handleCloseModal}
    >
      <div className="salaries-modal">
      <Table
        style={{ userSelect: 'text', marginTop: 20 }}
        columns={tableColumns}
        data={tableData?.workingPlaces || []}
        minRows={0}
        defaultPageSize={30}
        noDataText=" "
        sortable
        showPagination={false}
        loading={tableData?.isLoading}
        defaultSorted={[{ id: 'date', desc: true }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <div>
        <span style={{ float: 'right' }}>
          <TableButtons
            previous={tableData?.previous}
            next={tableData?.next}
            fetchFunction={fetchPaginatedData}
            count={tableData?.count}
          />
        </span>
      </div>
      </div>
    </Modal>
  );
};

WorkingPlacesModal.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  workerId: PropTypes.number,
  isOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};

export default (withRouter(withTranslation()(WorkingPlacesModal)));
