import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import * as actions from 'industry/IndustryLocationContainer/actions';
import { Page, PageContent, PageLoader } from 'shared';
import './styles.scss';

import { IndustryMenu, IndustrySubHeader } from 'industry/components';
import {
  IndustryLocationAssetList,
  IndustryLocationDashboard,
  IndustryLocationDashboardList,
  IndustryLocationDeviceList,
  IndustryLocationEventList,
  IndustryLocationOrderList,
  IndustryLocationWidgetDashboard,
  // IndustryFactoryFloor,
  IndustryLocationEfficiencyList,
  IndustryOperatorInterface,
  IndustryLocationUpload,
  IndustryOperatorView,
  IndustrySimpleOperatorInterface,
  IndustryLocationOperatorList,
  IndustryLocationPhotoLibrary,
  IndustryLocationPhotoLibraryAssetList,
  IndustryLocationWorkforceManagement,
  IndustryLocationMaintenanceModule,
  IndustryLocationReportsForSalary,
  IndustryAutomaticReports,
  IndustryLocationMicroPlanning,
  IndustryLocationShifts,
  IndustryLocationWarehouse,
  IndustryProductionRecords,
  Industry5S,
  Industry5SDetails,
  IndustryProjects,
  IndustryProjectDetails,
  IndustryLocationDMS,
  IndustryLocationDMSDetails,
  IndustryLocationOEE,
  IndustryLocationWorkerDetails,
  IndustryLocationOrderDetails,
  IndustryLocationHumanResources,
  IndustryLocationAdministration,
  IndustryLocationAdministrationWorkerDetails,
  IndustryLocationProcurement,
  IndustryLocationProcurementDetails,
} from 'industry/containers';

import EventsAndSubscribersTabs from 'company/CompanySettings/components/EventsAndSubscribersTabs/index';
import FerroPreisPlantFloor from 'industry/containers/IndustryFactoryFloor/FerroPreisPlantFloor';
import JohnsonMattheyPlantFloor from 'industry/containers/IndustryFactoryFloor/JohnsonMattheyPlantFloor';
import PartnersTab from 'company/CompanySettings/components/PartnersTab';
import CustomDataTab from 'company/CompanySettings/components/CustomDataTab';
import AssetUtilizationTab from 'company/CompanySettings/components/AssetUtilizationTab';
import AscaliaAuditLogTab from 'company/CompanySettings/components/AscaliaAuditLogTab/index';
import UserRoleManagement from 'company/CompanySettings/components/UserManagementTab';
import TimingSettingsTab from 'company/CompanySettings/components/TimingSettingsTab';
import OperatorViewTab from 'company/CompanySettings/components/OperatorViewTab';
// import StoppageReasonsTab from 'company/CompanySettings/components/StoppageReasonsTab';
// import MaintenanceReasonsTab from 'company/CompanySettings/components/MaintenanceReasonsTab';
import ProductTypesTab from 'company/CompanySettings/components/ProductTypesTab/index';
import SettingsMenu from 'company/CompanySettings/components/SettingsMenu/index';
import UserProfile from 'user-profile';
import ShiftsTab from '../../company/CompanySettings/components/ShiftsTab/index';
import TechnologyTab from '../../company/CompanySettings/components/TechnologyTab/index';
import DepartmentTab from '../../company/CompanySettings/components/DepartmentTab/index';
import ConfigsTab from '../../company/CompanySettings/components/ConfigsTab/index';
import NotificationsTab from '../../company/CompanySettings/components/NotificationsTab/index';
import IndustryLocationDepartmentDetails from '../containers/IndustryLocationDepartmentDetails/index';

class IndustryLocationContainer extends Component {
  constructor() {
    // const isMobileLocal = window.outerWidth < 992;
    super();
    this.state = {
      showSidebar: true,
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: {
          locationId,
          companyId,
        },
      },
    } = this.props;
    const params = {
      companyId,
      locationId,
    };
    this.props.fetchDashboards(params);
  }

  componentDidUpdate(prevProps) {
    const {
      currentUser,
      match: {
        params: {
          locationId,
          companyId,
        },
      },
    } = this.props;

    const fullscreen_click_counter = currentUser && currentUser.data && currentUser.data.fullscreen && currentUser.data.fullscreen.fullscreen_click_counter ?
      currentUser.data.fullscreen.fullscreen_click_counter : 0;

    const simpleOperatorInterface = '/simple-operator-interface';
    const operatorInterface = '/operator-interface/';
    const settings = '/settings/';
    if (window.location.href.includes(operatorInterface) || window.location.href.includes(simpleOperatorInterface)) {
      const sidebar = document.getElementsByClassName('industry-menu');
      if (sidebar && sidebar.length && fullscreen_click_counter === 0) {
        sidebar[0].style.display = 'none';
      }
      const subHeader = document.getElementsByClassName('SubHeader');
      if (subHeader && subHeader.length && fullscreen_click_counter === 0) {
        subHeader[0].style.display = 'none';
      }
      const topHeader = document.getElementsByClassName('top-header');
      if (topHeader && topHeader.length && fullscreen_click_counter === 0) {
        topHeader[0].style.display = 'none';
      }
    } else if (window.location.href.includes(settings)) {
      const sidebar = document.getElementsByClassName('industry-menu');
      if (sidebar && sidebar.length) {
        sidebar[0].style.display = 'none';
      }
    } else {
      const sidebar = document.getElementsByClassName('industry-menu');
      if (sidebar && sidebar.length && fullscreen_click_counter === 0) {
        sidebar[0].style.display = 'block';
      }
      const subHeader = document.getElementsByClassName('SubHeader');
      if (subHeader && subHeader.length && fullscreen_click_counter !== 2) {
        subHeader[0].style.display = 'flex';
      }
      const topHeader = document.getElementsByClassName('top-header');
      if (topHeader && topHeader.length && fullscreen_click_counter !== 2) {
        topHeader[0].style.display = 'flex';
      }
    }

    const {
      match: {
        params: {
          locationId: prevLocationId,
          companyId: prevCompanyId,
        },
      },
    } = prevProps;

    if (
      (
        prevCompanyId !== companyId ||
        prevLocationId !== locationId
      )
    ) {
      const params = {
        companyId,
        locationId,
      };

      // this.props.fetchDevices(params);
      // this.props.fetchAssets(params);
      this.props.fetchDashboards(params);
    }
  }

  componentWillUnmount() {
    this.props.resetContainer();
  }

  toggleSidebar(value) {
    this.setState({ showSidebar: value });
  }

  render() {
    const {
      dashboards,
      isLoadingDashboards,
      isLoadingAssets,
      location,
    } = this.props;

    if (isLoadingAssets || isLoadingDashboards || !location) {
      return <PageLoader />;
    }

    let {
      match: {
        params: {
          // eslint-disable-next-line
          locationId,
          // eslint-disable-next-line
          companyId,
        },
      },
      // eslint-disable-next-line
      addOrUpdateDashboardSuccess,
      // eslint-disable-next-line
      history,
    } = this.props;

    locationId = parseInt(locationId, 10);
    companyId = parseInt(companyId, 10);

    return (
      <Fragment>
        <IndustrySubHeader isSettings={window.location.href.includes('/settings/')} toggleSidebar={this.toggleSidebar} />
        <Page>
          <PageContent isSettings={window.location.href.includes('/settings/')}>
            {this.state.showSidebar && <IndustryMenu showSidebar={this.state.showSidebar} faults={[]} companyId={companyId} locationId={locationId} />}
            {window.location.href.includes('/settings/') && <SettingsMenu companyId={companyId} locationId={locationId} history={history} />}
            <Switch>
              <Route exact path="/:companyId/industry/location/:locationId/shifts" render={() => <IndustryLocationShifts companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/user-role-management" render={() => <UserRoleManagement companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/ascalia-audit-log" render={() => <AscaliaAuditLogTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/asset-utilization" render={() => <AssetUtilizationTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/timing-settings" render={() => <TimingSettingsTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/product-types" render={() => <ProductTypesTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/shifts" render={() => <ShiftsTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/technology" render={() => <TechnologyTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/department" render={() => <DepartmentTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/notifications" render={() => <NotificationsTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/events-and-subscribers" render={() => <EventsAndSubscribersTabs companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/configs" render={() => <ConfigsTab companyId={companyId} locationId={locationId} />} />
              {/* <Route exact path="/:companyId/industry/location/:locationId/settings/stoppage-events" render={() => <StoppageReasonsTab companyId={companyId} locationId={locationId} />} /> */}
              {/* <Route exact path="/:companyId/industry/location/:locationId/settings/maintenance-reasons" render={() => <MaintenanceReasonsTab companyId={companyId} locationId={locationId} />} /> */}
              <Route exact path="/:companyId/industry/location/:locationId/settings/partners" render={() => <PartnersTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/custom-data" render={() => <CustomDataTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/settings/operator-view" render={() => <OperatorViewTab companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/" render={() => <IndustryLocationWidgetDashboard companyId={companyId} locationId={locationId} dashboards={dashboards} />} />
              <Route exact path="/:companyId/industry/location/:locationId/events" render={() => <IndustryLocationEventList companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/workforce-management" render={() => <IndustryLocationWorkforceManagement companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/factory-floor" render={() => <FerroPreisPlantFloor companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/factory-floor-fp" render={() => <FerroPreisPlantFloor companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/factory-floor-jm" render={() => <JohnsonMattheyPlantFloor companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/upload" render={() => <IndustryLocationUpload companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/orders" render={() => <IndustryLocationOrderList companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/orders/:orderId" render={() => <IndustryLocationOrderDetails companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/efficiency" render={() => <IndustryLocationEfficiencyList companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/devices" component={IndustryLocationDeviceList} />
              <Route exact path="/:companyId/industry/location/:locationId/assets" component={IndustryLocationAssetList} />
              <Route exact path="/:companyId/industry/location/:locationId/operator-interface/:assetId" component={IndustryOperatorInterface} />
              <Route exact path="/:companyId/industry/location/:locationId/dashboards/" render={() => <IndustryLocationDashboardList addOrUpdateDashboardSuccess={addOrUpdateDashboardSuccess} companyId={companyId} locationId={locationId} dashboards={dashboards} />} />
              <Route exact path="/:companyId/industry/location/:locationId/dashboards/:dashboardId" component={IndustryLocationDashboard} companyId={companyId} locationId={locationId} />
              <Route exact path="/:companyId/industry/location/:locationId/user-profile" render={() => <UserProfile companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/operator-view/:viewId" component={IndustryOperatorView} />
              <Route exact path="/:companyId/industry/location/:locationId/operatorView/:viewId/simple-operator-interface" component={IndustrySimpleOperatorInterface} />
              <Route exact path="/:companyId/industry/location/:locationId/operator" component={IndustryLocationOperatorList} />
              <Route exact path="/:companyId/industry/location/:locationId/quality-inspection" component={IndustryLocationPhotoLibraryAssetList} />
              <Route exact path="/:companyId/industry/location/:locationId/quality-inspection/asset/:assetId/:gallery" component={IndustryLocationPhotoLibrary} />
              <Route exact path="/:companyId/industry/location/:locationId/maintenance-module" render={() => <IndustryLocationMaintenanceModule companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/reports-for-salary" render={() => <IndustryLocationReportsForSalary companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/automatic-reports" render={() => <IndustryAutomaticReports companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/warehouse" render={() => <IndustryLocationWarehouse companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/micro-planning" render={() => <IndustryLocationMicroPlanning companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/production-records" component={IndustryProductionRecords} />
              <Route exact path="/:companyId/industry/location/:locationId/5S" render={() => <Industry5S companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/5S/:entryId" render={() => <Industry5SDetails companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/projects" component={IndustryProjects} />
              <Route exact path="/:companyId/industry/location/:locationId/projects/:projectId" render={() => <IndustryProjectDetails companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/dms" render={() => <IndustryLocationDMS companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/dms/:dmsId" render={() => <IndustryLocationDMSDetails companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/oee" render={() => <IndustryLocationOEE companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/human-resources/worker/:workerId" render={() => <IndustryLocationWorkerDetails companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/human-resources/department/:departmentId" render={() => <IndustryLocationDepartmentDetails companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/human-resources/" render={() => <IndustryLocationHumanResources companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/administration/" render={() => <IndustryLocationAdministration companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/administration/worker/:workerId" render={() => <IndustryLocationAdministrationWorkerDetails companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/procurement/" render={() => <IndustryLocationProcurement companyId={companyId} locationId={locationId} />} />
              <Route exact path="/:companyId/industry/location/:locationId/procurement/order/:orderId" render={() => <IndustryLocationProcurementDetails companyId={companyId} locationId={locationId} />} />
            </Switch>
          </PageContent>
        </Page>
      </Fragment>
    );
  }
}

IndustryLocationContainer.propTypes = {
  // fetchAssets: PropTypes.func.isRequired,
  // fetchDevices: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  fetchDashboards: PropTypes.func.isRequired,
  resetContainer: PropTypes.func.isRequired,
  addOrUpdateDashboardSuccess: PropTypes.func.isRequired,
  dashboards: PropTypes.array,
  isLoadingAssets: PropTypes.bool,
  isLoadingDashboards: PropTypes.bool,
  location: PropTypes.any,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    isLoadingDashboards: state.industry.container.isLoadingDashboards,
    dashboards: state.industry.container.dashboards,
    latestUpdate: state.industry.container.latestUpdate,
    location: state.app.location,
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  fetchDashboards: actions.fetchDashboards,
  resetContainer: actions.resetContainer,
  addOrUpdateDashboardSuccess: actions.addOrUpdateDashboardSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustryLocationContainer);
