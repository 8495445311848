import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import { addBudget, editBudget } from '../actions';

const DepartmentBudgetModal = ({
  t,
  isOpen,
  companyId,
  handleCloseModal,
  initialValues = {},
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      ...formValues,
      company: companyId,
    };

    if (formValues?.id) {
      await editBudget(companyId, formValues?.id, payload);
    } else {
      await addBudget(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      enableSaveOnEnter={false}
      title={formValues?.id ? t('page_content.administration.control_panel_tab.modal_company_title_edit') : t('page_content.administration.control_panel_tab.modal_company_title_add')}
      disableSave={!formValues?.year || !formValues?.budget}
    >
      {
        isLoading ?
        <ContentLoader /> :
          <div className="asset_form_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.year_column')}: *</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'year', '')} onChange={(e) => onChange(e.target.value, 'year')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.budget_column')}: *</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'budget', '')} onChange={(e) => onChange(e.target.value, 'budget')} />
              </div>
            </div>

            {/* <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.net_salary_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'net_salary', '')} onChange={(e) => onChange(e.target.value, 'net_salary')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.non_taxable_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'non_taxable', '')} onChange={(e) => onChange(e.target.value, 'non_taxable')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.gross_salary_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'gross_salary', '')} onChange={(e) => onChange(e.target.value, 'gross_salary')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.gross_salary_2_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'gross_salary_2', '')} onChange={(e) => onChange(e.target.value, 'gross_salary_2')} />
              </div>
            </div> */}
          </div>
      }
    </Modal>
  );
};

DepartmentBudgetModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(DepartmentBudgetModal));
