import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { withTranslation } from 'react-i18next';
import { modalSizes } from 'shared/constants';
import { Modal, Button, TableLoader, Table } from 'shared';
import { initUpload, getUploadStatus } from '../actions';

class FileUploadModal extends Component {
  constructor() {
    super();
    this.uploadInterval = null;
    this.state = {
      initUploadState: false,
      initSuccess: false,
      waitingOnUploadStatus: false,
      uploadPercentage: 0,
      errorFlag: false,
      selectedUniqueIds: [],
      selectedUniquesPool: [],
      isLoadingExportToExcel: false,
      isCheckboxClicked: false,
      fileTypeForUpload: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploadStatus !== this.props.uploadStatus) {
      if (this.props.uploadStatus && this.props.uploadStatus.file_type) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ fileTypeForUpload: this.props.uploadStatus && this.props.uploadStatus.file_type });
      }
    }
  }

  clearInterval = () => {
    clearInterval(this.uploadInterval);
  };

  // selectUnique = (e, rowObject) => {
  //   const { selectedUniqueIds, selectedUniquesPool } = this.state;
  //   const { uploadFileType } = this.props;

  //   let selectedIdsPool = selectedUniqueIds.slice();
  //   let selectedUniques = selectedUniquesPool.slice();
  //   if (e.target.checked === true) {
  //     if (uploadFileType && uploadFileType === 'bom') {
  //       if (!selectedIdsPool.includes(rowObject.item)) {
  //         selectedIdsPool.push(rowObject.item);
  //         selectedUniques.push(rowObject);
  //       }
  //     }

  //     if (uploadFileType && uploadFileType === 'parts') {
  //       if (!selectedIdsPool.includes(rowObject.external_id)) {
  //         selectedIdsPool.push(rowObject.external_id);
  //         selectedUniques.push(rowObject);
  //       }
  //     }
  //   } else {
  //     if (uploadFileType && uploadFileType === 'bom') {
  //       selectedIdsPool = selectedIdsPool.filter((x) => x !== rowObject.item);
  //       selectedUniques = selectedUniques.filter((x) => x.item !== rowObject.item);
  //     }

  //     if (uploadFileType && uploadFileType === 'parts') {
  //       selectedIdsPool = selectedIdsPool.filter((x) => x !== rowObject.external_id);
  //       selectedUniques = selectedUniques.filter((x) => x.external_id !== rowObject.external_id);
  //     }
  //   }

  //   console.log(selectedIdsPool, selectedUniques);
  //   this.setState({ selectedUniqueIds: selectedIdsPool, selectedUniquesPool: selectedUniques, isCheckboxClicked: true });
  // }

  // multipleSelectUniques = (e, uniquesArray) => {
  //   const { selectedUniqueIds, selectedUniquesPool } = this.state;
  //   const { uploadFileType } = this.props;

  //   let selectedIdsPool = selectedUniqueIds.slice();
  //   let selectedUniques = selectedUniquesPool.slice();
  //   if (e.target.checked === true) {
  //     for (const unique of uniquesArray) {
  //       if (uploadFileType && uploadFileType === 'bom') {
  //         if (!selectedIdsPool.includes(unique.item)) {
  //           selectedIdsPool.push(unique.item);
  //           selectedUniques.push(unique);
  //         }
  //       }

  //       if (uploadFileType && uploadFileType === 'parts') {
  //         if (!selectedIdsPool.includes(unique.external_id)) {
  //           selectedIdsPool.push(unique.external_id);
  //           selectedUniques.push(unique);
  //         }
  //       }
  //     }
  //   } else {
  //     for (const unique of uniquesArray) {
  //       if (uploadFileType && uploadFileType === 'bom') {
  //         selectedIdsPool = selectedIdsPool.filter((x) => x !== unique.item);
  //         selectedUniques = selectedUniques.filter((x) => x.item !== unique.item);
  //       }

  //       if (uploadFileType && uploadFileType === 'parts') {
  //         selectedIdsPool = selectedIdsPool.filter((x) => x !== unique.external_id);
  //         selectedUniques = selectedUniques.filter((x) => x.external_id !== unique.external_id);
  //       }
  //     }
  //   }
  //   this.setState({ selectedUniqueIds: selectedIdsPool, selectedUniquesPool: selectedUniques, isCheckboxClicked: true });
  // }

  populateAllItemsSelected = (uniquesArray) => {
    const { uploadFileType } = this.props;

    if (uploadFileType && uploadFileType === 'bom') {
      const selectedIdsPool = [...new Set(uniquesArray.map((unique) => unique.item))];
      const selectedUniques = [...new Set(uniquesArray)];
      this.setState({ selectedUniqueIds: selectedIdsPool, selectedUniquesPool: selectedUniques });
    }

    if (uploadFileType && uploadFileType === 'parts') {
      const selectedIdsPool = [...new Set(uniquesArray.map((unique) => unique.external_id))];
      const selectedUniques = [...new Set(uniquesArray)];
      this.setState({ selectedUniqueIds: selectedIdsPool, selectedUniquesPool: selectedUniques });
    }
  }

  exportExcel = () => {
    const { uploadDataId } = this.props;

    this.setState({ isLoadingExportToExcel: true });

    api.get(`/api/v1/projects/verify/?file_id=${uploadDataId}&export_excel=1`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'projects_file_export.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({ isLoadingExportToExcel: false });
      })
      .catch(() => {
        this.setState({ isLoadingExportToExcel: false });
      });
  }

  startUploadAndGetProgress = () => {
    const {
      uploadDataId,
      projectId,
      clearUploadStatus,
    } = this.props;

    const {
      selectedUniqueIds,
    } = this.state;

    this.setState({
      waitingOnUploadStatus: true,
      initUploadState: true,
    });
    const data = {
      file_id: uploadDataId || null,
      external_ids: selectedUniqueIds || [],
    };

    initUpload(data)
      .then((re) => {
        if (!(re && re.status >= 200) && !(re && re.status < 300)) {
          this.setState({
            initSuccess: true,
            waitingOnUploadStatus: false,
            errorFlag: true,
          });
        }
        clearUploadStatus();
      });
    this.uploadInterval = setInterval(() => {
      getUploadStatus(uploadDataId, projectId)
        .then((resp) => {
          this.setState({
            uploadPercentage: resp.data.progress_percent,
          });
          if (resp.data.status === 'imported') {
            this.clearInterval();
            clearUploadStatus();
            this.setState({
              initSuccess: true,
              waitingOnUploadStatus: false,
            });
          }
        });
    }, 1000);
  }

  render() {
    const {
      modalStatus,
      closeImportModal,
      handleUploadWindow,
      uploadResponse,
      uploadLoader,
      t,
      uploadDataId,
      uploadStatus,
      clearUploadStatus,
      // uploadFileType,
    } = this.props;

    const {
      initUploadState,
      initSuccess,
      waitingOnUploadStatus,
      uploadPercentage,
      errorFlag,
      selectedUniqueIds,
      isLoadingExportToExcel,
      isCheckboxClicked,
      fileTypeForUpload,
    } = this.state;

    function returnTableColumns(uploadData) {
      const partsColumnDefinition = [
        {
          Header: () => <span>{t('page_content.projects.file_upload_modal.table_column_partName')}</span>,
          width: 200,
          accessor: 'name',
          Cell: (row) => (row.value),
        },
        {
          Header: () => <span>{t('page_content.projects.file_upload_modal.table_column_code')}</span>,
          width: 200,
          accessor: 'product_type.code',
          Cell: (row) => (row.value),
        },
        {
          Header: () => <span>{t('page_content.projects.file_upload_modal.table_column_comment')}</span>,
          width: 300,
          accessor: 'dirty_text',
          Cell: (row) => (row.value),
        },
      ];

      const bomColumnDefinition = [
        {
          Header: () => <span>{t('page_content.projects.file_upload_modal.table_column_partNumber')}</span>,
          width: 200,
          accessor: 'part_number',
          Cell: (row) => (row.value),
        },
        {
          Header: () => <span>{t('page_content.projects.file_upload_modal.table_column_parentBom')}</span>,
          width: 200,
          accessor: 'parent_bom',
          Cell: (row) => (row.value),
        },
        {
          Header: () => <span>{t('page_content.projects.file_upload_modal.table_column_comment')}</span>,
          width: 300,
          accessor: 'dirty_text',
          Cell: (row) => (row.value),
        },
      ];

      switch (uploadData) {
        case 'parts': return partsColumnDefinition;
        case 'bom': return bomColumnDefinition;
        default: return [];
      }
    }

    if (uploadResponse.length > 0 && selectedUniqueIds.length === 0 && !isCheckboxClicked) {
      this.populateAllItemsSelected(uploadResponse);
    }

    return (
      <Modal
        closeText={t('shared.modal.close_button_alt')}
        isOpen={modalStatus}
        handleClose={() => {
          closeImportModal();
          this.clearInterval();
          this.setState({
            initSuccess: false,
            waitingOnUploadStatus: false,
            initUploadState: false,
            uploadPercentage: 0,
            errorFlag: false,
            selectedUniqueIds: [],
            selectedUniquesPool: [],
          });
          clearUploadStatus();
        }}
        size={modalSizes.full}
        title={t('page_content.projects.file_upload_modal.title')}
      >
        {uploadLoader ? <div className="fileUpload_modal_wrapper">
          <TableLoader loading={uploadLoader} />
          <div>{t('page_content.projects.file_upload_modal.uploading_file')}</div>
        </div> : <div className="fileUpload_modal_wrapper">
          <div className="modal_row">
            <div>{t('page_content.projects.file_upload_modal.upload_prompt')}</div>
            <Button disabled={initSuccess} onClick={handleUploadWindow}>{t('page_content.projects.file_upload_modal.upload_button')}</Button>
          </div>
          {uploadResponse && uploadResponse.length > 0 &&
            <div className="modal_table_row">
              <div>{`${t('page_content.projects.file_upload_modal.found_string_1')} ${uploadResponse.length} ${t('page_content.projects.file_upload_modal.found_string_2')}`}</div>
              <Table
                style={{ userSelect: 'text', width: '900px' }}
                data={uploadResponse}
                noDataText=""
                minRows={0}
                selectedRow={null}
                defaultPageSize={10}
                showPagination
                manual={false}
                nextText={t('pagination_table.next')}
                previousText={t('pagination_table.previous')}
                columns={[
                  ...returnTableColumns(fileTypeForUpload),
                ]}
              />
              {!initUploadState ? (
                <div>
                  <Button
                    disabled={!uploadDataId}
                    onClick={() => { this.startUploadAndGetProgress(); }}
                  >{t('page_content.projects.file_upload_modal.import_button')}</Button>
                  <Button type="export" loading={isLoadingExportToExcel} onClick={() => { this.exportExcel(); }}>{t('page_content.projects.file_upload_modal.exprot_to_excel')}</Button></div>

              ) : waitingOnUploadStatus ? <div className="fileUpload_uploadStatus"><TableLoader loading={waitingOnUploadStatus} /><div>{`${t('page_content.projects.file_upload_modal.import_progress')} - ${uploadPercentage}%`}</div></div> : errorFlag ? t('page_content.projects.file_upload_modal.error_flag') : t('page_content.projects.file_upload_modal.import_button_alt')}
            </div>}
          {(uploadStatus && uploadStatus.items && uploadStatus.items.length === 0) && <div className="import_emptyArr_button">{t('page_content.projects.file_upload_modal.noPartsMsg')}
          </div>}
        </div>}
      </Modal>
    );
  }
}

FileUploadModal.propTypes = {
  modalStatus: PropTypes.bool.isRequired,
  closeImportModal: PropTypes.func,
  handleUploadWindow: PropTypes.func.isRequired,
  uploadResponse: PropTypes.array,
  uploadLoader: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  uploadDataId: PropTypes.number,
  projectId: PropTypes.number.isRequired,
  uploadStatus: PropTypes.object,
  clearUploadStatus: PropTypes.func.isRequired,
  uploadFileType: PropTypes.string,
};

export default (withTranslation()(FileUploadModal));
