import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import { addBudget, editBudget, getWorkingPlaces } from '../actions';

const WorkingPlaceBudgetModal = ({
  t,
  isOpen,
  companyId,
  handleCloseModal,
  departmentOptions,
  initialValues = {},
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [workingPlaceOptions, setWorkingPlaceOptions] = useState([]);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      ...formValues,
      company: companyId,
      profession: formValues?.workingPlace?.id,
      department: formValues?.department?.id,
    };

    delete payload.workingPlace;

    if (formValues?.id) {
      await editBudget(companyId, formValues?.id, payload);
    } else {
      await addBudget(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  const fetchWorkingPlaces = async () => {
    const res = await getWorkingPlaces(formValues?.department?.id);
    setWorkingPlaceOptions(get(res, 'data.results', []));
  };

  useEffect(() => {
    if (formValues?.department) fetchWorkingPlaces();
  }, [formValues?.department]);

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      enableSaveOnEnter={false}
      title={formValues?.id ? t('page_content.administration.control_panel_tab.modal_working_place_title_edit') : t('page_content.administration.control_panel_tab.modal_working_place_title_add')}
      disableSave={!formValues?.year || !formValues?.department || !formValues?.workingPlace}
    >
      {
        isLoading ?
        <ContentLoader /> :
          <div className="budgets_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.year_column')}: *</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'year', '')} onChange={(e) => onChange(e.target.value, 'year')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.net_salary_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'net_salary', '')} onChange={(e) => onChange(e.target.value, 'net_salary')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.non_taxable_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'non_taxable', '')} onChange={(e) => onChange(e.target.value, 'non_taxable')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.gross_salary_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'gross_salary', '')} onChange={(e) => onChange(e.target.value, 'gross_salary')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.gross_salary_2_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'gross_salary_2', '')} onChange={(e) => onChange(e.target.value, 'gross_salary_2')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.food_fee_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'food_fee', '')} onChange={(e) => onChange(e.target.value, 'food_fee')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.transport_fee_column')}:</div>
              <div className="right_input">
                <input type="number" value={get(formValues, 'transport_fee', '')} onChange={(e) => onChange(e.target.value, 'transport_fee')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.department_column')}: *</div>
              <div className="right_select">
              <Select
                options={departmentOptions.sort((a, b) => a.name.localeCompare(b.name))}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={t('page_content.administration.control_panel_tab.modal_select_department_placeholder')}
                isClearable
                menuPosition="fixed"
                onChange={(e) => onChange(e, 'department')}
                value={departmentOptions.find((a) => a.id === get(formValues, 'department.id', '')) || ''}
                styles={selectModalStyles}
              />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.administration.control_panel_tab.working_place_column')}: *</div>
              <div className="right_select">
              <Select
                options={workingPlaceOptions.sort((a, b) => a.name.localeCompare(b.name))}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder={t('page_content.administration.control_panel_tab.modal_select_working_place_placeholder')}
                isClearable
                menuPosition="fixed"
                onChange={(e) => onChange(e, 'workingPlace')}
                value={workingPlaceOptions.find((a) => a.id === get(formValues, 'workingPlace.id', '')) || ''}
                styles={selectModalStyles}
                isDisabled={!get(formValues, 'department', false)}
              />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

WorkingPlaceBudgetModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  departmentOptions: PropTypes.array,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(WorkingPlaceBudgetModal));
