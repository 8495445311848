import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { defaultDateFormat } from 'shared/constants';
import { IconWarning } from 'shared/Icons';
import { ContentLoader } from 'shared';
import { colors } from 'shared/colors';

import {
  getEvaluations,
  getValueOptions,
  getCompanyValues,
  getWorkerEvaluations,
} from '../../actions';
import '../../styles.scss';

const CompanyValuesRating = ({ t, currentUser, companyId, selectedWorker }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [note, setNote] = useState('');

  const [companyValues, setCompanyValues] = useState({
    data: [],
    isLoading: true,
  });

  const [valueOptions, setValueOptions] = useState({
    data: [],
    isLoading: true,
  });

  const [workerEvaluations, setWorkerEvaluations] = useState({
    isLoading: true,
    data: [],
    selectedItem: null,
  });

  const fetchCompanyValues = () => {
    setCompanyValues((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getCompanyValues(companyId)
      .then((res) => {
        setCompanyValues({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => {
        setCompanyValues((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchValueOptions = () => {
    setValueOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getValueOptions(companyId)
      .then((res) => {
        setValueOptions({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => {
        setValueOptions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const onWorkerEvaluationChange = (value) => {
    setWorkerEvaluations((prevState) => ({
      ...prevState,
      selectedItem: value,
    }));
    setFormValues({});
    setNote(value?.note || '');

    if (value) {
      setIsLoading(true);
      getEvaluations(companyId, `&worker_evaluation=${value?.id}`)
        .then((res) => {
          const selectedEvaluationData = get(res, 'data.results', []);

          const evaluationAnswers = selectedEvaluationData.reduce((acc, answer) => {
            const { option } = answer;
            const { value: valueOption } = option;

            if (!acc[valueOption?.id]) {
              acc[valueOption?.id] = {
                option1: null,
                option2: null,
              };
            }

            if (!acc[valueOption?.id].option1) {
              acc[valueOption?.id].option1 = option;
            } else {
              acc[valueOption?.id].option2 = option;
            }

            return acc;
          }, {});

          setFormValues(evaluationAnswers);
          setIsLoading(false);
          setWorkerEvaluations((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        })
        .catch(() => {
          setWorkerEvaluations((prevState) => ({
            ...prevState,
            selectedItem: null,
            isLoading: false,
          }));
          setIsLoading(false);
        });
    }
  };

  const fetchWorkerEvaluations = () => {
    setWorkerEvaluations((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getWorkerEvaluations(companyId, `&worker=${selectedWorker?.id}&order_by=-created_at`)
      .then((res) => {
        setWorkerEvaluations({ data: get(res, 'data.results', []) });
        onWorkerEvaluationChange(get(res, 'data.results[0]', null));
      })
      .catch(() => {
        setWorkerEvaluations((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchCompanyValues();
    fetchValueOptions();
    fetchWorkerEvaluations();
  }, []);

  const getNameAndScore = (id, key) => {
    const data = formValues?.[id] || null;
    const option = data?.[key] || null;
    return option?.name ? `${option?.name || '-'} (${option?.score?.score || '-'} ${t('page_content.administration.rating.points')})` : '-';
  };

  if (isLoading || companyValues?.isLoading || valueOptions?.isLoading || workerEvaluations?.isLoading) {
    return <ContentLoader />;
  }

  if (!currentUser?.worker_id) {
    return (
      <span className="ratings__screen__warning">
        <IconWarning color={colors.red} height="18px" width="18px" />
        <p>{t('page_content.administration.rating.no_rating')}</p>
      </span>
    );
  }

  return (
    <div className="ratings__screen">
      <span style={{ width: '300px' }}>
        <Select
          options={workerEvaluations?.data}
          getOptionLabel={(option) => <span>{t('page_content.administration.rating.evaluation')} - {moment(option.date).format(defaultDateFormat)}</span>}
          getOptionValue={(option) => option}
          placeholder={t('page_content.administration.rating.view_previous_evaluations')}
          menuPosition="fixed"
          onChange={(e) => onWorkerEvaluationChange(e || null)}
          value={(workerEvaluations?.data?.find((sOption) => (sOption?.id === workerEvaluations?.selectedItem?.id))) || ''}
          styles={selectModalStyles}
        />
      </span>

      {
        workerEvaluations?.selectedItem?.evaluator &&
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontWeight: '600', margin: 0 }}>{`${t('page_content.administration.rating.evaluator')}: ${workerEvaluations?.selectedItem?.evaluator?.name} ${workerEvaluations?.selectedItem?.evaluator?.last_name}`}</p>
        </div>
      }

      {
        workerEvaluations?.selectedItem?.id && (
          <div className="custom_inputs_view">
            {
              companyValues?.data?.map((value) => (
                <div key={value.id} className="modal_row">
                  <div className="left_text">
                    <label>{value?.name}</label>
                  </div>

                  <div className="multiple_right_text">
                    <p>{getNameAndScore(value?.id, 'option1') || '-'}</p>
                    <p>{getNameAndScore(value?.id, 'option2') || '-'}</p>
                  </div>
                </div>))
            }

            {
              workerEvaluations?.selectedItem?.id && (
                <div className="modal_row">
                  <div className="left_text">{t('page_content.administration.rating.comment')}</div>
                  <div className="right_text">
                    <p>{note || '-'}</p>
                  </div>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
};

CompanyValuesRating.propTypes = {
  t: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  selectedWorker: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id'),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(CompanyValuesRating));
