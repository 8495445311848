import React, { Component } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'shared';

class AddPalletsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1,
      pallet_type: 'EPAL',
      tare_weight: 0,
      create_pdf: true,
    };
  }

  onSave = () => {
    const { pallet_type, quantity, tare_weight, create_pdf } = this.state;
    const { addPallets } = this.props;

    addPallets({ prefix: pallet_type, count: quantity || 1, tare_weight: tare_weight || 0, create_pdf: create_pdf || !(quantity > 10) });
  }

  onChange = (key, value) => {
    if (key === 'quantity' && value > 10) {
      this.setState({
        create_pdf: false,
      });
    }
    this.setState({
      [key]: value,
    });
  }

  blockInvalidChar = (e) => ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();

  render() {
    const { quantity, pallet_type, tare_weight, create_pdf } = this.state;
    const { closeAddPalletsModal, t } = this.props;

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '400px',
        minHeight: '34px',
        height: '34px',
        padding: 0,
        fontSize: '14px',
        color: '#555',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: '0',
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 400,
        borderRadius: 0,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    const pallets_types = [
      { value: 'EPAL', name: 'EPAL' },
      { value: 'K', name: 'K' },
      { value: 'D', name: 'D' },
      { value: 'G', name: 'G' },
    ];

    return (
      <Modal
        isOpen
        handleSave={this.onSave}
        handleClose={closeAddPalletsModal}
        title={t('page_content.warehouse.add_pallet')}
      >

        <div className="default-form">
          <table>
            <tbody>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.pallet_type')}:
                  </label>
                </td>
                <td className="input">
                  <Select
                    options={pallets_types}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.value}
                    isSearchable
                    menuPosition="fixed"
                    onChange={(value) => this.onChange('pallet_type', value.value)}
                    value={(pallets_types.find((o) => o.value === pallet_type)) || ''}
                    styles={selectStyles}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.quantity')}:
                  </label>
                </td>
                <td className="input">
                  <input
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={(e) => { this.onChange('quantity', e.target.value); }}
                    onKeyDown={this.blockInvalidChar}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.tare_weight')}:
                  </label>
                </td>
                <td className="input">
                  <input
                    type="text"
                    value={tare_weight}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) this.onChange('tare_weight', value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="label">
                  <label>
                    {t('page_content.warehouse.create_pdf')}:
                  </label>
                </td>
                <td className="input">
                  <input
                    type="checkbox"
                    disabled={quantity > 10}
                    checked={create_pdf}
                    onChange={(e) => { this.onChange('create_pdf', e.target.checked); }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

AddPalletsModal.propTypes = {
  t: PropTypes.func.isRequired,
  closeAddPalletsModal: PropTypes.func.isRequired,
  addPallets: PropTypes.func.isRequired,
};

export default withTranslation()(AddPalletsModal);
