import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { ordersCreatedAndUpdated } from 'shared/constants';
import { ContentLoader, Button } from 'shared';
import '../styles.scss';

import { getProcurement, editProcurement } from '../actions';

const DetailsCard = ({ t, companyId, orderId, currentUser }) => {
  const [procurementData, setProcurementData] = useState({
    data: {},
    isLoading: false,
  });

  const fetchProcurement = () => {
    setProcurementData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const apiFilters = `company=${companyId}`;

    getProcurement(orderId, apiFilters)
      .then((res) => {
        const fetchedData = get(res, 'data', {});
        setProcurementData({
          data: fetchedData,
          isLoading: false,
        });
      })
      .catch(() => {
        setProcurementData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const handleApprove = async () => {
    setProcurementData((prevState) => ({ ...prevState, isLoading: true }));

    await editProcurement(companyId, orderId, {
      approved_by: currentUser.id,
      approved_time: moment(),
    });

    fetchProcurement();
  };

  const handleApprovePM = async () => {
    setProcurementData((prevState) => ({ ...prevState, isLoading: true }));

    await editProcurement(companyId, orderId, {
      pm_approved_by: currentUser.id,
      pm_approved_time: moment(),
    });

    fetchProcurement();
  };

  useEffect(() => {
    fetchProcurement();
  }, [companyId, orderId]);

  const procurementStatusOptions = [
    { id: 'created', name: t('page_content.procurement.status_created') },
    { id: 'approved', name: t('page_content.procurement.status_approved') },
    { id: 'denied', name: t('page_content.procurement.status_denied') },
    { id: 'ordered', name: t('page_content.procurement.status_ordered') },
    { id: 'delivered', name: t('page_content.procurement.status_delivered') },
    { id: 'finished', name: t('page_content.procurement.status_finished') },
  ];

  const procurementOrderTypeOptions = [
    { id: 'material', name: t('page_content.procurement.order_type_material') },
    { id: 'service', name: t('page_content.procurement.order_type_service') },
  ];

  const fields = [
    {
      label: t('page_content.procurement.details.cards.table_column_number'),
      key: 'number',
    },
    {
      label: t('page_content.procurement.details.cards.table_column_project'),
      key: 'project.name',
    },
    {
      label: t('page_content.procurement.details.cards.table_column_order_type'),
      key: 'order_type',
      customRender: (data) => procurementOrderTypeOptions.find((option) => option.id === data)?.name,
    },
    {
      label: t('page_content.procurement.details.cards.table_column_item_count'),
      key: 'items_count',
    },
    {
      label: t('page_content.procurement.details.cards.table_column_description'),
      key: 'description',
    },
    {
      label: t('page_content.procurement.details.cards.table_column_creator'),
      key: 'user',
      customRender: (data) => (data ? `${data.first_name} ${data.last_name}` : '-'),
    },
    {
      label: t('page_content.procurement.details.cards.table_column_created_date'),
      key: 'created_at',
      isDate: true,
    },
    {
      label: t('page_content.procurement.details.cards.table_column_destination'),
      key: 'destination.name',
    },
    {
      label: t('page_content.procurement.details.cards.table_column_reorder'),
      key: 'is_reorder',
      isBoolean: true,
    },
    {
      label: t('page_content.procurement.details.cards.table_column_label'),
      key: 'external_id',
    },
    {
      label: t('page_content.procurement.details.cards.table_column_approver'),
      key: 'approved_by',
      customRender: (data) => (data ? `${data.first_name} ${data.last_name}` : '-'),
    },
    {
      label: t('page_content.procurement.details.cards.table_column_approval_time'),
      key: 'approved_time',
      isDate: true,
    },
    {
      label: t('page_content.procurement.details.cards.table_column_pm_approver'),
      key: 'pm_approved_by',
      customRender: (data) => (data ? `${data.first_name} ${data.last_name}` : '-'),
    },
    {
      label: t('page_content.procurement.details.cards.table_column_pm_approval_time'),
      key: 'pm_approved_time',
      isDate: true,
    },
    {
      label: t('page_content.procurement.details.cards.table_column_received'),
      key: 'received_by',
      customRender: (data) => (data ? `${data.first_name} ${data.last_name}` : '-'),
    },
    {
      label: t('page_content.procurement.details.cards.table_column_received_time'),
      key: 'received_time',
      isDate: true,
    },
    {
      label: t('page_content.procurement.details.cards.table_column_status'),
      key: 'status',
      customRender: (data) => procurementStatusOptions.find((option) => option.id === data)?.name,
    },
    {
      label: t('page_content.procurement.details.cards.table_column_supplier_number'),
      key: 'supplier_number',
    },
  ];

  const renderFieldValue = (value, isDate, isBoolean, customRender) => {
    if (typeof customRender === 'function') return customRender(value);

    if (isDate) {
      return value ? moment(value).format(ordersCreatedAndUpdated) : '-';
    }
    if (isBoolean) {
      return value ? <img src={checkMarkTrue} alt="" width="20px" height="20px" /> : <img src={checkMarkFalse} alt="" width="20px" height="20px" />;
    }
    return value || '-';
  };

  const getNestedValue = (obj, key) => key.split('.').reduce((acc, part) => (acc ? acc[part] : undefined), obj);

  if (procurementData.isLoading) return <ContentLoader />;

  return (
    <div className="details-card">
      {fields.map((field) => {
        const isNested = field.key.includes('.');
        const fieldValue = isNested ? getNestedValue(procurementData.data, field.key) : get(procurementData.data, field.key);
        return (
          <div key={field.key} className="details-card__field">
            <div className="details-card__field__key">{field.label}:</div>
            <div className="details-card__field__value">{renderFieldValue(fieldValue, field.isDate, field.isBoolean, field.customRender)}</div>

          </div>
        );
      })}

      <div style={{ gridArea: 'button', display: 'flex', alignItems: 'center', width: '100%', gap: '5px' }}>
        <Button type="dark-blue" style={{ width: '100%' }} onClick={handleApprove} disabled={procurementData?.data?.approved_by}>{t('page_content.procurement.details.cards.button_approve')}</Button>
        <Button type="dark-blue" style={{ width: '100%' }} onClick={handleApprovePM} disabled={procurementData?.data?.pm_approved_by}>{t('page_content.procurement.details.cards.button_approve_pm')}</Button>
      </div>
    </div>
  );
};

DetailsCard.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: get(state, 'currentUser', null),
});

export default connect(mapStateToProps, null)(withTranslation()(DetailsCard));
