import React, { useState } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal, ContentLoader } from 'shared';
import { modalSizes } from 'shared/constants';
import '../styles.scss';

import { addProcurement, editProcurement } from '../actions';

const ProcurementModal = ({
  t,
  isOpen,
  companyId,
  currentUser,
  projects = [],
  handleCloseModal,
  destinations = [],
  initialValues = {},
  closeModalAndRefetch,
  procurementOrderTypeOptions = [],
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState('');

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const requiredFields = [
      'project',
      'order_type',
      'destination',
    ];

    const isFormValid = requiredFields?.every((field) => !!formValues?.[field]);

    if (!isFormValid) {
      setIsLoading(false);
      setError(t('page_content.procurement.modal.please_fill_in_all_required_fields'));
      return;
    }

    setIsLoading(true);
    setError('');

    const payload = {
      ...formValues,
      company: companyId,
      user: currentUser.id,
      project: get(formValues, 'project.id', null),
      destination: get(formValues, 'destination.id', null),
      approved_by: get(formValues, 'approved_by.id', null),
      pm_approved_by: get(formValues, 'pm_approved_by.id', null),
    };

    if (formValues?.id) {
      await editProcurement(companyId, formValues?.id, payload);
    } else {
      await addProcurement(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      title={formValues?.id ? t('page_content.procurement.modal.edit_procurement') : t('page_content.procurement.modal.add_new_procurement')}
      error={error}
    >
      {
        isLoading ?
        <ContentLoader /> :
        <div className="order_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.procurement.modal.project')}:*</div>
              <div className="right_select">
                <Select
                  options={projects}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  placeholder={t('page_content.procurement.modal.select_a_project')}
                  onChange={(e) => onChange(e || null, 'project')}
                  value={projects?.find((p) => p.id === get(formValues, 'project.id', null))}
                  styles={selectModalStyles}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.procurement.modal.order_type')}:*</div>
              <div className="right_select">
                <Select
                  options={procurementOrderTypeOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  placeholder={t('page_content.procurement.modal.select_an_order_type')}
                  onChange={(e) => onChange(e?.id || null, 'order_type')}
                  value={procurementOrderTypeOptions?.find((op) => op.id === get(formValues, 'order_type', null))}
                  styles={selectModalStyles}
                />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.procurement.modal.description')}:</div>
              <div className="right_input">
                <input type="text" value={get(formValues, 'description', '')} onChange={(e) => onChange(e.target.value, 'description')} />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.procurement.modal.destination')}:*</div>
              <div className="right_select">
                <Select
                  options={destinations}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  menuPosition="fixed"
                  placeholder={t('page_content.procurement.modal.select_a_destination')}
                  onChange={(e) => onChange(e || null, 'destination')}
                  value={destinations?.find((op) => op.id === get(formValues, 'destination.id', null))}
                  styles={selectModalStyles}
                />
                </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.procurement.modal.reorder')}:</div>
              <div className="right_checkbox">
                <input type="checkbox" checked={get(formValues, 'is_reorder', false)} onChange={(e) => onChange(e.target.checked, 'is_reorder')} />
              </div>
            </div>
          </div>
      }
    </Modal>
  );
};

ProcurementModal.propTypes = {
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  projects: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  destinations: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  closeModalAndRefetch: PropTypes.func.isRequired,
  procurementOrderTypeOptions: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  currentUser: get(state, 'currentUser', null),
});

export default connect(mapStateToProps, null)(withTranslation()(ProcurementModal));
