import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button } from 'shared';
import ProcurementItemsCalendar from './ProcurementItemsCalendar';
import ProcurementItemsTable from './ProcurementItemsTable';
import '../styles.scss';

const ProcurementItemsTab = ({ t, companyId, locationId }) => {
  const [currentView, setCurrentView] = useState('calendar');

  const viewOptions = [
    { id: 'table', name: t('page_content.procurement.details.items_table.table') },
    { id: 'calendar', name: t('page_content.procurement.details.items_table.calendar') },
  ];

  const statusDropdownOptions = [
    { value: 'all', label: t('page_content.procurement.details.items_table.all') },
    { value: 'delivered', label: t('page_content.procurement.details.items_table.delivered') },
    { value: 'not_delivered', label: t('page_content.procurement.details.items_table.not_delivered') },
  ];

  return (
    <div className="procurement-items">
      <div className="procurement-items__right-actions">
        <label className="switch">
          {viewOptions.map((option) => (
            <Button
              onClick={() => setCurrentView(option.id)}
              type={currentView === option.id ? 'dark-blue' : 'plain'}
            >
              {t(option?.name)}
            </Button>
          ))}
        </label>
      </div>
      {
        currentView === 'table' ?
          <ProcurementItemsTable t={t} locationId={locationId} companyId={companyId} statusDropdownOptions={statusDropdownOptions} /> :
          <ProcurementItemsCalendar t={t} locationId={locationId} companyId={companyId} statusDropdownOptions={statusDropdownOptions} />
      }
    </div>
  );
};

ProcurementItemsTab.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default withRouter(ProcurementItemsTab);
