import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import '../styles.scss';

class DeliveryLegend extends Component {
  render() {
    const { t } = this.props;

    const statuses = [
      { status: t('page_content.procurement.details.items_table.delivered'), color: '#10AC84' },
      { status: t('page_content.procurement.details.items_table.not_delivered_on_time'), color: '#E54949' },
      { status: t('page_content.procurement.details.items_table.awaiting_delivery'), color: '#AAB0BF' },
    ];

    return (
      <div className="delivery-legend">
        {statuses.map((status) => {
          return (
            <div className="delivery-legend__item" key={status.status}>
              <div
                className="color-box"
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '5px',
                  backgroundColor: status.color,
                }}
              />
              <span className="delivery-legend__label">{status.status}</span>
            </div>
          );
        })}
      </div>
    );
  }
}

DeliveryLegend.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(DeliveryLegend);
