import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { Modal, ContentLoader } from 'shared';
import { getLocale } from 'shared/DatePicker/constants';
import { modalSizes } from 'shared/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { addParameterValue, editParameterValue, getDefinitions } from '../actions';

const ParameterValuesModal = ({
  t,
  isOpen,
  companyId,
  locationId,
  handleCloseModal,
  initialValues = {},
  closeModalAndRefetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [definitions, setDefinitions] = useState({
    data: [],
    count: null,
    isLoading: true,
  });

  const fetchDefinitions = () => {
    setDefinitions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const apiFilters = `company=${companyId}`;

    getDefinitions(apiFilters)
      .then((res) => {
        setDefinitions({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => {
        setDefinitions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchDefinitions();
  }, []);

  const onChange = (value, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      ...formValues,
      company: companyId,
      location: locationId,
      definition: formValues?.definition?.id,
    };

    if (formValues?.id) {
      await editParameterValue(companyId, formValues?.id, payload);
    } else {
      await addParameterValue(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleSave={handleSave}
      size={modalSizes.medium}
      handleClose={handleCloseModal}
      enableSaveOnEnter={false}
    >
      {
        isLoading ?
        <ContentLoader /> :
          <div className="asset_form_modal_container">
            <div className="modal_row">
              <div className="left_text">{t('page_content.workforce_management.value')}:</div>
              <div className="right_input">
              <input
                type="number"
                value={get(formValues, 'value', '')}
                onChange={(e) => {
                  let value = e.target.value;
                  // Limit to 2 decimal places
                  if (value.includes('.')) {
                    const parts = value.split('.');
                    if (parts[1].length > 2) {
                      value = `${parts[0]}.${parts[1].slice(0, 2)}`;
                    }
                  }
                  onChange(value, 'value');
                }}
                step="0.01"
              />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.workforce_management.date')}:</div>
              <div className="right_input">
              <DatePicker
                className="date_picker"
                placeholderText=""
                dateFormat="dd.MM.yyyy"
                selected={formValues?.month ? moment(formValues.month, 'YYYY-MM-DD').toDate() : null}
                onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'), 'month')}
                locale={getLocale(t)}
              />
              </div>
            </div>

            <div className="modal_row">
              <div className="left_text">{t('page_content.workforce_management.definition')}:</div>
              <div className="right_select">
              <Select
                options={definitions.data}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isSearchable
                placeholder=""
                menuPosition="fixed"
                onChange={(o) => onChange(o, 'definition')}
                value={formValues?.definition || ''}
                styles={selectModalStyles}
              />
              </div>
          </div>
          </div>
      }
    </Modal>
  );
};

ParameterValuesModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(ParameterValuesModal));
