/* eslint-disable no-undef */
import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { Table, TableButtons } from 'shared';

// import { getOrders, getPaginatedOrders } from '../actions';
import '../styles.scss';

const AuditLog = ({ companyId }) => {
  const [tableData, setTableData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });

  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  // eslint-disable-next-line no-unused-vars
  const fetchLogs = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${filters?.selectedSort}`;

    getOrders(companyId, apiFilters)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedLogs = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedOrders(url)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  // useEffect(() => {
  //   fetchLogs();
  // }, [companyId, filters]);

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    sortKey = sortKey.replace('.', '__');

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  return (
    <div className="procurement-details__logs">
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>Datum izmjene</span>,
            accessor: 'name',
          },
          {
            Header: () => <span>Izmjena</span>,
            accessor: 'none',
          },
          {
            Header: () => <span>Korisnik</span>,
            accessor: 'none',
          },
        ]}
        minRows={0}
        noDataText=""
        selectedRow={null}
        defaultPageSize={100}
        showPagination={false}
        data={tableData?.data || []}
        loading={tableData?.isLoading}
        defaultSorted={[{ id: 'name', desc: false }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <div>
        <span style={{ float: 'right' }}>
          <TableButtons
            next={tableData?.next}
            count={tableData?.count}
            previous={tableData?.previous}
            fetchFunction={fetchPaginatedLogs}
          />
        </span>
      </div>
    </div>
  );
};

AuditLog.propTypes = {
  // t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default AuditLog;
