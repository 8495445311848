import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IconSearch } from 'shared/Icons';
import { defaultDateFormat } from 'shared/constants';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { Table, TableButtons, Button, DateFilters, ConfirmationModal } from 'shared';
import { checkAccessOnPage, redirectToHomePage } from 'industry/helpers';
import { selectStyles } from 'styles/modules/reactSelect';
import ComplaintModal from './components/ComplaintModal';
import { getComplaints, getPaginatedComplaints, archiveComplaint } from './actions';

const Complaints = ({ t, locationId, companyId }) => {
  const [data, setData] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({
    deleteData: null,
    showConfirmationDialog: false,
  });
  const [filtersAndSorting, setFiltersAndSorting] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'date_received',
    justified: null,
    date: null,
    clearDateFilter: false,
    term: '',
    param: { id: 'product_series' },
  });
  const [modalValues, setModalValues] = useState({
    initialData: null,
    showModal: false,
  });
  const [paginationData, setPaginationData] = useState({
    count: null,
    next: null,
    previous: null,
    isLoading: true,
  });

  const { selectedAscDesc, selectedSort, justified, date, clearDateFilter, term, param } = filtersAndSorting;

  const fetchComplaintsList = () => {
    setPaginationData({ isLoading: true });
    let urlFilters = '';

    if (justified) {
      urlFilters += `&complaint_is_justified=${justified?.id}`;
    }

    if (date) {
      urlFilters += `&${date?.filters}_after=${date?.start.format('YYYY-MM-DD')}&${date?.filters}_before=${date?.end.format('YYYY-MM-DD')}`;
    }

    if (param?.id && term) {
      urlFilters += `&${param.id}=${term}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `&order_by=${asc}${selectedSort}`;

    getComplaints(companyId, locationId, urlFilters)
      .then((res) => {
        setPaginationData({
          count: get(res, 'data.count') || null,
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoading: false,
        });
        setData(get(res, 'data.results'));
      })
      .catch((e) => console.error('Error while fetching complaints', e));
  };

  useEffect(() => {
    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          setIsReadOnly(true);
        }
      });

    fetchComplaintsList();
  }, []);

  useEffect(() => {
    fetchComplaintsList();
  }, [selectedAscDesc, selectedSort, justified, date, clearDateFilter]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchComplaintsList();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [term, param]);

  const fetchPaginatedComplaintsList = (url) => {
    getPaginatedComplaints(url)
      .then((res) => {
        setPaginationData({
          count: get(res, 'data.count') || null,
          next: get(res, 'data.next'),
          previous: get(res, 'data.previous'),
          isLoading: false,
        });
        setData(get(res, 'data.results'));
      })
      .catch((e) => console.error('Error while fetching paginated complaints', e));
  };

  const openModal = (rowInfo) => {
    if (rowInfo?.original?.id) setModalValues({ initialData: rowInfo.original, showModal: true });
    else setModalValues({ initialData: null, showModal: true });
  };

  const handleClose = () => {
    setModalValues({ initialData: null, showModal: false });
    fetchComplaintsList();
  };

  const handleSorting = (sortData) => {
    let column = sortData.id;

    if (column === 'product_type.name') {
      column = 'product_type';
    }
    if (column === 'line.name') {
      column = 'line';
    }

    setFiltersAndSorting((prevState) => ({
      ...prevState,
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }));
  };

  const handleFiltering = (value, key) => {
    setFiltersAndSorting((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const clearAllFilters = (key) => {
    if (key === 'term') {
      setFiltersAndSorting((prevState) => ({
        ...prevState,
        term: '',
      }));
    } else {
      setFiltersAndSorting((prevState) => ({
        ...prevState,
        justified: null,
        date: null,
        clearDateFilter: !prevState.clearDateFilter,
        query: {},
      }));
    }
  };

  const handleArchiveComplaint = async () => {
    if (confirmationDialog?.deleteData?.id) {
      await archiveComplaint(confirmationDialog.deleteData.id, companyId);
      setConfirmationDialog({ showConfirmationDialog: false, deleteData: null });
    }
    fetchComplaintsList();
  };

  const handleShowConfirmationModal = (e, row) => {
    e.stopPropagation();
    setConfirmationDialog({ showConfirmationDialog: true, deleteData: get(row, 'original', null) });
  };

  const tableColumnConfig = [
    {
      Header: () => <span>{t('page_content.complaints.table.notification_number')}</span>,
      accessor: 'notification_number',
      width: 150,
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.date_received')}</span>,
      accessor: 'date_received',
      width: 150,
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.customer')}</span>,
      accessor: 'customer',
      width: 150,
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.reason')}</span>,
      accessor: 'reason',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.product_name')}</span>,
      accessor: 'product_type.name',
      width: 150,
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.product_series')}</span>,
      accessor: 'product_series',
      width: 150,
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.line')}</span>,
      accessor: 'line.name',
      width: 150,
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.complaint_is_justified')}</span>,
      accessor: 'complaint_is_justified',
      width: 100,
      Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>{row.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}</div>,
    },
    {
      Header: () => <span>{t('page_content.complaints.table.cause')}</span>,
      accessor: 'cause',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.activities')}</span>,
      accessor: 'activities',
      Cell: (row) => (row.value ? row.value : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.date_resolved')}</span>,
      accessor: 'date_resolved',
      width: 150,
      Cell: (row) => (row.value ? moment(row.value).format(defaultDateFormat) : '-'),
    },
    {
      Header: () => <span>{t('page_content.complaints.table.archive')}</span>,
      width: 130,
      sortable: false,
      Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            disabled={isReadOnly || get(row, 'original.is_deleted', false)}
            style={{ width: 'fit-content' }}
            onClick={(e) => handleShowConfirmationModal(e, row)}
            type="danger"
          >
            {get(row, 'original.is_deleted', false) ?
              t('page_content.complaints.status_archived') :
              t('page_content.complaints.table.archive')}
            </Button>
        </div>,
      // show: ordersArchivePermission,
      style: {
        cursor: 'default',
      },
    },
  ];

  const justifiedOptions = [
    { id: true, label: t('page_content.complaints.status_justified_yes') },
    { id: false, label: t('page_content.complaints.status_justified_no') },
  ];

  const dateFilterOptions = [
    { id: 'date_received', label: t('page_content.complaints.table.date_received'), showTime: false },
    { id: 'date_resolved', label: t('page_content.complaints.table.date_resolved'), showTime: false },
  ];

  const searchFilterOptions = [
    { id: 'product_series', label: t('page_content.complaints.table.product_series') },
    { id: 'customer', label: t('page_content.complaints.table.customer') },
    { id: 'notification_number', label: t('page_content.complaints.table.notification_number') },
  ];

  return (
    <div className="complaints_container">
      <div className="complaints_container-header">
      <div className="filters_row">
        <div className="input_container">
          <input
            name="search"
            onChange={(e) => handleFiltering(e.target.value, 'term')}
            placeholder={t('page_content.orders.search_for_placeholder')}
            value={term || ''}
          />
            {term && (<button onClick={() => clearAllFilters('term')}>&times;</button>)}
            <Select
              options={searchFilterOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.id}
              isSearchable={false}
              onChange={(e) => handleFiltering(e ?? null, 'param')}
              value={(searchFilterOptions.find((sOption) => sOption.id === param?.id)) || ''}
              styles={selectStyles}
            />
            <div className="icon_container">
              <IconSearch
                color="#555"
                height="26px"
                width="26px"
              />
            </div>
          </div>
        <div className="datepicker_box">
          <DateFilters
            selectedFilterProp={(filters, start, end) => handleFiltering({ filters, start: moment(start), end: moment(end) }, 'date')}
            filterOptions={dateFilterOptions}
            clearFilters={clearDateFilter}
          />
        </div>
        <div>
          <Select
            options={justifiedOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.id}
            isClearable
            menuPosition="fixed"
            placeholder={t('page_content.complaints.select_justified_placeholder')}
            onChange={(val) => handleFiltering(val, 'justified')}
            value={justified ? justifiedOptions.find((val) => val.id === justified?.id) : null}
            styles={selectStyles}
          />
        </div>
        <div className="clear_button">
          <Button
            type="plain"
            onClick={() => clearAllFilters('')}
          >
            {t('page_content.orders.clear_all_button')}
          </Button>
        </div>
      </div>
      <div className="add_complaint_button">
          <Button type="add" disabled={isReadOnly} onClick={openModal}>{t('page_content.complaints.button_add_new_complaint')}</Button>
        </div>
      </div>
      <div className="complaints_tableArea">
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumnConfig}
          data={data || []}
          minRows={0}
          showPagination={false}
          defaultPageSize={40}
          isCompact
          loading={paginationData.isLoading}
          handleClick={(rowInfo) => !isReadOnly && openModal(rowInfo)}
          defaultSorted={[{ id: 'date_received', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons previous={paginationData.previous} next={paginationData.next} fetchFunction={fetchPaginatedComplaintsList} count={paginationData.count} />
      </div>
      {modalValues.showModal &&
        <ComplaintModal
          t={t}
          modalValues={modalValues}
          locationId={locationId}
          companyId={companyId}
          handleClose={handleClose}
        />}
      <ConfirmationModal
        itemName={`${get(confirmationDialog.deleteData, 'notification_number', '-')}`}
        showModal={confirmationDialog.showConfirmationDialog}
        handleCloseModal={() => setConfirmationDialog({ showConfirmationDialog: false })}
        handleConfirmModal={handleArchiveComplaint}
        type="warning"
      />
    </div>
  );
};

Complaints.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const mapStateToProps = (state) => {
  return {
    companyId: get(state, 'app.company.id', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(Complaints));
