import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ContentLoader, ConfirmationModal } from 'shared';
import { IconRemove, IconLocked, IconUnlocked, IconX } from 'shared/Icons';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import copyImage from 'shared/Icons/FilePlus.png';
import '../styles.scss';

import { getLocale } from 'shared/DatePicker/constants';
import { defaultDateFormat, defaultISODateFormat } from 'shared/constants';

import { getMaterialTypes, getProcurementItems, getProcurementItem, getPaginatedProcurementItems, addProcurementItem, editProcurementItem, deleteProcurementItem } from '../actions';

const ItemsTable = ({ t, companyId, orderId, currentUser }) => {
  const [tableData, setTableData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'item_num',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [materialTypes, setMaterialTypes] = useState({
    data: [],
    isLoading: false,
  });
  const [confirmationModal, setConfirmationModal] = useState({
    showDeleteDialog: false,
    deleteData: null,
    showDuplicateDialog: false,
    duplicateData: null,
  });

  const fetchProcurementItems = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `company=${companyId}&procurement=${orderId}&limit=100&order_by=${asc}${filters?.selectedSort}`;

    getProcurementItems(apiFilters)
      .then((res) => {
        const fetchedData = get(res, 'data.results', []);
        const oldData = tableData?.data?.filter((item) => item?.addedOrEdited === 'added');
        fetchedData.push(...oldData);
        setTableData({
          data: fetchedData,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedProcurementItems = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedProcurementItems(url)
      .then((res) => {
        const fetchedData = get(res, 'data.results', []);
        const oldData = tableData?.data?.filter((item) => item?.addedOrEdited === 'added');
        fetchedData.push(...oldData);
        setTableData({
          data: fetchedData,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchMaterialTypes = () => {
    const apiFilters = `company=${companyId}&limit=9999`;

    getMaterialTypes(apiFilters)
      .then((res) => {
        const fetchedData = get(res, 'data.results', []);
        setMaterialTypes({
          data: fetchedData,
          isLoading: false,
        });
      })
      .catch(() => {
        setMaterialTypes((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchMaterialTypes();
  }, []);

  useEffect(() => {
    fetchProcurementItems();
  }, [filters]);

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    sortKey = sortKey.replace('.', '__');

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleInput = (value, row, column) => {
    const rowId = row?.original?.id || row?.index;
    const columnId = column?.id;
    const isRowNew = row?.original?.addedOrEdited === 'added';

    let updatedData = [];
    if (!isRowNew) {
      updatedData = tableData?.data?.map((item) => {
        if (item?.id === rowId) return { ...item, [columnId]: value, addedOrEdited: 'edited' };
        return item;
      });
    } else {
      updatedData = tableData?.data?.map((item, index) => {
        if (index === rowId) return { ...item, [columnId]: value };
        return item;
      });
    }
    setTableData((prevState) => ({
      ...prevState,
      data: updatedData,
    }));
  };

  const renderTextboxCell = (row, column) => {
    if (!isEditing) return row?.value || '-';
    return (
      <input
        className="procurement-items_text-field"
        style={{ borderColor: row?.original?.errors?.includes(column?.id) ? 'red' : '' }}
        type="text"
        value={row?.value || ''}
        onChange={(e) => handleInput(e.target.value, row, column)}
      />
    );
  };

  const renderQuantityCell = (row, column) => {
    if (!isEditing) return row?.value || '-';
    return (
      <input
        className="procurement-items_text-field"
        style={{ borderColor: row?.original?.errors?.includes(column?.id) ? 'red' : '' }}
        type="text"
        value={row?.value || ''}
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*\.?\d{0,2}$/.test(value)) {
            handleInput(value, row, column);
          }
        }}
      />
    );
  };

  const renderDateCell = (row, column) => {
    if (!isEditing) return row?.value ? moment(row.value).format(defaultDateFormat) : '-';
    return (
      <DatePicker
        className={`custom-datepicker ${row?.original?.errors?.includes(column?.id) ? 'error' : ''}`}
        dateFormat="dd.MM.yyyy"
        locale={getLocale(t)}
        placeholderText={t('')}
        selected={get(row, 'value', false) ? new Date(row.value) : null}
        onChange={(date) => handleInput(date, row, column)}
        popperProps={{ strategy: 'fixed' }}
      />
    );
  };

  const selectCustomStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      width: '100%',
      minHeight: '24px',
      height: '26px',
      padding: 0,
      fontSize: '13px',
      color: '#555',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '24px',
      padding: '0px 0px 0px 5px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0px 3px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
      paddingRight: 10,
      color: 'black',
      svg: {
        width: '15px',
        height: '15px',
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      borderRadius: 0,
      zIndex: 2,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '13px',
      fontWeight: 500,
      padding: '6px 12px',
    }),
  };

  const installationLocationsOptions = [
    { id: 'factory', name: t('page_content.procurement.details.items_table.installation_location_factory') },
    { id: 'site', name: t('page_content.procurement.details.items_table.installation_location_site') },
  ];

  const renderSelectCell = (row, column) => {
    switch (column.id) {
      case 'material_type': return !isEditing ? row?.value?.name || '-' : (
          <Select
            className={`custom-select ${row?.original?.errors?.includes(column?.id) ? 'error' : ''}`}
            options={materialTypes?.data || []}
            isLoading={materialTypes?.isLoading}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition="fixed"
            isClearable
            placeholder={t('')}
            onChange={(e) => handleInput(e || null, row, column)}
            value={row?.value}
            styles={selectCustomStyles}
          />
      );
      case 'installation_location': return !isEditing ? installationLocationsOptions?.find((op) => op.id === row?.value)?.name || '-' : (
          <Select
            className={`custom-select ${row?.original?.errors?.includes(column?.id) ? 'error' : ''}`}
            options={installationLocationsOptions}
            isLoading={materialTypes?.isLoading}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menuPosition="fixed"
            placeholder={t('')}
            onChange={(e) => handleInput(e?.id, row, column)}
            value={installationLocationsOptions?.find((op) => op.id === row?.value)}
            styles={selectCustomStyles}
          />
      );
      default: return row?.value || '-';
    }
  };

  const handleAddNewItem = () => {
    const newItem = {
      id: '',
      item_num: tableData?.data?.length + 1,
      name: '',
      quantity: '',
      addedOrEdited: 'added',
    };

    setTableData((prevState) => ({
      ...prevState,
      data: [...(prevState.data || []), newItem],
    }));
  };

  const handleDeleteItem = () => {
    if (confirmationModal.deleteData.original.addedOrEdited === 'added') {
      const deletedData = tableData?.data?.filter((item, index) => index !== confirmationModal.deleteData.index);
      const updatedData = deletedData.map((item, index) => ({ ...item, item_num: item?.id ? item.item_num : index + 1 }));
      setTableData((prevState) => ({
        ...prevState,
        data: updatedData,
      }));
      setConfirmationModal((prevState) => ({ ...prevState, showDeleteDialog: false, deleteData: null }));
    } else {
      deleteProcurementItem(companyId, confirmationModal.deleteData.original.id)
        .then(() => {
          fetchProcurementItems();
          setConfirmationModal((prevState) => ({ ...prevState, showDeleteDialog: false, deleteData: null }));
        });
    }
  };

  const handleDeleteConfirmation = (e, row) => {
    e.stopPropagation();
    setConfirmationModal((prevState) => ({ ...prevState, showDeleteDialog: true, deleteData: row }));
  };

  const handleDuplicateRow = () => {
    const original = confirmationModal.duplicateData;
    setTableData((prevState) => ({
      ...prevState,
      data: [...prevState.data, { ...original, id: null, item_num: prevState.data.length + 1, addedOrEdited: 'added' }],
    }));
    setConfirmationModal((prevState) => ({ ...prevState, showDuplicateDialog: false, duplicateData: null }));
  };

  const handleDuplicateConfirmation = (e, original) => {
    e.stopPropagation();
    setConfirmationModal((prevState) => ({ ...prevState, showDuplicateDialog: true, duplicateData: original }));
  };

  const handleSave = (e, row) => {
    e.stopPropagation();

    const requiredFields = [
      'item_num',
      'name',
      // // 'material_type',
      'quantity',
      'installation_location',
      'planned_delivery_date',
      // 'delivery_date',
      // // 'note',
      // // 'reserve_quantity',
      // // 'ordered_quantity',
      // // 'delivered_quantity',
    ];

    const missingFields = requiredFields.filter((field) => !row?.original?.[field]);

    const payload = {
      ...row.original,
      procurement: orderId,
      user: currentUser.id,
      material_type: get(row, 'original.material_type.id', null),
      planned_delivery_date: get(row, 'original.planned_delivery_date', false) ? moment(row.original.planned_delivery_date).format(defaultISODateFormat) : null,
      delivery_date: get(row, 'original.delivery_date', false) ? `${moment(row.original.delivery_date).format(defaultISODateFormat)}` : null,
    };

    if (missingFields.length) {
      setTableData((prevState) => {
        const updatedData = [...prevState.data];
        updatedData[row.index] = { ...row.original, errors: missingFields };
        return { ...prevState, data: updatedData };
      });
    } else if (row?.original?.addedOrEdited === 'added') {
      setTableData((prevState) => {
        const updatedData = [...prevState.data];
        updatedData[row.index] = { ...row.original, isLoading: true };
        return { ...prevState, data: updatedData };
      });

      addProcurementItem(companyId, payload)
        .then((res) => {
          const responseItem = get(res, 'data', {});
          const updatedData = tableData?.data || [];

          if (responseItem?.id) {
            // Successfully saved new item
            updatedData[row.index] = { ...responseItem, upload: 'success', isLoading: false };
          } else {
            // Failed to save new item
            const keys = Object.keys(res);
            if (keys.length > 0) {
              // Failed to save new item with errors
              updatedData[row.index] = { ...row.original, upload: 'failed', isLoading: false, errors: keys };
            } else {
              // Failed to save new item without errors
              updatedData[row.index] = { ...row.original, upload: 'failed', isLoading: false };
            }
          }
          setTableData((prevState) => ({ ...prevState, data: updatedData }));
        });
    } else if (row?.original?.addedOrEdited === 'edited') {
      setTableData((prevState) => {
        const updatedData = tableData?.data?.map((item) => {
          if (item.id === row.original.id) return { ...item, isLoading: true };
          return item;
        });
        return { ...prevState, data: updatedData };
      });

      editProcurementItem(companyId, payload.id, payload)
        .then((res) => {
          const responseItem = get(res, 'data', {});
          const updatedData = tableData?.data || [];
          const index = updatedData.findIndex((item) => item.id === row.original.id);

          if (responseItem?.id) {
            // Successfully saved edited item
            updatedData[index] = { ...responseItem, upload: 'success', isLoading: false };
          } else {
            // Failed to save edited item
            const keys = Object.keys(res);
            if (keys.length > 0) {
              // Failed to save edited item with errors
              updatedData[index] = { ...row.original, upload: 'failed', isLoading: false, errors: keys };
            } else {
              // Failed to save edited item without errors
              updatedData[index] = { ...row.original, upload: 'failed', isLoading: false };
            }
          }
          setTableData((prevState) => ({ ...prevState, data: updatedData }));
        });
    }
  };

  const handleDiscardChanges = (e, row) => {
    e.stopPropagation();

    setTableData((prevState) => {
      const updatedData = tableData?.data?.map((item) => {
        if (item.id === row.original.id) return { ...item, isLoading: true };
        return item;
      });
      return { ...prevState, data: updatedData };
    });
    getProcurementItem(companyId, row?.original.id)
      .then((res) => {
        const fetchedData = get(res, 'data', {});
        const updatedData = tableData?.data?.map((item) => {
          if (item.id === fetchedData.id) return { ...fetchedData, isLoading: false };
          return item;
        });
        setTableData((prevState) => ({ ...prevState, data: updatedData }));
      })
      .catch(() => {
        setTableData((prevState) => {
          const updatedData = tableData?.data?.map((item) => {
            if (item.id === row.original.id) return { ...item, isLoading: false };
            return item;
          });
          return { ...prevState, data: updatedData };
        });
      });
  };

  return (
    <div className="procurement-items">
      <div className="procurement-items__action_buttons">
        <Button
          type="icon"
          tooltipPlacement="top"
          tooltip={isEditing ? t('page_content.procurement.details.items_table.edit_lock_status_unlocked') : t('page_content.procurement.details.items_table.edit_lock_status_locked')}
          onClick={() => setIsEditing((prevState) => !prevState)}
        >
          {isEditing ? <IconUnlocked height="18px" width="18px" /> : <IconLocked height="18px" width="18px" />}
        </Button>

        <Button onClick={handleAddNewItem} type="add" disabled={!isEditing}>
          {t('page_content.procurement.details.items_table.button_add_new_item')}
        </Button>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_item_number')}{isEditing ? '*' : ''}</span>,
            accessor: 'item_num',
            Cell: (row) => row?.value || '-',
            width: 60,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_item_name')}{isEditing ? '*' : ''}</span>,
            accessor: 'name',
            Cell: (row) => renderTextboxCell(row, { id: 'name' }),
            style: { cursor: 'default' },
            width: 200,
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_material_type')}</span>,
            accessor: 'material_type',
            Cell: (row) => renderSelectCell(row, { id: 'material_type' }),
            width: 125,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_unit')}</span>,
            accessor: 'unit',
            Cell: (row) => renderTextboxCell(row, { id: 'unit' }),
            style: { cursor: 'default' },
            width: 75,
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_quantity')}{isEditing ? '*' : ''}</span>,
            accessor: 'quantity',
            Cell: (row) => renderQuantityCell(row, { id: 'quantity' }),
            width: 90,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_included_reserve')}</span>,
            // accessor: '',
            // Cell: (row) => renderTextboxCell(row, { id: '' }),
            style: { cursor: 'default' },
            width: 90,
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_installation_location')}{isEditing ? '*' : ''}</span>,
            accessor: 'installation_location',
            Cell: (row) => renderSelectCell(row, { id: 'installation_location' }),
            width: 125,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_lot')}</span>,
            accessor: 'lot',
            Cell: (row) => renderTextboxCell(row, { id: 'lot' }),
            style: { cursor: 'default' },
            width: 125,
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.planned_delivery_date')}</span>,
            accessor: 'planned_delivery_date',
            Cell: (row) => renderDateCell(row, { id: 'planned_delivery_date' }),
            width: 100,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_delivery_date')}</span>,
            accessor: 'delivery_date',
            Cell: (row) => renderDateCell(row, { id: 'delivery_date' }),
            width: 100,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_note')}</span>,
            accessor: 'note',
            Cell: (row) => renderTextboxCell(row, { id: 'note' }),
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_free_stock_status')}</span>,
            accessor: 'reserve_quantity',
            Cell: (row) => renderQuantityCell(row, { id: 'reserve_quantity' }),
            width: 90,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_ordered_quantity')}</span>,
            accessor: 'ordered_quantity',
            Cell: (row) => renderQuantityCell(row, { id: 'ordered_quantity' }),
            width: 90,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.table_column_delivered_quantity')}</span>,
            accessor: 'delivered_quantity',
            Cell: (row) => renderQuantityCell(row, { id: 'delivered_quantity' }),
            width: 90,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.button_save_changes')}</span>,
            accessor: 'addedOrEdited',
            Cell: (row) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {
                    row?.original?.isLoading ?
                      <ContentLoader onlySpinner />
                      :
                      (row?.original?.addedOrEdited === 'added' || row?.original?.addedOrEdited === 'edited') ?
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                          <Button type="add" style={{ width: 75, padding: '5px 8px' }} onClick={(e) => handleSave(e, row)}>{t('Save')}</Button>
                          {
                            row?.original?.addedOrEdited === 'edited' &&
                              <Button
                                type="icon"
                                style={{ width: 25, height: 'auto', padding: 0 }}
                                tooltip={t('page_content.procurement.details.items_table.button_discard_changes')}
                                tooltipPlacement="top"
                                onClick={(e) => handleDiscardChanges(e, row)}
                              >
                                <IconX height="18px" width="18px" stroke="#555" strokeWidth="5" />
                              </Button>
                          }
                        </div>
                        :
                        row?.original?.upload !== 'failed' ?
                          <img src={checkMarkTrue} alt="" width="24px" height="24px" />
                          :
                          <img src={checkMarkFalse} alt="" width="24px" height="24px" />
                  }
                </div>
            ),
            show: isEditing,
            width: 105,
            sortable: false,
            style: { cursor: 'default' },
          },
          {
            Header: () => <span>{t('page_content.procurement.details.items_table.button_duplicate_row')}</span>,
            Cell: (row) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={{ width: 55, padding: '3px 0' }}
                  onClick={(e) => handleDuplicateConfirmation(e, row?.original)}
                >
                  <img src={copyImage} alt="" style={{ height: '18px', width: '16px' }} />
                </Button>
              </div>
            ),
            show: isEditing,
            width: 85,
            sortable: false,
            style: { cursor: 'default' },
          },
          {
            Header: () => '',
            Cell: (row) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  style={{ width: 55, padding: '5px 8px' }}
                  onClick={(e) => handleDeleteConfirmation(e, row)}
                >
                  <IconRemove height="14px" width="14px" fill="#4285F4" />
                </Button>
              </div>
            ),
            width: 75,
            sortable: false,
            style: { cursor: 'default' },
          },
        ]}
        minRows={0}
        defaultPageSize={100}
        data={tableData?.data || []}
        loading={tableData?.isLoading}
        defaultSorted={[{ id: 'item_num', desc: false }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo) {
            return {
              style: { backgroundColor: rowInfo?.original?.errors ? 'rgba(239, 195, 195, 0.5)' : null },
            };
          }
        }}
      />
      <TableButtons
        next={tableData?.next}
        count={tableData?.count}
        previous={tableData?.previous}
        fetchFunction={fetchPaginatedProcurementItems}
      />
      <ConfirmationModal
        itemName={confirmationModal?.deleteData?.original?.name || ''}
        showModal={confirmationModal?.showDeleteDialog}
        handleCloseModal={() => setConfirmationModal((prevState) => ({ ...prevState, showDeleteDialog: false, deleteData: null }))}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
      <ConfirmationModal
        itemName={confirmationModal?.duplicateData?.name || ''}
        showModal={confirmationModal?.showDuplicateDialog}
        handleCloseModal={() => setConfirmationModal((prevState) => ({ ...prevState, showDuplicateDialog: false, duplicateData: null }))}
        handleConfirmModal={handleDuplicateRow}
        type="info"
        customTitle={t('page_content.procurement.details.items_table.confirmation_duplicate_item_message')}
      />
    </div>
  );
};

ItemsTable.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  // locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: get(state, 'currentUser', null),
});

export default connect(mapStateToProps, null)(withTranslation()(ItemsTable));
