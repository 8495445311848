import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { selectModalStyles, multiSelectStylesAutoHeight } from 'styles/modules/reactSelect';
import { getLocale } from 'shared/DatePicker/constants';
import { modalSizes } from 'shared/constants';
import { Modal } from 'shared';
import { getProductTypesList, getLinesList, getWorkersList, addNewComplaint, editComplaint } from '../actions';
import '../style.scss';

const ComplaintModal = ({ t, handleClose, modalValues, locationId, companyId, company_short_code, currentUser }) => {
  const [formValid, setFormValid] = useState(true);
  const [stateValues, setStateValues] = useState(modalValues?.initialData ?? {});
  const [isSelectedRowUpdated, setIsSelectedRowUpdated] = useState(false);
  const [supplementaryData, setSupplementaryData] = useState({
    workersList: [],
    productTypeList: [],
    linesList: [],
  });

  useEffect(() => {
    getProductTypesList(companyId)
      .then((res) => {
        setSupplementaryData((prevState) => ({ ...prevState, productTypeList: get(res, 'data.results', []) }));
      })
      .catch((e) => console.error('Error while fetching product types list', e));
    getLinesList(company_short_code, locationId)
      .then((res) => {
        setSupplementaryData((prevState) => ({ ...prevState, linesList: get(res, 'data.results', []) }));
      })
      .catch((e) => console.error('Error while fetching lines list', e));
  }, []);

  const handleChangeData = (value, key) => {
    const val = {
      ...stateValues,
      [key]: value,
    };
    setStateValues(val);
    setIsSelectedRowUpdated(true);
  };

  const handleInputChange = (value, key) => {
    if (value.length < 3) return;
    if (key === 'product_type') {
      getProductTypesList(companyId, `name_or_code=${value}`)
        .then((res) => {
          setSupplementaryData((prevState) => ({ ...prevState, productTypeList: get(res, 'data.results', []) }));
        })
        .catch((e) => console.error('Error while fetching complaints', e));
    }
    if (key === 'line') {
      getLinesList(company_short_code, locationId, value)
        .then((res) => {
          setSupplementaryData((prevState) => ({ ...prevState, linesList: get(res, 'data.results', []) }));
        })
        .catch((e) => console.error('Error while fetching lines list', e));
    }
    if (key === 'machine_master') {
      let filters = '';
      if (value.includes(' ')) {
        const name = value.split(' ')[0];
        const lastName = value.split(' ')[1];
        filters += `&first_name=${name}&last_name=${lastName}`;
      } else {
        filters += `&first_or_last_name=${value}`;
      }
      getWorkersList(locationId, companyId, filters)
        .then((res) => {
          setSupplementaryData((prevState) => ({ ...prevState, workersList: get(res, 'data.results', []) }));
        })
        .catch((e) => console.error('Error while fetching workers list', e));
    }
  };

  const handleSaveComplaint = async () => {
    if (!stateValues?.complaint_is_justified) {
      setStateValues((prevState) => ({ ...prevState, complaint_is_justified: false }));
    }
    const requiredKeyValues = [
      'date_received',
      'notification_number',
      'customer',
      'plant',
      'reason',
      'product_type',
      'product_series',
      'line',
      'cause',
    ];
    let isValid = true;
    requiredKeyValues.forEach((key) => {
      if (stateValues[key] === '' || stateValues[key] === null || stateValues[key] === undefined) {
        isValid = false;
      }
    });
    setFormValid(isValid);
    if (isValid) {
      const newData = {
        ...stateValues,
        location: locationId,
        company: companyId,
        user: currentUser?.id,
        line: stateValues?.line?.id,
        product_type: stateValues?.product_type?.id,
        product_series: stateValues?.product_series ? moment(stateValues?.product_series).format('YYYY-MM-DD') : null,
        workers: stateValues?.machine_master?.map((val) => val.id),
        date_received: moment(stateValues?.date_received).format('YYYY-MM-DD'),
      };

      delete newData.machine_master;

      if (stateValues?.date_resolved) {
        newData.date_resolved = moment(stateValues?.date_resolved).format('YYYY-MM-DD');
      }

      if (stateValues?.id) {
        await editComplaint(stateValues.id, newData, companyId);
        handleClose();
      } else {
        await addNewComplaint(newData);
        handleClose();
      }
    }
  };

  const requiredSign = <span style={{ margin: '0 5px' }}> *</span>;

  return (
    <Modal
      enableSaveOnEnter={false}
      title={stateValues?.id ?
        t('page_content.complaints.complaints_modal_edit_title') :
        t('page_content.complaints.complaints_modal_add_new_title')}
      size={modalSizes.large}
      isOpen={modalValues?.showModal}
      handleClose={handleClose}
      handleSave={handleSaveComplaint}
      disableSave={stateValues?.id && !isSelectedRowUpdated}
      error={!formValid ? t('page_content.complaints.form_not_valid') : ''}
    >
      <div className="custom_data_modal_container_complaints">
        <span style={{ fontSize: 12 }}>{requiredSign} {t('page_content.complaints.mandatory_fields_label')}</span>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.date_received')}:{requiredSign}</div>
          <div className="right_input">
            <DatePicker
              placeholderText={t('page_content.complaints.select_receipt_date_placeholder')}
              className="datepicker_input"
              dateFormat="dd.MM.yyyy"
              selected={stateValues?.date_received ? moment(stateValues?.date_received).toDate() : null}
              onChange={(date) => handleChangeData(date, 'date_received')}
              maxDate={new Date()}
              locale={getLocale(t)}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.plant')}:{requiredSign}</div>
          <div className="right_input">
            <input type="text" defaultValue={stateValues?.plant || ''} onChange={(e) => { handleChangeData(e.target.value, 'plant'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.notification_number')}:{requiredSign}</div>
          <div className="right_input">
            <input type="number" defaultValue={stateValues?.notification_number || ''} onChange={(e) => { handleChangeData(e.target.value, 'notification_number'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.customer')}:{requiredSign}</div>
          <div className="right_input">
            <input type="text" defaultValue={stateValues?.customer || ''} onChange={(e) => { handleChangeData(e.target.value, 'customer'); }} />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.reason')}:{requiredSign}</div>
          <div className="right_input">
            <textarea
              type="text"
              rows={5}
              defaultValue={stateValues?.reason || ''}
              onChange={(e) => { handleChangeData(e.target.value, 'reason'); }}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.complaint_description')}:</div>
          <div className="right_input">
            <textarea
              type="text"
              rows={5}
              defaultValue={stateValues?.description || ''}
              onChange={(e) => { handleChangeData(e.target.value, 'description'); }}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.product_name')}:{requiredSign}</div>
          <div className="right_select">
            <div style={{ width: '100%' }}>
              <Select
                options={supplementaryData?.productTypeList}
                getOptionLabel={(option) => `${option.name} (${option.code})`}
                getOptionValue={(option) => option.id}
                isSearchable
                isClearable
                menuPosition="fixed"
                placeholder={t('settings.custom_data.search_placeholder')}
                onChange={(val) => handleChangeData(val, 'product_type')}
                onInputChange={(val) => handleInputChange(val, 'product_type')}
                value={stateValues?.product_type ?? supplementaryData?.productTypeList.find((val) => val.id === stateValues?.product_type?.id)}
                styles={selectModalStyles}
              />
            </div>
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.product_series')}:{requiredSign}</div>
          <div className="right_input">
            <DatePicker
              placeholderText={t('page_content.complaints.select_completion_date_placeholder')}
              className="datepicker_input"
              dateFormat="dd.MM.yyyy"
              selected={stateValues?.product_series ? moment(stateValues?.product_series).toDate() : null}
              onChange={(date) => handleChangeData(date, 'product_series')}
              locale={getLocale(t)}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.line')}:{requiredSign}</div>
          <div className="right_select">
            <div style={{ width: '100%' }}>
              <Select
                options={supplementaryData?.linesList}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                isSearchable
                isClearable
                menuPosition="fixed"
                placeholder={t('settings.custom_data.search_placeholder')}
                onChange={(val) => handleChangeData(val, 'line')}
                onInputChange={(val) => handleInputChange(val, 'line')}
                value={stateValues?.line ?? supplementaryData?.linesList.find((val) => val.id === stateValues?.line?.id)}
                styles={selectModalStyles}
              />
            </div>
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.machine_master')}:</div>
          <div className="right_select">
            <div style={{ width: '100%' }}>
              <Select
                options={supplementaryData?.workersList}
                getOptionLabel={(option) => `${option?.name} ${option?.last_name} (${option?.external_id})`}
                getOptionValue={(option) => option.id}
                isSearchable
                isMulti
                isClearable
                menuPosition="fixed"
                placeholder={t('settings.custom_data.search_placeholder')}
                onChange={(val) => handleChangeData(val, 'machine_master')}
                onInputChange={(val) => handleInputChange(val, 'machine_master')}
                value={stateValues?.machine_master || stateValues?.workers || []}
                styles={multiSelectStylesAutoHeight}
              />
            </div>
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.justified_not_checked')}:</div>
          <div className="right_input">
            <input
              type="checkbox"
              checked={stateValues?.complaint_is_justified ?? false}
              onChange={(e) => handleChangeData(!!e.target.checked, 'complaint_is_justified')}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.cause')}:{requiredSign}</div>
          <div className="right_input">
            <textarea
              type="text"
              rows={5}
              defaultValue={stateValues?.cause || ''}
              onChange={(e) => { handleChangeData(e.target.value, 'cause'); }}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.activities')}:</div>
          <div className="right_input">
            <textarea
              type="text"
              rows={7}
              defaultValue={stateValues?.activities || ''}
              onChange={(e) => { handleChangeData(e.target.value, 'activities'); }}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.date_resolved')}:</div>
          <div className="right_input">
            <DatePicker
              placeholderText={t('page_content.complaints.select_completion_date_placeholder')}
              className="datepicker_input"
              dateFormat="dd.MM.yyyy"
              selected={stateValues?.date_resolved ? moment(stateValues?.date_resolved).toDate() : null}
              onChange={(date) => handleChangeData(date, 'date_resolved')}
              locale={getLocale(t)}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">{t('page_content.complaints.table.note')}:</div>
          <div className="right_input">
            <textarea
              type="text"
              rows={7}
              defaultValue={stateValues?.note || ''}
              onChange={(e) => { handleChangeData(e.target.value, 'note'); }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

ComplaintModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalValues: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  company_short_code: PropTypes.string,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    company_short_code: get(state, 'app.companyConfig.short_code', null),
    currentUser: get(state, 'currentUser', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(ComplaintModal));
