import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, Modal, TableButtons } from 'shared';
import { defaultDateFormat, modalSizes } from 'shared/constants';
import { getSalaries, getPaginatedSalaries } from '../actions';
import '../styles.scss';

const SalariesModal = ({ t, companyId, workerId, isOpen, handleCloseModal }) => {
  const [tableData, setTableData] = useState({
    isLoading: true,
    salaries: [],
    next: null,
    previous: null,
  });

  const [filters, setFilters] = useState({
    status: null,
    selectedSort: 'date',
    selectedAscDesc: 'desc',
  });

  const fetchSalaries = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let urlFilters = '';

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `order_by=${asc}${filters?.selectedSort}&company=${companyId}&worker=${workerId}&limit=20`;

    if (filters?.status) urlFilters += `&status=${filters?.status?.value}`;

    getSalaries(urlFilters)
      .then((re) => {
        setTableData({
          isLoading: false,
          salaries: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  useEffect(() => {
    fetchSalaries();
  }, [companyId, filters]);

  const fetchPaginatedData = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedSalaries(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          salaries: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;

    setFilters({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    });
  };

  const tableColumns = [
    {
      Header: t('page_content.administration.date'),
      accessor: 'date',
      Cell: (row) => <span>{row.value ? moment(row.value).format(defaultDateFormat) : '-'}</span>,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.net_salary'),
      accessor: 'net_salary',
      Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.gross_salary'),
      accessor: 'gross_salary',
      Cell: (row) => <span>{row.value ? row.value : '-'}</span>,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.percentage'),
      accessor: 'percent',
      Cell: (row) => <span style={{ color: parseFloat(row?.value) > 0 ? 'green' : 'red' }}>
        {row.value ? row.value : '-'}
      </span>,
      style: {
        cursor: 'default',
      },
    },
  ];

  return (
    <Modal
      title=""
      size={modalSizes.large}
      isOpen={isOpen}
      handleClose={handleCloseModal}
    >
      <div className="salaries-modal">
      <Table
        style={{ userSelect: 'text', marginTop: 20 }}
        columns={tableColumns}
        data={tableData?.salaries || []}
        minRows={0}
        defaultPageSize={30}
        noDataText=" "
        sortable
        showPagination={false}
        loading={tableData?.isLoading}
        defaultSorted={[{ id: 'date', desc: true }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <div>
        <span style={{ float: 'right' }}>
          <TableButtons
            previous={tableData?.previous}
            next={tableData?.next}
            fetchFunction={fetchPaginatedData}
            count={tableData?.count}
          />
        </span>
      </div>
      </div>
    </Modal>
  );
};

SalariesModal.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number,
  workerId: PropTypes.number,
  isOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};

export default (withRouter(withTranslation()(SalariesModal)));
