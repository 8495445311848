import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Table, Modal, TableButtons } from 'shared';
import { defaultDateFormat, modalSizes } from 'shared/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { styledWorkInjuryStatusOptions } from 'industry/helpers';
import { getWorkingInjuries, getPaginatedWorkingInjuries } from '../actions';
import '../styles.scss';

const WorkingInjuriesModal = ({ t, locationId, workerId, isOpen, handleCloseModal }) => {
  const [tableData, setTableData] = useState({
    isLoading: true,
    workingInjuries: [],
    next: null,
    previous: null,
  });

  const [filters, setFilters] = useState({
    status: null,
    selectedSort: 'date',
    selectedAscDesc: 'desc',
  });

  const fetchInjuries = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    let urlFilters = '';

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    urlFilters += `order_by=${asc}${filters?.selectedSort}&location=${locationId}&worker=${workerId}&limit=20`;

    if (filters?.status) urlFilters += `&status=${filters?.status?.value}`;

    getWorkingInjuries(urlFilters)
      .then((re) => {
        setTableData({
          isLoading: false,
          workingInjuries: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  useEffect(() => {
    fetchInjuries();
  }, [locationId, filters]);

  const fetchPaginatedData = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedWorkingInjuries(url)
      .then((re) => {
        setTableData({
          isLoading: false,
          workingInjuries: get(re, 'data.results', []),
          count: get(re, 'data.count'),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
        });
      });
  };

  const handleSorting = (sortData) => {
    const column = sortData.id;

    setFilters({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const statusesOptions = [
    { value: 'resolved', name: t('page_content.administration.resolved') },
    { value: 'denied', name: t('page_content.administration.denied') },
    { value: 'unresolved', name: t('page_content.administration.unresolved') },
    { value: 'in_progress', name: t('page_content.administration.in_progress') },
  ];

  const tableColumns = [
    {
      Header: t('page_content.administration.date'),
      accessor: 'date',
      width: 90,
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.event_location'),
      accessor: 'event_location',
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.injured_body_part'),
      accessor: 'injured_body_part',
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.injury_source'),
      accessor: 'injury_source',
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.injury_origin'),
      accessor: 'injury_origin',
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.undertaken_activity'),
      accessor: 'activity_undertaken',
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.planned_resolution_date'),
      accessor: 'planned_resolution_date',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.actual_resolution_date'),
      accessor: 'resolution_date',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.status'),
      accessor: 'status',
      Cell: (row) => <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span style={row?.value && statusesOptions?.find((val) => val.value === row?.value)?.name ? styledWorkInjuryStatusOptions(row?.value) : {}}>
          {row?.value ? statusesOptions?.find((val) => val.value === row?.value)?.name : '-'}</span>
      </div>,
      style: {
        cursor: 'default',
      },
    },
    {
      Header: t('page_content.administration.responsible_person'),
      accessor: 'responsible_person',
      Cell: (row) => (row && row.value ? `${row.value.name} ${row.value.last_name}` : '-'),
      style: {
        cursor: 'default',
      },
    },
  ];

  return (
    <Modal
      title=""
      size={modalSizes.full}
      isOpen={isOpen}
      handleClose={handleCloseModal}
    >
      <div className="working-injuries-modal">
      <Select
        className="select-style"
        options={statusesOptions}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.value}
        placeholder={t('page_content.administration.select_status')}
        isClearable
        onChange={(e) => handleFilterChange('status', e)}
        value={statusesOptions.find((a) => a.value === filters?.status?.value) || ''}
        styles={selectModalStyles}
      />

      <Table
        style={{ userSelect: 'text', marginTop: 20 }}
        columns={tableColumns}
        data={tableData?.workingInjuries || []}
        minRows={0}
        defaultPageSize={30}
        noDataText=" "
        sortable
        showPagination={false}
        loading={tableData?.isLoading}
        defaultSorted={[{ id: 'date', desc: true }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <div>
        <span style={{ float: 'right' }}>
          <TableButtons
            previous={tableData?.previous}
            next={tableData?.next}
            fetchFunction={fetchPaginatedData}
            count={tableData?.count}
          />
        </span>
      </div>
      </div>
    </Modal>
  );
};

WorkingInjuriesModal.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number,
  workerId: PropTypes.number,
  isOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};

export default (withRouter(withTranslation()(WorkingInjuriesModal)));
