import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { selectModalStyles, multiSelectFixedOptionsAutoHeight } from 'styles/modules/reactSelect';
import { Table, TableButtons, Button, DateFilters } from 'shared';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import '../styles.scss';

import { ordersCreatedAndUpdated } from 'shared/constants';
import { styledProcurementStatusOptions, styledProcurementTypeOptions, checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';

import { getProcurements, getPaginatedProcurements, getUsers, getProjects, getProcurementDestinations } from '../actions';

import OrderModal from './ProcurementModal';

const ProcurementsTable = ({ t, companyId, locationId, history, currentUser }) => {
  const [tableData, setTableData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });

  const [clearDateFilters, setClearDateFilters] = useState(false);
  const [filters, setFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'created_at',
    selectedCreatedBy: null,
    selectedApprovedBy: null,
    selectedStatus: null,
    selectedDestination: null,
    selectedProject: null,
    dateFilter: null,
  });

  const [users, setUsers] = useState({
    data: [],
    isLoading: false,
  });
  const [destinations, setDestinations] = useState({
    data: [],
    isLoading: false,
  });
  const [projects, setProjects] = useState({
    data: [],
    isLoading: false,
  });

  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchProcurements = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `company=${companyId}&limit=15&order_by=${asc}${filters?.selectedSort}`;

    if (filters?.selectedCreatedBy?.user_id) apiFilters += `&user=${filters?.selectedCreatedBy?.user_id}`;

    if (filters?.selectedApprovedBy?.user_id) apiFilters += `&approved_by=${filters?.selectedApprovedBy?.user_id}`;

    if (filters?.selectedDestination?.id) apiFilters += `&destination=${filters?.selectedDestination?.id}`;

    if (filters?.selectedProject?.id) apiFilters += `&project=${filters?.selectedProject?.id}`;

    if (filters?.dateFilter?.selectedFilter && filters?.dateFilter?.start && filters?.dateFilter?.end) {
      const startDate = moment(filters?.dateFilter?.start).format('YYYY-MM-DD');
      const endDate = moment(filters?.dateFilter?.end).format('YYYY-MM-DD');
      apiFilters += `&${filters?.dateFilter?.selectedFilter}_after=${startDate}&${filters?.dateFilter?.selectedFilter}_before=${endDate}`;
    }

    if (filters?.selectedStatus?.length === 1) {
      apiFilters += `&status=${filters?.selectedStatus?.[0]?.id}`;
    } else if (filters?.selectedStatus?.length > 1) {
      const statusIds = filters?.selectedStatus?.map((status) => status.id)?.join(',');
      apiFilters += `&status=${statusIds}`;
    }

    getProcurements(apiFilters)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedProcurements = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedProcurements(url)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchUsers = () => {
    setUsers((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const apiFilters = `company=${companyId}&limit=9999`;

    getUsers(apiFilters)
      .then((res) => {
        setUsers({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => {
        setUsers((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchProjects = () => {
    const apiFilters = `company=${companyId}&limit=999`;

    getProjects(apiFilters)
      .then((res) => {
        setProjects({
          data: res.data.results,
          isLoading: false,
        });
      })
      .catch(() => {
        setProjects({
          data: [],
          isLoading: false,
        });
      });
  };

  const fetchProcurementDestinations = () => {
    setDestinations((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const apiFilters = `company=${companyId}&limit=9999`;

    getProcurementDestinations(apiFilters)
      .then((res) => {
        setDestinations({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => {
        setDestinations((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    if (!currentUser?.is_admin) {
      checkModules(companyId)
        .then((re) => {
          const modules = re.data;
          const module = modules.find((m) => m.name === 'Procurement');
          if ((module && !module.is_active) || !module) redirectToHomePage(companyId, locationId);
        });

      checkAccessOnPage(companyId)
        .then((access) => { if (access === 0) redirectToHomePage(companyId, locationId); });
    }

    fetchUsers();
    fetchProcurementDestinations();
    fetchProjects();
  }, [companyId]);

  useEffect(() => {
    fetchProcurements();
  }, [filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    sortKey = sortKey.replace('.', '__');

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleSelectedDateFilter = (filter, start, end) => {
    setFilters((prevState) => ({
      ...prevState,
      dateFilter: {
        start,
        end,
        selectedFilter: filter,
      },
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const redirectToProcurementDetails = (id) => {
    if (id) history.push(`/${companyId}/industry/location/${locationId}/procurement/order/${id}`);
  };

  const handleAddNewProcurement = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchProcurements();
  };

  const clearAllFilters = () => {
    setClearDateFilters((prevState) => !prevState);
    setFilters((prevState) => ({
      ...prevState,
      selectedCreatedBy: null,
      selectedApprovedBy: null,
      selectedStatus: null,
      selectedDestination: null,
      selectedProject: null,
      dateFilter: null,
    }));
  };

  const procurementStatusOptions = [
    { id: 'created', name: t('page_content.procurement.status_created') },
    { id: 'approved', name: t('page_content.procurement.status_approved') },
    { id: 'denied', name: t('page_content.procurement.status_denied') },
    { id: 'ordered', name: t('page_content.procurement.status_ordered') },
    { id: 'delivered', name: t('page_content.procurement.status_delivered') },
    { id: 'finished', name: t('page_content.procurement.status_finished') },
    { id: 'cancelled', name: t('page_content.procurement.status_cancelled') },
  ];

  const procurementOrderTypeOptions = [
    { id: 'material', name: t('page_content.procurement.order_type_material') },
    { id: 'service', name: t('page_content.procurement.order_type_service') },
  ];

  return (
    <div className="procurement">
      <div className="procurement__filters">
        <div className="procurement__filters__dropdowns">
          <Select
            className="select-style"
            options={projects?.data || []}
            isLoading={projects?.isLoading}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.procurement.filter_project')}
            isClearable
            onChange={(e) => handleFilterChange('selectedProject', e || null)}
            value={filters?.selectedProject || null}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={users?.data || []}
            isLoading={users?.isLoading}
            getOptionLabel={(option) => `${option.first_name || '-'} ${option.last_name || '-'}`}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.procurement.filter_creator')}
            isClearable
            isSearchable
            onChange={(e) => handleFilterChange('selectedCreatedBy', e || null)}
            value={filters?.selectedCreatedBy || null}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={users?.data || []}
            isLoading={users?.isLoading}
            getOptionLabel={(option) => `${option.first_name || '-'} ${option.last_name || '-'}`}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.procurement.filter_approver')}
            isClearable
            isSearchable
            onChange={(e) => handleFilterChange('selectedApprovedBy', e || null)}
            value={filters?.selectedApprovedBy || null}
            styles={selectModalStyles}
          />
          <Select
            className="select-style"
            options={destinations?.data || []}
            isLoading={destinations?.isLoading}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.procurement.filter_destination')}
            isClearable
            isSearchable
            onChange={(e) => handleFilterChange('selectedDestination', e || null)}
            value={filters?.selectedDestination || null}
            styles={selectModalStyles}
          />
          <DateFilters
            selectedFilterProp={handleSelectedDateFilter}
            filterOptions={[
              { label: t('page_content.procurement.filter_created_time'), id: 'created_at', showTime: false },
              { label: t('page_content.procurement.filter_approved_time'), id: 'approved_time', showTime: false },
              { label: t('page_content.procurement.filter_pm_approved_time'), id: 'pm_approved_time', showTime: false },
            ]}
            clearFilters={clearDateFilters}
          />
          <Select
            options={procurementStatusOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={t('page_content.procurement.filter_status')}
            isClearable
            isMulti
            onChange={(e) => handleFilterChange('selectedStatus', e || null)}
            value={filters?.selectedStatus || null}
            styles={multiSelectFixedOptionsAutoHeight}
          />
          <Button type="plain" onClick={clearAllFilters}>{t('page_content.procurement.clear_all_filters')}</Button>
        </div>
        <div className="procurement__actions">
            <Button type="add" onClick={handleAddNewProcurement}>{t('page_content.procurement.add_new_procurement')}</Button>
        </div>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.procurement.table_column_number')}</span>,
            accessor: 'number',
            Cell: (row) => row?.value || '-',
            width: 55,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_project')}</span>,
            accessor: 'project',
            Cell: (row) => get(row, 'value.name', '-'),
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_order_type')}</span>,
            accessor: 'order_type',
            Cell: (row) => {
              const orderType = get(row, 'value', null);
              const option = procurementOrderTypeOptions?.find((op) => op.id === orderType);
              return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span style={option ? styledProcurementTypeOptions(option.id) : {}}>
                    {option ? option.name : '-'}
                  </span>
                </div>
              );
            },
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_item_count')}</span>,
            accessor: 'items_count',
            Cell: (row) => row?.value || '-',
            width: 100,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_description')}</span>,
            accessor: 'description',
            Cell: (row) => row?.value || '-',
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_creator')}</span>,
            accessor: 'user',
            Cell: (row) => `${get(row, 'value.first_name', '-')} ${get(row, 'value.last_name', '-')}`,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_created_date')}</span>,
            accessor: 'created_at',
            Cell: (row) => (get(row, 'value', null) ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
            width: 100,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_destination')}</span>,
            accessor: 'destination',
            Cell: (row) => get(row, 'value.name', '-'),
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_reorder')}</span>,
            accessor: 'is_reorder',
            Cell: (row) => (row?.value ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />),
            style: { alignItems: 'center' },
            width: 100,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_label')}</span>,
            accessor: 'external_id',
            Cell: (row) => row?.value || '-',
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_approver')}</span>,
            accessor: 'approved_by',
            Cell: (row) => `${get(row, 'value.first_name', '-')} ${get(row, 'value.last_name', '-')}`,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_approval_time')}</span>,
            accessor: 'approved_time',
            Cell: (row) => (get(row, 'value', null) ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
            width: 100,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_pm_approver')}</span>,
            accessor: 'pm_approved_by',
            Cell: (row) => `${get(row, 'value.first_name', '-')} ${get(row, 'value.last_name', '-')}`,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_pm_approval_time')}</span>,
            accessor: 'pm_approved_time',
            Cell: (row) => (get(row, 'value', null) ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
            width: 100,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_received')}</span>,
            Cell: (row) => {
              const receivedTime = get(row, 'original.received_time', null);
              const receivedBy = get(row, 'original.received_by', null);
              return receivedTime && receivedBy ? <img src={checkMarkTrue} width="25px" height="20px" alt="" /> : <img src={checkMarkFalse} width="25px" height="20px" alt="" />;
            },
            style: { alignItems: 'center' },
            width: 100,
          },
          {
            Header: () => <span>{t('page_content.procurement.table_column_status')}</span>,
            accessor: 'status',
            Cell: (row) => {
              const status = get(row, 'value', null);
              const option = procurementStatusOptions?.find((stat) => stat.id === status);
              return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <span style={option ? styledProcurementStatusOptions(option.id) : {}}>
                    {option ? option.name : '-'}
                  </span>
                </div>
              );
            },
          },
        ]}
        minRows={0}
        defaultPageSize={15}
        data={tableData?.data || []}
        loading={tableData?.isLoading}
        defaultSorted={[{ id: 'created_at', desc: true }]}
        enableEdit
        onEdit={(original) => handleTableRowClick(original)}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo) {
            const approvedBy = get(rowInfo, 'original.approved_by', null);
            const approvedTime = get(rowInfo, 'original.approved_time', null);
            const isApproved = approvedBy && approvedTime;

            return {
              style: { backgroundColor: !isApproved ? 'rgb(239, 195, 195)' : null },
              onClick: () => redirectToProcurementDetails(get(rowInfo, 'original.id', null)),
            };
          }
        }}
      />
      <TableButtons
        next={tableData?.next}
        count={tableData?.count}
        previous={tableData?.previous}
        fetchFunction={fetchPaginatedProcurements}
      />

      {modalData?.isOpen &&
        <OrderModal
          isOpen={modalData?.isOpen}
          handleCloseModal={handleCloseModal}
          initialValues={modalData?.selectedItem}
          closeModalAndRefetch={closeModalAndRefetch}
          procurementOrderTypeOptions={procurementOrderTypeOptions}
          destinations={destinations?.data}
          projects={projects?.data}
        />}
    </div>
  );
};

ProcurementsTable.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: get(state, 'currentUser', null),
});

export default connect(mapStateToProps, null)(withRouter(withTranslation()(ProcurementsTable)));
