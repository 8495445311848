import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Modal } from 'shared';
import { modalSizes, defaultDateFormat } from 'shared/constants';

import { getAbsence, getPaginatedAbsence } from '../actions';

function AbsencesModal({ t, isOpen, handleCloseModal, companyId, workerId }) {
  const [absenceTable, setAbsenceTable] = useState({
    data: [],
    isLoading: true,
    count: 0,
    next: null,
    previous: null,
  });
  const [sortingAndFiltering, setSortingAndFiltering] = useState({
    selectedSort: 'updated_at',
    selectedAscDesc: 'desc',
  });

  const fetchAbsences = async () => {
    setAbsenceTable((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = sortingAndFiltering?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `limit=15&order_by=${asc}${sortingAndFiltering?.selectedSort}`;

    apiFilters += `&company=${companyId}&worker=${workerId}&limit=9999&absence_type=sick-leave`;

    await getAbsence(apiFilters)
      .then((res) => {
        setAbsenceTable({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => setAbsenceTable((prevState) => ({ ...prevState, isLoading: false })));
  };

  const fetchPaginatedAbsences = async (url) => {
    setAbsenceTable((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    await getPaginatedAbsence(url)
      .then((res) => {
        setAbsenceTable({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => setAbsenceTable((prevState) => ({ ...prevState, isLoading: false })));
  };

  useEffect(() => {
    fetchAbsences();
  }, [sortingAndFiltering]);

  const handleSorting = (newSorted) => {
    setSortingAndFiltering((prevState) => ({
      ...prevState,
      selectedSort: newSorted.id,
      selectedAscDesc: newSorted.desc ? 'desc' : 'asc',
    }));
  };

  const tableColumnConfig = [
    {
      Header: () => <span>{t('page_content.administration.sick_leave.start_date')}</span>,
      accessor: 'start_date',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.administration.sick_leave.end_date')}</span>,
      accessor: 'end_date',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.administration.sick_leave.number_of_days')}</span>,
      accessor: 'number_of_days',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.administration.sick_leave.created_at')}</span>,
      accessor: 'created_at',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.administration.sick_leave.updated_at')}</span>,
      accessor: 'updated_at',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
  ];

  return (
    <Modal
      title={t('page_content.administration.sick_leave.sick_leave_title')}
      size={modalSizes.large}
      isOpen={isOpen}
      handleClose={handleCloseModal}
    >
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumnConfig}
          data={absenceTable?.data || []}
          defaultPageSize={15}
          loading={absenceTable.isLoading}
          minRows={0}
          noDataText={t('page_content.administration.sick_leave.no_data_found')}
          defaultSorted={[{ id: 'updated_at', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={absenceTable.previous}
          next={absenceTable.next}
          count={absenceTable.count}
          fetchFunction={fetchPaginatedAbsences}
        />
    </Modal>
  );
}

AbsencesModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func,
  workerId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default (withTranslation()(AbsencesModal));
