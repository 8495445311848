import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import DetailsCard from './components/DetailsCard';
import ItemsTable from './components/ItemsTable';
import AuditLog from './components/AuditLog';

import './styles.scss';

const IndustryLocationProcurementDetails = ({ t, history, companyId, locationId, match: { params: { orderId } } }) => {
  return (
        <div className="industry-location-procurement-details">
          <div className="breadcrumbs">
            <span onClick={() => history.push(`/${companyId}/industry/location/${locationId}/procurement/`)}>{t('sidebar.procurement')}</span>
            <p>{'>'}</p>
            <span>{orderId}</span>
          </div>

          <DetailsCard companyId={companyId} orderId={orderId} />
          <Tabs>
              <TabList>
                  <Tab>{t('page_content.procurement.details.tab_items_table')}</Tab>
                  <Tab>{t('page_content.procurement.details.tab_audit_log')}</Tab>
              </TabList>
              <TabPanel>
                  <ItemsTable companyId={companyId} locationId={locationId} orderId={orderId} />
              </TabPanel>
              <TabPanel>
                  <AuditLog companyId={companyId} locationId={locationId} />
              </TabPanel>
            </Tabs>
        </div>
  );
};

IndustryLocationProcurementDetails.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string,
    }),
  }).isRequired,
};

export default (withRouter(withTranslation()(IndustryLocationProcurementDetails)));
