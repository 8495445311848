import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import './styles.scss';

import { checkAccess, redirectToSettingsPage } from 'industry/helpers';

import { getLocations } from '../../actions';

import EventSubscribersTab from './components/EventSubscribersTab/index';
import SubscribersTab from './components/SubscribersTab/index';
import AuditLogs from './components/AuditLogs/index';

const EventsAndSubscribersTabs = ({ t, companyId, locationId, isUserProfile = false }) => {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [locationFilter, setLocationFilter] = useState({
    data: [],
    isLoading: true,
  });

  const fetchLocations = () => {
    setLocationFilter((prevState) => ({ ...prevState, isLoading: true }));

    getLocations(companyId)
      .then((res) => {
        setLocationFilter({
          data: get(res, 'data.items', []),
          isLoading: false,
        });
      })
      .catch(() => setLocationFilter((prevState) => ({ ...prevState, isLoading: false })));
  };

  useEffect(() => {
    if (!isUserProfile) {
      checkAccess(`/${companyId}/industry/location/${locationId}/settings/events-and-subscribers`, companyId)
        .then((acc) => {
          const access = get(acc, 'data.access', 0);
          if (access === 0) redirectToSettingsPage(companyId, locationId);
          else if (access === 1) setIsReadOnly(true);
          fetchLocations();
        });
    } else {
      fetchLocations();
    }
  }, [companyId, locationId]);

  return (
        <div className={`fullscreen ${isUserProfile ? 'events-and-subscribers-tab-container-user-profile' : 'events-and-subscribers-tab-container'} tabs`}>
          <Tabs>

            <TabList>
              <Tab>{t('settings.events_and_subscribers.tab_event_subscribers')}</Tab>
              <Tab>{t('settings.events_and_subscribers.tab_subscribers')}</Tab>
              <Tab>{t('settings.events_and_subscribers.audit_logs_tab')}</Tab>
            </TabList>

            <TabPanel>
                <EventSubscribersTab
                  companyId={companyId}
                  locationFilter={locationFilter}
                  isUserProfile={isUserProfile}
                  isReadOnly={isReadOnly}
                />
            </TabPanel>

            <TabPanel>
                <SubscribersTab
                  companyId={companyId}
                  locationFilter={locationFilter}
                  isUserProfile={isUserProfile}
                  isReadOnly={isReadOnly}
                />
            </TabPanel>

            <TabPanel>
                <AuditLogs
                  t={t}
                  companyId={companyId}
                  locationFilter={locationFilter}
                  isUserProfile={isUserProfile}
                  isReadOnly={isReadOnly}
                />
            </TabPanel>

          </Tabs>
        </div>
  );
};

EventsAndSubscribersTabs.propTypes = {
  t: PropTypes.func.isRequired,
  isUserProfile: PropTypes.bool,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default (withTranslation()(EventsAndSubscribersTabs));
