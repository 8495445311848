import api from 'helpers/api';
import { Notification } from 'shared';

export const getProcurements = async (filters = '') => {
  const url = `/api/v1/procurement/procurements/?${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching procurements', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedProcurements = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching procurements', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addProcurement = (companyId, data) => {
  return api.post(`/api/v1/procurement/procurements/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Procurement was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editProcurement = (companyId, id, data) => {
  return api.put(`/api/v1/procurement/procurements/${id}/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Procurement was successfully created.');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getUsers = async (filters = '') => {
  try {
    const res = await api.get(`/api/v1/company_members/?${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching users', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProcurementDestinations = async (filters = '') => {
  try {
    const res = await api.get(`/api/v1/procurement/destinations/?${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching procurement destinations', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProjects = async (filters = '') => {
  try {
    const res = await api.get(`/api/v1/projects/projects/?${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching projects', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProcurementItems = async (filters = '') => {
  const url = `/api/v1/procurement/items/?${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching procurement items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedProcurementItems = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching procurement items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getMaterialTypes = async (filters = '') => {
  const url = `/api/v1/procurement/material_types/?${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching material types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
