import React, { Component } from 'react';
import PropTypes from 'prop-types';
import api from 'helpers/api';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Tooltip from 'rc-tooltip';
import moment from 'moment';
import 'moment/min/locales';
import ReactDatePicker from 'react-datepicker';
import { getLocale } from 'shared/DatePicker/constants';
import Select from 'react-select';
import { ContentLoader, Button, Modal } from 'shared';
import { IconInfo } from 'shared/Icons';
import Timeline, { TimelineMarkers, CursorMarker, TimelineHeaders, DateHeader, CustomMarker } from 'react-calendar-timeline';
import { withRouter } from 'react-router-dom';
import { ordersCreatedAndUpdated } from 'shared/constants';
import { checkAccessOnPage, redirectToHomePage, checkModules, numberSeparatorFormat } from 'industry/helpers';
import { getCurrentUserSuccess } from 'user/actions';
import { saveSortingAndFiltering } from 'industry/actions';
import IconText from 'shared/Icons/IconText.png';
import { defaultHeaderFormats } from 'shared/helpers';
import { getGroups, getCompanyData, getEvents, editEvent, deleteEvent, getOrders, addEvent, getLineGroups, addChangeoverEvent, addProductTrialEvent, addCleaningEvent } from './actions';
import { comparisonGroupsTransformer, groupsTransformer, actualEventsTransformer, comparisonEventsTransformer, getStyleByStatus } from './helpers';
import './styles.scss';
import ItemModal from './components/ItemModal';
import InfoLabel from './components/InfoLabel';
import UploadModal from './components/UploadModal';
import Legend from './components/Legend';

let visibleStart = moment().subtract(1, 'weeks');
let visibleEnd = moment().add(1, 'weeks').endOf('week').isoWeekday(7);
class IndustryLocationMicroPlanning extends Component {
  constructor(props) {
    super(props);

    const sortingAndFiltering = props && props.currentUser && props.currentUser.data ? props.currentUser.data : {};
    let selectedLineGroup = null;

    if (sortingAndFiltering.microplanning) {
      const sAndF = sortingAndFiltering.microplanning;
      selectedLineGroup = sAndF.selectedLineGroup ? sAndF.selectedLineGroup : null;
    }

    this.state = {
      filter_microplanning_by_location: false,
      company_short_code: null,
      lineGroups: [],
      selectedLineGroup,
      isReadOnly: false,
      isLoading: true,
      selectedItem: null,
      groups: [],
      events: [],
      showOverlay: false,
      draggedItem: undefined,
      today: moment().valueOf(),
      disableZoom: false,
      isItemModalOpen: false,
      isUploadModalOpen: false,
      isEdit: false,
      query: '',
      orders: [],
      isLoadingOrders: false,
      statuses: [],
      views: [
        { id: 1, name: 'Actual' },
        { id: 2, name: 'Comparison' },
        { id: 3, name: 'BOM' },
      ],
      selectedView: 1,
      colorMap: {},
      shiftDaysBy: 'custom',
      microplanning_show_day_scroll: false,
      microplanning_show_week_scroll: false,
      isLoadingExportToExcel: false,
      microplanning_export_analysis: false,
      exportAnalysisModal: false,
      exportAnalysisStartTime: '',
      exportAnalysisEndTime: '',
      exportModalType: '',
      grouplineIds: '',
    };
  }

  componentDidMount() {
    const { selectedLineGroup } = this.state;
    const { companyId, locationId } = this.props;

    this.isMobileDevice();

    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Micro Planning');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    // getCompanyData(companyId);
    getCompanyData(companyId)
      .then((re) => {
        const company_short_code = re.data.short_code || null;
        const number_of_days_to_display = re && re.data && re.data.config && re.data.config.microplanning_days_to_display ? re.data.config.microplanning_days_to_display : 14;
        const microplanning_show_day_scroll = re && re.data && re.data.config && re.data.config.microplanning_show_day_scroll ? re.data.config.microplanning_show_day_scroll : false;
        const microplanning_show_week_scroll = re && re.data && re.data.config && re.data.config.microplanning_show_week_scroll ? re.data.config.microplanning_show_week_scroll : false;
        const statuses = re.data.config.statuses || [];
        const filter_microplanning_by_location = !!(re && re.data && re.data.config && re.data.config.filter_microplanning_by_location && re.data.config.filter_microplanning_by_location === true);
        const microplanning_export_analysis = re && re.data && re.data.config && re.data.config.microplanning_export_analysis ? re.data.config.microplanning_export_analysis : false;
        const number_of_weeks_to_display = 1;

        const exactNumberOfDays = number_of_days_to_display - 1; // Subtract 1 day to show exact days

        visibleStart = moment().subtract(Math.floor(exactNumberOfDays / 2), 'days').startOf('day');
        visibleEnd = moment().add(Math.ceil(exactNumberOfDays / 2), 'days').endOf('day');

        getLineGroups(company_short_code, locationId)
          .then((r) => {
            this.setState({
              lineGroups: r.data.results || [],
              company_short_code,
              filter_microplanning_by_location,
              microplanning_show_week_scroll,
              microplanning_show_day_scroll,
              microplanning_export_analysis,
            }, () => {
              // eslint-disable-next-line eqeqeq
              const line_group = r.data && r.data.results && selectedLineGroup ? r.data.results.some((e) => e.id == selectedLineGroup) ? selectedLineGroup : (r.data && r.data.results && r.data.results[0] && r.data.results[0].id ? r.data.results[0].id : null) : (r.data && r.data.results && r.data.results[0] && r.data.results[0].id ? r.data.results[0].id : null);
              let filters = '';
              if (line_group && r.data.results.length) {
                filters += `&group=${line_group}`;
              }
              getGroups(company_short_code, filter_microplanning_by_location ? locationId : '', filters)
                .then((res) => {
                  this.setState({
                    groups: res.data.results ? groupsTransformer(res.data.results) : [],
                    number_of_days_to_display,
                    number_of_weeks_to_display,
                    statuses,
                    selectedLineGroup: line_group,
                    grouplineIds: res.data.results ? res.data.results.map((line) => line.id).join(',') : [],
                  }, () => {
                    this.getAllLineEvents();
                  });
                });
            });
          });
      });
  }

  componentWillUnmount() {
    this.saveSortingAndFiltering();
  }

  onTimeChange(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) {
    const min = Math.max(visibleTimeStart, visibleStart.valueOf());
    const max = Math.min(visibleTimeEnd, visibleEnd.valueOf());

    updateScrollCanvas(min, max);
  }

  onChangeSelectedItem = (field, value) => {
    const { selectedItem } = this.state;

    if (field === 'start_time') {
      this.setState({
        selectedItem: {
          ...selectedItem,
          [field]: value,
          end_time: moment(value).add(1, 'hours').toDate(),
          formError: false,
        },
      });
    } else {
      this.setState({
        selectedItem: {
          ...selectedItem,
          [field]: value,
          formError: false,
        },
      });
    }
  }

  onCanvasDoubleClick = (groupId, time) => {
    this.setState(({
      isItemModalOpen: true,
      isEdit: false,
      selectedItem: { event_type: 'order', start_time: time, end_time: moment(time).add(1, 'hours').toDate(), group: groupId, status: 'scheduled' },
    }));
  }

  oneNextDay = () => {
    const number_of_days_to_display = 1;
    this.setState({ showOverlay: true });
    const exactNumberOfDays = number_of_days_to_display - 1; // Subtract 1 day to show exact days

    visibleStart = moment(visibleStart).add(exactNumberOfDays + 1, 'days').startOf('day');
    visibleEnd = moment(visibleEnd).add(exactNumberOfDays + 1, 'days').endOf('day');

    this.getAllLineEvents();
    this.timeline.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  }

  oneCurrentDay = () => {
    const number_of_days_to_display = 1;
    this.setState({ shiftDaysBy: 'day', showOverlay: true });
    const exactNumberOfDays = number_of_days_to_display - 1; // Subtract 1 day to show exact days

    visibleStart = moment().subtract(Math.floor(exactNumberOfDays / 2), 'days').startOf('day');
    visibleEnd = moment().add(Math.ceil(exactNumberOfDays / 2), 'days').endOf('day');

    this.getAllLineEvents();
    this.timeline.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  }

  onePreviousDay = () => {
    const number_of_days_to_display = 1;
    this.setState({ showOverlay: true });
    const exactNumberOfDays = number_of_days_to_display - 1; // Subtract 1 day to show exact days

    visibleStart = moment(visibleStart).subtract(exactNumberOfDays + 1, 'days').startOf('day');
    visibleEnd = moment(visibleEnd).subtract(exactNumberOfDays + 1, 'days').endOf('day');

    this.getAllLineEvents();
    this.timeline.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  }

  getObjectById = (id) => {
    const { views } = this.state;
    // eslint-disable-next-line eqeqeq
    return views.find((view) => view.id == id).name;
  };

  getAllLineEvents = (() => {
    let timeout;

    return () => {
      const { groups, filter_microplanning_by_location, selectedView } = this.state;
      const { i18n, locationId } = this.props;

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        const currentView = this.getObjectById(selectedView);

        const startTime = visibleStart ? `&planned_start_before=${moment(visibleEnd).endOf('day').toISOString()}` : '';
        const endTime = visibleEnd ? `&planned_end_after=${moment(visibleStart).startOf('day').toISOString()}` : '';

        let eventsFilters = `${startTime}${endTime}`;

        if (currentView === 'Actual') {
          eventsFilters += '&simple_order=1';
        } else if (currentView === 'BOM') {
          eventsFilters += '&view_type=bom_needs';
        }
        const promises = groups && groups.length ? groups.map((group) => getEvents(group.id, filter_microplanning_by_location ? locationId : '', eventsFilters)) : [];

        Promise.all(promises)
          .then((responses) => {
            let events = [];

            responses.forEach((response) => {
              events = events.concat(response.data.results || {});
            });

            const transformer = this.getTransformer(currentView);
            const isBOM = currentView === 'BOM';

            this.fillColorMap(currentView, events ? transformer(events, i18n.language, isBOM) : []);
          });
      }, 200);
    };
  })();

  getTransformer = (currentView) => {
    let transformer;

    switch (currentView) {
      case 'Actual':
        transformer = actualEventsTransformer;
        break;
      case 'Comparison':
        transformer = comparisonEventsTransformer;
        break;
      case 'BOM':
        transformer = actualEventsTransformer;
        break;
      default:
        transformer = actualEventsTransformer;
        break;
    }

    return transformer;
  }

  getRandomColor = () => {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    const brightness = this.calculateBrightness(randomColor);

    // Check the brightness level
    if (brightness < 128) {
      return { text: '#FFFFFF', background: `${randomColor}CC` };
    }
    return { text: '#000000', background: `${randomColor}CC` };
  }

  isMobileDevice() {
    const isMobile = window.innerWidth < 992;

    this.setState({ isReadOnly: isMobile });
  }

  calculateBrightness = (color) => {
    const hex = color.slice(1); // Remove the '#' from the color string
    const red = parseInt(hex.substr(0, 2), 16);
    const green = parseInt(hex.substr(2, 2), 16);
    const blue = parseInt(hex.substr(4, 2), 16);

    // Calculate the brightness using the formula
    return ((red * 299) + (green * 587) + (blue * 114)) / 1000;
  }

  fillColorMap = (currentView, events) => {
    const { colorMap } = this.state;

    if (events && events.length) {
      if (currentView === 'BOM') {
        events.forEach((item) => {
          const key = item.needs.code;

          if (!colorMap[key]) {
            colorMap[key] = this.getRandomColor();
          }
        });

        this.setState({
          events,
          colorMap,
          isLoading: false,
          showOverlay: false,
        });
      } else {
        this.setState({
          events,
          isLoading: false,
          showOverlay: false,
        });
      }
    } else {
      this.setState({
        events,
        isLoading: false,
        showOverlay: false,
      });
    }
  }

  saveSortingAndFiltering = () => {
    const { selectedLineGroup } = this.state;
    const { currentUser, setCurrentUser } = this.props;

    if (currentUser) {
      currentUser.data.microplanning = {
        selectedLineGroup,
      };
      setCurrentUser(currentUser);
      saveSortingAndFiltering({ data: currentUser.data });
    }
  }

  searchOrder = (e) => {
    const external_id = e.target.value || '';
    const { companyId, locationId } = this.props;
    const { filter_microplanning_by_location } = this.state;

    this.setState({
      isLoadingOrders: true,
    });

    if (external_id.length >= 3) {
      let filters = '';
      filters = `&external_id=${external_id}`;

      this.setState({
        query: external_id,
      });

      getOrders(companyId, filter_microplanning_by_location ? locationId : '', filters)
        .then((re) => {
          this.setState({
            orders: re.data.results || [],
            isLoadingOrders: false,
          });
        });
    } else {
      this.setState({
        query: external_id,
        isLoadingOrders: false,
      });
    }
  }

  handleItemProductCode = (eventType) => {
    switch (eventType) {
      case 'switchover':
        return 'changeover';
      case 'product_trial':
        return 'product_trial';
      case 'cleaning':
        return 'cleaning';
      default: return 'order';
    }
  }

  selectItem = (itemId) => {
    const { events } = this.state;
    const item = events.find((x) => x.id === itemId);
    const item_event_type = this.handleItemProductCode(item && item.order && item.order.product_code && item.order.product_code);

    const is_planned = !!(item && item.is_planned);
    if (!is_planned) {
      return false;
    }
    this.setState({
      selectedItem: {
        ...item,
        event_type: item_event_type,
      },
      isItemModalOpen: true,
      isEdit: true,
      query: '',
    });
  }

  closeModal = () => {
    this.setState({
      selectedItem: null,
      isItemModalOpen: false,
      isEdit: false,
      query: '',
    });
  }

  saveItem = () => {
    const { selectedItem, isEdit } = this.state;

    if (selectedItem.event_type === 'order' && !selectedItem.order) {
      this.setState({
        selectedItem: {
          ...selectedItem,
          formError: true,
        },
      });
      return;
    }

    if (isEdit) {
      const id = selectedItem && selectedItem.id ? selectedItem.id : null;
      const lineId = selectedItem && selectedItem.group ? this.removePlannedSuffix(selectedItem.group) : null;

      const event_type = selectedItem && selectedItem.event_type ? selectedItem.event_type : 'order';

      if (event_type) {
        let data = {};
        data = {
          ...selectedItem,
          line: lineId,
          planned_start: moment(selectedItem.start_time).format('YYYY-MM-DD[T]HH:mm:ss'),
          planned_end: moment(selectedItem.end_time).format('YYYY-MM-DD[T]HH:mm:ss'),
        };

        delete data.start_time;
        delete data.end_time;
        delete data.group;
        delete data.formError;
        delete data.event_type;

        if (event_type === 'order') {
          data.reason = null;
          data.order = selectedItem.order.id || null;
        }

        if (event_type === 'changeover' || event_type === 'product_trial' || event_type === 'cleaning') {
          data.order = selectedItem.order.id || null;
        }

        editEvent(id, lineId, data)
          .then(() => {
            this.getAllLineEvents();
            this.setState({
              selectedItem: {
                event_type: 'order',
                status: 'scheduled',
              },
              isItemModalOpen: false,
              isEdit: false,
              query: '',
            });
          });
      }
    } else {
      let data = {};
      const event_type = selectedItem && selectedItem.event_type ? selectedItem.event_type : 'order';

      if (event_type) {
        const lineId = selectedItem && selectedItem.group ? selectedItem.group : null;
        data = {
          ...selectedItem,
          line: lineId,
          planned_start: moment(selectedItem.start_time).format('YYYY-MM-DD[T]HH:mm:ss'),
          planned_end: moment(selectedItem.end_time).format('YYYY-MM-DD[T]HH:mm:ss'),
          manual_entry: true,
        };

        delete data.start_time;
        delete data.end_time;
        delete data.group;
        delete data.formError;
        delete data.event_type;

        if (event_type === 'order') {
          data.reason = null;
          data.order = selectedItem.order.id || null;

          addEvent(lineId, data)
            .then(() => {
              this.getAllLineEvents();
              this.setState({
                selectedItem: {
                  event_type: 'order',
                  status: 'scheduled',
                },
                isItemModalOpen: false,
                query: '',
              });
            });
        }

        if (event_type === 'changeover') {
          addChangeoverEvent(data)
            .then(() => {
              this.getAllLineEvents();
              this.setState({
                selectedItem: {
                  event_type: 'order',
                  status: 'scheduled',
                },
                isItemModalOpen: false,
                query: '',
              });
            });
        } else if (event_type === 'product_trial') {
          addProductTrialEvent(data)
            .then(() => {
              this.getAllLineEvents();
              this.setState({
                selectedItem: {
                  event_type: 'order',
                  status: 'scheduled',
                },
                isItemModalOpen: false,
                query: '',
              });
            });
        } else if (event_type === 'cleaning') {
          addCleaningEvent(data)
            .then(() => {
              this.getAllLineEvents();
              this.setState({
                selectedItem: {
                  event_type: 'order',
                  status: 'scheduled',
                },
                isItemModalOpen: false,
                query: '',
              });
            });
        }
      }
    }
  }

  removePlannedSuffix = (str) => {
    const stringifiedStr = String(str);
    if (stringifiedStr.includes('_planned')) {
      return stringifiedStr.replace('_planned', '');
    }
    return str;
  };

  removeItem = () => {
    const { selectedItem } = this.state;

    const id = selectedItem && selectedItem.id ? selectedItem.id : null;
    const lineId = selectedItem && selectedItem.group ? this.removePlannedSuffix(selectedItem.group) : null;

    if (id && lineId) {
      deleteEvent(id, lineId)
        .then(() => {
          this.getAllLineEvents();
          this.setState({
            selectedItem: {
              event_type: 'order',
              status: 'scheduled',
            },
            isItemModalOpen: false,
            isEdit: false,
            query: '',
          });
        });
    }
  }

  handleItemMove = (itemId, dragTime) => {
    const { events, isReadOnly } = this.state;

    if (isReadOnly) {
      return false;
    }

    const selectedItem = events.find((x) => x.id === itemId);

    const id = selectedItem && selectedItem.id ? selectedItem.id : null;
    const lineId = selectedItem && selectedItem.group ? this.removePlannedSuffix(selectedItem.group) : null;
    const is_planned = !!(selectedItem && selectedItem.is_planned);

    if (!is_planned) {
      return false;
    }

    if (id && lineId) {
      this.setState({ showOverlay: true, draggedItem: undefined });
      editEvent(
        id,
        lineId,
        {
          planned_start: moment(dragTime).format('YYYY-MM-DD[T]HH:mm:ss'),
          planned_end: moment(dragTime + (selectedItem.end_time - selectedItem.start_time)).format('YYYY-MM-DD[T]HH:mm:ss'),
        },
      )
        .then(() => {
          this.getAllLineEvents();
        });
    }
  }

  handleItemDrag = ({ itemId, time }) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    let item = this.state.draggedItem ? this.state.draggedItem.item : undefined;
    if (!item) {
      item = this.state.events.find((i) => i.id === itemId);
    }

    const is_planned = !!(item && item.is_planned);
    if (!is_planned) {
      return false;
    }

    this.setState({
      draggedItem: { item, time },
    });
  };

  itemRenderer = ({ item, itemContext, getItemProps }) => {
    const { colorMap } = this.state;
    const { i18n, t } = this.props;
    const quantity = item.quantity_percentage ? item.quantity_percentage : 0;
    let styles = {};
    const isManualEntry = item && item.manual_entry ? item.manual_entry : false;

    if (!itemContext.selected && !item.isBOM) {
      styles = getStyleByStatus(item.status, quantity, isManualEntry);

      if (item?.order && (item?.order?.product_code === 'switchover' || item?.order?.product_code === 'product_trial' || item?.order?.product_code === 'cleaning')) {
        styles = getStyleByStatus(item?.order?.product_code, quantity, '', item.status);
      }
    }

    if (item.isBOM) {
      styles = {
        background: colorMap && colorMap[item.needs.code] && colorMap[item.needs.code].background ? colorMap[item.needs.code].background : '#eee',
        border: isManualEntry ? '4px dashed rgb(236 86 203)' : '2px solid #717d98',
        color: colorMap && colorMap[item.needs.code] && colorMap[item.needs.code].text ? colorMap[item.needs.code].text : 'black',
        overflow: 'hidden',
      };
    }

    return (
      <Tooltip
        id={(item.id).toString()}
        trigger={['hover']}
        placement="top"
        overlay={
          !itemContext.dragging ?
            <div className="reacttooltip">
              {
                item.isBOM &&
                <React.Fragment>
                  <span><label>{t('page_content.microplanning.tooltip.bom_code')}: </label>{item.needs && item.needs.code ? item.needs.code : ''}</span><br />
                  <span><label>{t('page_content.microplanning.tooltip.bom_name')}: </label>{item.needs && item.needs.name ? item.needs.name : ''}</span><br />
                </React.Fragment>
              }
              <span><label>{t('page_content.microplanning.tooltip.order_number')}: </label>{itemContext.title ? itemContext.title : ''}</span><br />
              <span><label>{t('page_content.microplanning.tooltip.product')}: </label>{item.order && item.order.customer_name ? item.order.customer_name : ''}</span><br />
              <span><label>{t('page_content.microplanning.tooltip.status')}: </label>{item.status ? item.status : ''}</span><br />
              <span><label>{t('page_content.microplanning.tooltip.quantity')}: </label>{item.order_items_done && item.order_items_total ? `${item.order_items_done}/${item.order_items_total}=${quantity}%` : ''}</span><br />
              <span><label>{t('page_content.microplanning.tooltip.planned_start')}: </label>{item.plannedStart ? moment(item.plannedStart).format(ordersCreatedAndUpdated) : ''}</span><br />
              <span><label>{t('page_content.microplanning.tooltip.planned_end')}: </label>{item.plannedEnd ? moment(item.plannedEnd).format(ordersCreatedAndUpdated) : ''}</span><br />
              <span><label>{t('page_content.microplanning.tooltip.actual_start')}: </label>{item.actualStart ? moment(item.actualStart).format(ordersCreatedAndUpdated) : ''}</span><br />
              <span><label>{t('page_content.microplanning.tooltip.actual_end')}: </label>{item.actualEnd ? moment(item.actualEnd).format(ordersCreatedAndUpdated) : ''}</span><br />
            </div> : ''
        }
      >
        <div
          {...getItemProps({
            style: {
              ...styles,
              zIndex: '999 !important',
            },
          })}
        >

          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: 'hidden',
              paddingLeft: 3,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              position: 'relative',
            }}
          >
            {
              item.note && item.note.length ?
                <img
                  src={IconText}
                  className="note_icon"
                  alt=""
                /> : ''
            }
            {
              item.isBOM ?
                `${item.needs && item.needs.code ? item.needs.code : '-'} | ${item.needs && item.needs.quantity ? item.needs.quantity : '-'}/${item.needs && item.needs.quantity_done ? item.needs.quantity_done : '-'}` :
                `${itemContext.title} (${item.order && item.order.customer_name ? item.order.customer_name : ''}) |
              ${item.order && item.order.items_done && item.order.items_total ?
                  `${numberSeparatorFormat(i18n.language, item.order.items_done, 0, 0)}/${numberSeparatorFormat(i18n.language, item.order.items_total, 0, 0)}=${quantity}%`
                  : ''}`
            }

          </div>
        </div>
      </Tooltip>
    );
  };

  nextDays = () => {
    const { number_of_days_to_display } = this.state;
    this.setState({ showOverlay: true });
    const exactNumberOfDays = number_of_days_to_display - 1; // Subtract 1 day to show exact days

    visibleStart = moment(visibleStart).add(exactNumberOfDays + 1, 'days').startOf('day');
    visibleEnd = moment(visibleEnd).add(exactNumberOfDays + 1, 'days').endOf('day');

    this.getAllLineEvents();
    this.timeline.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  }

  currentDay = () => {
    this.setState({ shiftDaysBy: 'custom', showOverlay: true });
    const { number_of_days_to_display } = this.state;
    const exactNumberOfDays = number_of_days_to_display - 1; // Subtract 1 day to show exact days

    visibleStart = moment().subtract(Math.floor(exactNumberOfDays / 2), 'days').startOf('day');
    visibleEnd = moment().add(Math.ceil(exactNumberOfDays / 2), 'days').endOf('day');

    this.getAllLineEvents();
    this.timeline.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  }

  previousDays = () => {
    const { number_of_days_to_display } = this.state;
    this.setState({ showOverlay: true });
    const exactNumberOfDays = number_of_days_to_display - 1; // Subtract 1 day to show exact days

    visibleStart = moment(visibleStart).subtract(exactNumberOfDays + 1, 'days').startOf('day');
    visibleEnd = moment(visibleEnd).subtract(exactNumberOfDays + 1, 'days').endOf('day');

    this.getAllLineEvents();
    this.timeline.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  }

  handleZoom = (timelineContext) => {
    const timeline = document.getElementsByClassName('react-calendar-timeline');
    if (timelineContext) {
      if (this.state.disableZoom === false) {
        if ((visibleStart.valueOf() !== timelineContext.visibleTimeStart) && (visibleEnd.valueOf() === timelineContext.visibleTimeEnd)) {
          timeline[0].style.pointerEvents = 'none';
        }
        if ((visibleEnd.valueOf() !== timelineContext.visibleTimeEnd) && (visibleStart.valueOf() === timelineContext.visibleTimeStart)) {
          timeline[0].style.pointerEvents = 'none';
        }
      }
    }
  }

  nextWeek = () => {
    const { number_of_weeks_to_display } = this.state;
    this.setState({ showOverlay: true });
    visibleStart = moment(visibleStart).add(number_of_weeks_to_display, 'weeks');
    visibleEnd = moment(visibleEnd).add(number_of_weeks_to_display, 'weeks');
    this.getAllLineEvents();

    this.timeline.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  }

  currentWeek = () => {
    this.setState({
      shiftDaysBy: 'week',
    });
    const { number_of_weeks_to_display } = this.state;
    this.setState({ showOverlay: true });
    const number_of_days_in_week = 7;
    visibleStart = moment().startOf('isoWeek').subtract((number_of_weeks_to_display - 1) * number_of_days_in_week, 'days').startOf('day');
    visibleEnd = moment().startOf('isoWeek').add((number_of_days_in_week * number_of_weeks_to_display) - 1, 'days').endOf('day');
    this.getAllLineEvents();

    this.timeline.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  }

  previousWeek = () => {
    const { number_of_weeks_to_display } = this.state;
    this.setState({ showOverlay: true });
    visibleStart = moment(visibleStart).subtract(number_of_weeks_to_display, 'weeks');
    visibleEnd = moment(visibleEnd).subtract(number_of_weeks_to_display, 'weeks');
    this.getAllLineEvents();

    this.timeline.updateScrollCanvas(visibleStart.valueOf(), visibleEnd.valueOf());
  }

  handleZoomEvent = () => {
    this.setState({ disableZoom: true });
    const timeline = document.getElementsByClassName('react-calendar-timeline');
    timeline[0].style.pointerEvents = 'auto';
  }

  labelFormater = (
    // eslint-disable-next-line no-unused-vars
    [timeStart, timeEnd],
    unit,
    labelWidth,
    formatOptions = defaultHeaderFormats,
  ) => {
    const { i18n } = this.props;
    moment.locale(i18n.language);
    let format;
    if (labelWidth >= 150) {
      format = formatOptions[unit].long;
    } else if (labelWidth >= 100) {
      format = formatOptions[unit].mediumLong;
    } else if (labelWidth >= 50) {
      format = formatOptions[unit].medium;
    } else {
      format = formatOptions[unit].short;
    }

    return timeStart.format(format);
  }

  changeLineGroup = (lineGroupId) => {
    const { company_short_code, lineGroups, filter_microplanning_by_location } = this.state;
    const { locationId } = this.props;

    this.setState({
      events: [],
    });
    const line_group = lineGroupId || null;

    let filters = '';
    if (line_group && lineGroups.length) {
      filters += `&group=${line_group}`;
    }

    getGroups(company_short_code, filter_microplanning_by_location ? locationId : '', filters)
      .then((res) => {
        this.setState({
          groups: res.data.results ? groupsTransformer(res.data.results) : [],
          selectedLineGroup: line_group || null,
        }, () => {
          this.getAllLineEvents();
          this.saveSortingAndFiltering();
        });
      });
  }

  changeView = (viewId) => {
    const view = viewId || null;

    this.setState({
      selectedView: view,
      isLoading: true,
      showOverlay: true,
      events: [],
    }, () => {
      this.getAllLineEvents();
      this.saveSortingAndFiltering();
    });
  }

  redirectToOrder = (order) => {
    let currentLocation = window.location.href.replace('micro-planning', 'orders/');

    currentLocation += order.id;

    return currentLocation;
  }

  openUploadModal = () => {
    this.setState({
      isUploadModalOpen: true,
    });
  }

  closeUploadModal = (reload = false) => {
    if (reload === true) {
      this.setState({
        isUploadModalOpen: false,
        isLoading: true,
        showOverlay: true,
      }, () => {
        this.getAllLineEvents();
      });
    } else {
      this.setState({
        isUploadModalOpen: false,
      });
    }
  }

  exportExcel = () => {
    const { i18n, locationId } = this.props;
    const { company_short_code, exportAnalysisStartTime, exportAnalysisEndTime } = this.state;

    this.setState({ isLoadingExportToExcel: true });
    api.get(`/api/v1/planning/plan_export/?company=${company_short_code}&location=${locationId}&date_from=${moment(exportAnalysisStartTime || visibleStart).format('YYYY-MM-DD')}&date_to=${moment(exportAnalysisEndTime || visibleEnd).format('YYYY-MM-DD')}&format=xlsx&lang=${i18n.language || 'hr'}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'microplanning_export.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({ isLoadingExportToExcel: false });
      })
      .catch(() => {
        this.setState({ isLoadingExportToExcel: false });
      });
  }

  openExportAnalysisModal = () => {
    this.setState({
      exportAnalysisModal: true,
    });
  }

  closeExportAnalysisModal = () => {
    this.setState({
      exportAnalysisModal: false,
      exportModalType: '',
      exportAnalysisStartTime: '',
      exportAnalysisEndTime: '',
    });
  }

  handleExportAnalysisModalSave = (modalType) => {
    if (modalType === 'exportAnalysis') {
      this.exportExcel();
    } else if (modalType === 'bomNeeds') { this.exportExcelBomNeeds(); }
    this.setState({
      exportAnalysisModal: false,
      exportModalType: '',
      exportAnalysisStartTime: '',
      exportAnalysisEndTime: '',
    });
  }

  handleExportDateModalChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  exportExcelBomNeeds = () => {
    const { locationId } = this.props;
    const { exportAnalysisStartTime, exportAnalysisEndTime, grouplineIds } = this.state;
    this.setState({ isLoadingExportToExcel: true });
    api.get(`/api/v1/planning/excel_needs/?lines=${grouplineIds}&location=${locationId}&planned_start__before=${moment(exportAnalysisEndTime || visibleEnd).utc().toISOString()}&planned_end__after=${moment(exportAnalysisStartTime || visibleStart).utc().toISOString()}`, { responseType: 'blob' })
      .then((myBlob) => {
        const href = URL.createObjectURL(myBlob.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'microplanning_export.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.setState({ isLoadingExportToExcel: false });
      })
      .catch(() => {
        this.setState({ isLoadingExportToExcel: false });
      });
  }

  render() {
    const { t, companyId, locationId } = this.props;

    const {
      isLoading,
      events,
      groups,
      selectedItem,
      isReadOnly,
      showOverlay,
      draggedItem,
      today,
      number_of_days_to_display,
      isItemModalOpen,
      isEdit,
      orders,
      query,
      isLoadingOrders,
      statuses,
      lineGroups,
      selectedLineGroup,
      selectedView,
      views,
      isUploadModalOpen,
      shiftDaysBy,
      microplanning_show_week_scroll,
      microplanning_show_day_scroll,
      isLoadingExportToExcel,
      microplanning_export_analysis,
      exportAnalysisModal,
      exportModalType,
      exportAnalysisStartTime,
      exportAnalysisEndTime,
    } = this.state;
    const zoomTooltipContent = (<h4>{t('page_content.microplanning.zoom_tooltip')}</h4>);

    const selectStyles = {
      control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: '200px',
        minHeight: '34px',
        height: '36px',
        padding: 0,
        fontSize: '13px',
        color: '#555',
        marginRight: '5px',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '34px',
        padding: '0 10px',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        paddingRight: 10,
        color: 'black',
        svg: {
          width: '15px',
          height: '15px',
        },
      }),
      menu: (provided) => ({
        ...provided,
        width: 200,
        borderRadius: 0,
        zIndex: 999999,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: 500,
        padding: '6px 12px',
      }),
    };

    if (isLoading) {
      return (
        <div className="loaderWrapper">
          <ContentLoader />
        </div>
      );
    }

    const currentView = this.getObjectById(selectedView);
    const groupsTimeline = currentView === 'Comparison' ? comparisonGroupsTransformer(groups) : groups;

    return (
      <div className="industry-tab industry-microplanning fullscreen">
        <main>
          <div className="microplanning-action-buttons">
            <Button type="dark-blue" style={{ marginLeft: '10px' }} onClick={this.openUploadModal} disabled={isReadOnly}>
              {t('page_content.microplanning.plan_import')}
            </Button>
            {
              microplanning_export_analysis &&
              <Button
                loading={isLoadingExportToExcel}
                type="export"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  this.setState({
                    exportModalType: 'exportAnalysis',
                  }, () => {
                    this.openExportAnalysisModal();
                  });
                }}
              >
                {t('page_content.microplanning.export_analysis')}
              </Button>
            }
          </div>
          <Legend />
          <div className="microplanning-tools">
            <div className="microplanning-filter">
              {lineGroups && lineGroups.length ?
                <div className="filter_selector_container">
                  {t('page_content.microplanning.group')}
                  <Select
                    options={lineGroups}
                    getOptionLabel={(group) => group.name}
                    getOptionValue={(group) => group.id}
                    isSearchable
                    placeholder="Select Line Group"
                    onChange={(value) => this.changeLineGroup(value.id)}
                    value={(lineGroups.find((lGroup) => lGroup.id === selectedLineGroup)) || ''}
                    styles={selectStyles}
                  />
                </div> : ''}
              <div className="filter_selector_container">
                {t('page_content.microplanning.view')}
                <Select
                  options={views}
                  getOptionLabel={(view) => t([`page_content.microplanning.${view.name}`])}
                  getOptionValue={(view) => view.id}
                  isSearchable
                  placeholder="Select View"
                  onChange={(value) => this.changeView(value.id)}
                  value={(views.find((v) => v.id === selectedView)) || ''}
                  styles={selectStyles}
                />
                {views.find((v) => v.id === selectedView).name === 'BOM' &&
                  <Button
                    loading={isLoadingExportToExcel}
                    type="export"
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      this.setState({
                        exportModalType: 'bomNeeds',
                      }, () => {
                        this.openExportAnalysisModal();
                      });
                    }}
                  >
                    {t('page_content.microplanning.export_bom_needs_button')}
                  </Button>}
              </div>

            </div>
            <div className="microplanning-zoom">
              <span className="tooltip">
                <Tooltip
                  id="tooltip-zoom"
                  trigger={['hover']}
                  placement="left"
                  overlay={zoomTooltipContent}
                  overlayClassName="where-filter-tooltip"
                >
                  <span
                    aria-describedby="tooltip-zoom"
                  >
                    <IconInfo
                      color="#2e86de"
                      height="18px"
                      width="20px"
                    />
                  </span>
                </Tooltip>
              </span>
              <span className="microplanning-zoom-buttons">
                <Button onClick={() => this.timeline.changeZoom(0.50)}>{t('page_content.microplanning.zoom_in_button')}</Button>
                <Button onClick={() => this.timeline.changeZoom(1.50)}>{t('page_content.microplanning.zoom_out_button')}</Button>
              </span>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
                  microplanning_show_day_scroll &&
                  <span className="microplanning-zoom-week-buttons">
                    <Button disabled={shiftDaysBy !== 'day'} onClick={() => this.onePreviousDay()}>{t('page_content.microplanning.previous_day')}</Button>
                    <Button onClick={() => this.oneCurrentDay()}>{t('page_content.microplanning.current_day')}</Button>
                    <Button disabled={shiftDaysBy !== 'day'} onClick={() => this.oneNextDay()}>{t('page_content.microplanning.next_day')}</Button>
                  </span>
                }
                <span className="microplanning-zoom-week-buttons">
                  <Button disabled={shiftDaysBy !== 'custom'} onClick={() => this.previousDays()}>{t('page_content.microplanning.previous_days', { num: `${number_of_days_to_display}` })}</Button>
                  <Button onClick={() => this.currentDay()}>{t('page_content.microplanning.current_days', { num: `${number_of_days_to_display}` })}</Button>
                  <Button disabled={shiftDaysBy !== 'custom'} onClick={() => this.nextDays()}>{t('page_content.microplanning.next_days', { num: `${number_of_days_to_display}` })}</Button>
                </span>
                {
                  microplanning_show_week_scroll &&
                  <span className="microplanning-zoom-week-buttons">
                    <Button disabled={shiftDaysBy !== 'week'} onClick={() => this.previousWeek()}>{t('page_content.microplanning.previous_week')}</Button>
                    <Button onClick={() => this.currentWeek()}>{t('page_content.microplanning.current_week')}</Button>
                    <Button disabled={shiftDaysBy !== 'week'} onClick={() => this.nextWeek()}>{t('page_content.microplanning.next_week')}</Button>
                  </span>
                }

              </div>
            </div>
          </div>
          <div
            className="timeline-wrapper"
            onKeyUp={this.handleZoomEvent}
            onKeyDown={this.handleZoomEvent}
            onMouseUp={this.handleZoomEvent}
            onMouseDown={() => this.setState({ disableZoom: false })}
          >
            {showOverlay && <div id="overlay"><ContentLoader /></div>}
            <Timeline
              ref={(r) => { this.timeline = r; }}
              groups={groupsTimeline || []}
              horizontalLineClassNamesForGroup={(group) => (group.title.includes(t('page_content.microplanning.actual')) ? ['actual'] : '')}
              items={events || []}
              defaultTimeStart={visibleStart}
              defaultTimeEnd={visibleEnd}
              onTimeChange={this.onTimeChange}
              itemRenderer={this.itemRenderer}
              stackItems
              fullUpdate={false}
              lineHeight={65}
              itemHeightRatio={0.95}
              canResize={false}
              onItemMove={this.handleItemMove}
              onItemDrag={this.handleItemDrag}
              onItemDoubleClick={(itemId) => { this.selectItem(itemId); }}
              onZoom={this.handleZoom}
              onCanvasDoubleClick={this.onCanvasDoubleClick}
            >
              <TimelineMarkers>
                <CustomMarker date={today}>
                  {({ styles }) => <div style={{ ...styles, backgroundColor: 'red', width: '2px' }} />}
                </CustomMarker>
                <CursorMarker>
                  {({ styles }) => <div style={{ ...styles, backgroundColor: 'gray' }} />}
                </CursorMarker>
              </TimelineMarkers>
              <TimelineHeaders className="sticky">
                <DateHeader unit="primaryHeader" />
                <DateHeader labelFormat={this.labelFormater} />
              </TimelineHeaders>
            </Timeline>
            {draggedItem && <InfoLabel time={draggedItem.time} />}
          </div>

          {!events && !groups && <p className="microplanning-nodata">{t('page_content.microplanning.no_data_placeholder')}</p>}

          {isItemModalOpen &&
            <ItemModal
              selectedItem={selectedItem}
              onChange={this.onChangeSelectedItem}
              saveItem={this.saveItem}
              removeItem={this.removeItem}
              closeModal={this.closeModal}
              isEdit={isEdit}
              orders={orders}
              searchOrder={this.searchOrder}
              query={query}
              isLoadingOrders={isLoadingOrders}
              groups={groups}
              statuses={statuses}
              redirectToOrder={this.redirectToOrder}
              removePlannedSuffix={this.removePlannedSuffix}
              isReadOnly={isReadOnly}
            />}
          {isUploadModalOpen && !isReadOnly &&
            <UploadModal
              uploadFile={this.uploadFile}
              closeUploadModal={this.closeUploadModal}
              companyId={companyId}
              locationId={locationId}
              t={t}
            />}
          {exportAnalysisModal &&
            <Modal
              isOpen={exportAnalysisModal}
              handleClose={this.closeExportAnalysisModal}
              title={exportModalType === 'exportAnalysis' ? t('page_content.microplanning.export_analysis') : t('page_content.microplanning.export_bom_needs_button')}
              handleSave={() => this.handleExportAnalysisModalSave(exportModalType)}
              saveText={t('page_content.microplanning.export')}
            >
              <div className="microplaning_export_modal_container">
                <div className="modal_row">
                  <div className="left_text">{t('page_content.microplanning.export_modal_start_date')}</div>
                  <div className="right_datePicker">
                    <ReactDatePicker
                      dateFormat="dd.MM.yyyy"
                      selected={exportAnalysisStartTime || moment(visibleStart).toDate()}
                      maxDate={exportAnalysisEndTime || moment(visibleEnd).toDate()}
                      selectsStart
                      onChange={(date) => this.handleExportDateModalChange('exportAnalysisStartTime', moment(date).toDate())}
                      showTimeSelect={false}
                      disabledKeyboardNavigation
                      locale={getLocale(t)}
                    />
                  </div>
                </div>
                <div className="modal_row">
                  <div className="left_text">{t('page_content.microplanning.export_modal_end_date')}</div>
                  <div className="right_datePicker">
                    <ReactDatePicker
                      dateFormat="dd.MM.yyyy"
                      selected={exportAnalysisEndTime || moment(visibleEnd).toDate()}
                      minDate={exportAnalysisStartTime || moment(visibleStart).toDate()}
                      selectsStart
                      onChange={(date) => this.handleExportDateModalChange('exportAnalysisEndTime', moment(date).toDate())}
                      showTimeSelect={false}
                      disabledKeyboardNavigation
                      locale={getLocale(t)}
                    />
                  </div>
                </div>
              </div>
            </Modal>}
        </main>
      </div>
    );
  }
}

IndustryLocationMicroPlanning.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  };
};

const mapDispatchToProps = {
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(IndustryLocationMicroPlanning)));
