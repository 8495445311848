import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { checkAccessOnPage, redirectToHomePage, checkModules } from 'industry/helpers';
import { getProject } from './actions';
import ProjectDetailsTab from './components/ProjectDetailsTab';
import ProjectPartsTab from './components/ProjectPartsTab';
import ProjectOrdersTab from './components/ProjectOrdersTab/index';
import ProductBomTab from './components/ProductBomTab';
import Products from './components/Products';

class IndustryProjectDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      isReadOnly: false,
      project: {},
    };
  }

  componentDidMount() {
    const {
      companyId,
      locationId,
      match: { params: { projectId } },
    } = this.props;
    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Projects');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    getProject(projectId, companyId, locationId)
      .then((res) => {
        this.setState({
          project: get(res, 'data'),
        });
      });
  }

  render() {
    const {
      companyId,
      locationId,
      t,
      history,
      match: { params: { projectId } },
    } = this.props;

    const {
      isReadOnly,
      project,
    } = this.state;

    return (
      <div className="industry-tab project-details fullscreen" style={{ boxSizing: 'border-box' }}>
        <div className="project_details_breadcrumbs"><span
          onClick={() => {
            history.push(`/${companyId}/industry/location/${locationId}/projects/`);
          }}
        >{t('page_content.projects.breadcrumb')}</span><p>{'>'}</p><span>{project.name}</span></div>
        <main>
          <Tabs>
            <TabList>
              <Tab>{t('page_content.projects.details_tab.details')}</Tab>
              <Tab>{t('page_content.projects.details_tab.uniques')}</Tab>
              <Tab>{t('page_content.projects.details_tab.orders')}</Tab>
              <Tab>{t('page_content.projects.details_tab.BOM')}</Tab>
              <Tab>{t('page_content.projects.details_tab.products')}</Tab>
            </TabList>

            <TabPanel>
              <ProjectDetailsTab project={project} t={t} />
            </TabPanel>

            <TabPanel>
              <ProjectPartsTab
                companyId={companyId}
                projectId={projectId}
                locationId={locationId}
                isReadOnly={isReadOnly}
              />
            </TabPanel>

            <TabPanel>
              <ProjectOrdersTab
                projectId={projectId}
                companyId={companyId}
                locationId={locationId}
              />
            </TabPanel>

            <TabPanel>
              <ProductBomTab
                projectId={projectId}
                companyId={companyId}
                locationId={locationId}
              />
            </TabPanel>

            <TabPanel>
              <Products
                isReadOnly={isReadOnly}
                projectId={projectId}
                companyId={companyId}
                locationId={locationId}
              />
            </TabPanel>
          </Tabs>
        </main>
      </div>
    );
  }
}

IndustryProjectDetails.propTypes = {
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

export default (withRouter(withTranslation()(IndustryProjectDetails)));
