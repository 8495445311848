/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';

import { getPaginatedBudgets, getBudgets, deleteBudget } from '../actions';
import '../styles.scss';
import CompanyBudgetModal from './CompanyBudgetModal';

const CompanyBudgetTable = ({ t, companyId }) => {
  const [tableData, setTableData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'year',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchCompanyBudget = () => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&company=${companyId}&department_isnull=true&profession_isnull=true&limit=15&order_by=${asc}${filters?.selectedSort}`;

    getBudgets(apiFilters)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedCompanyBudget = (url) => {
    setTableData((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedBudgets(url)
      .then((res) => {
        setTableData({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setTableData((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchCompanyBudget();
  }, [companyId, filters]);

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewCompanyBudget = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchCompanyBudget();
  };

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteBudget(deleteData?.id, companyId);
    setShowConfirmationDialog(false);
    fetchCompanyBudget();
  };

  return (
    <React.Fragment>
      <div className="company-budget">
        <h3>{t('page_content.administration.control_panel_tab.company_budget_heading')}</h3>

        <div className="company-budget__action-buttons">
        <Button
          type="add"
          onClick={handleAddNewCompanyBudget}
        >
          {t('page_content.administration.control_panel_tab.add_new_company_budget_button')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.budget_column')}</span>,
              accessor: 'budget',
            },
            {
              Header: () => <span>{t('page_content.administration.control_panel_tab.year_column')}</span>,
              accessor: 'year',
            },
          ]}
          data={tableData?.data || []}
          defaultPageSize={100}
          loading={tableData.isLoading}
          minRows={0}
          noDataText=""
          enableEdit
          enableDelete
          onEdit={(row) => handleTableRowClick(row)}
          onDelete={(original) => handleShowConfirmationDialog(original)}
          defaultSorted={[{ id: 'year', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <TableButtons
          previous={tableData.previous}
          next={tableData.next}
          fetchFunction={fetchPaginatedCompanyBudget}
          count={tableData.count}
        />
      </div>

      {modalData?.isOpen &&
      <CompanyBudgetModal
        t={t}
        isOpen={modalData?.isOpen}
        initialValues={modalData?.selectedItem}
        handleCloseModal={handleCloseModal}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'year')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </React.Fragment>
  );
};

CompanyBudgetTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default withTranslation()(CompanyBudgetTable);
