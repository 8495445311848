import api from 'helpers/api';
import { Notification } from 'shared';

// eslint-disable-next-line import/prefer-default-export
export const getComplaints = async (companyId, locationId, urlFilters) => {
  try {
    const res = await api
      .get(`/api/v1/complaints/complaints/?company=${companyId}&location=${locationId}${urlFilters}&limit=40`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching complaints', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedComplaints = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching workers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getWorkersList = async (locationId, companyId, filters = '') => {
  const urlString = '/api/v1/workforce/workers/';
  try {
    const res = await api.get(`${urlString}?company=${companyId}&location=${locationId}${filters}&order_by=-name&limit=100`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching workers', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLinesList = async (company_short_code, location, nameContains = '') => {
  try {
    const res = await api
      .get(`/api/v1/planning/lines/?company=${company_short_code}&location=${location}&name_contains=${nameContains}&order_by=-name&is_hidden=false&limit=100`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching groups', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductTypesList = async (companyId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/product_types/?company=${companyId}&limit=100&order_by=name&${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addNewComplaint = async (data) => {
  return api.post('/api/v1/complaints/complaints/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'New Rastep was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editComplaint = async (id, data, companyId) => {
  return api.patch(`/api/v1/complaints/complaints/${id}/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } else return Notification('success', 'Complaint edited successful', 'Item was successfully edited.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const archiveComplaint = async (id, companyId) => {
  return api.delete(`/api/v1/complaints/complaints/${id}/?company=${companyId}`)
    .then(() => {
      return Notification('success', 'Archive successful', 'Item was successfully archived.');
    })
    .catch(() => {
      return Notification('error', 'An error occurred');
    });
};
