import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get, set } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Button, ContentLoader, Notification } from 'shared';
import { IconWarning, IconRemove } from 'shared/Icons';
import { defaultDateFormat } from 'shared/constants';
import { colors } from 'shared/colors';

import { getLocale } from 'shared/DatePicker/constants';
import {
  getQuestions,
  finishSurvey,
  getWorkerById,
  getWorkerSurveys,
  getQuestionOptions,
  deleteWorkProcessRating,
  saveWorkProcessRatingAnswer,
  getWorkProcessesRatingAnswers,
} from '../../../actions';
import '../../../styles.scss';

const WorkProcessesRating = ({ t, currentUser, companyId, selectedWorker, workProcessRatingCategoryCode, userHaveFullAccess = false }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [errorNote, setErrorNote] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [workProcesses, setWorkProcesses] = useState({
    data: [],
    isLoading: true,
  });

  const [valueOptions, setValueOptions] = useState({
    data: [],
    isLoading: true,
  });

  const [previousWorkProcesses, setPreviousWorkProcesses] = useState({
    isLoading: true,
    data: [],
    selectedItem: null,
  });

  const fetchWorkProcesses = () => {
    setWorkProcesses((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const filters = `&category_code=${workProcessRatingCategoryCode}&order_by=-workersurveyfile__created_at&is_finished=false`;

    getWorkerSurveys(selectedWorker.id, filters)
      .then((res) => {
        let survey = get(res, 'data.results', []);
        if (survey.length > 0) {
          survey = survey[0];
          if (survey.survey?.id && survey.survey?.is_published) {
            setWorkProcesses((prevState) => ({
              ...prevState,
              survey,
            }));
            return getQuestions(survey.survey.id);
          }
          setErrorNote(t('page_content.human_resources.rating.no_work_processes'));
          throw new Error('');
        } else {
          setErrorNote(t('page_content.human_resources.rating.no_work_processes'));
          throw new Error('');
        }
      })
      .then((res2) => {
        const questions = get(res2, 'data.results', []);
        setWorkProcesses((prevState) => ({
          ...prevState,
          data: questions,
        }));

        if (questions.length > 0) {
          setValueOptions((prevState) => ({
            ...prevState,
            isLoading: true,
          }));
          // eslint-disable-next-line no-return-await
          const answerPromises = questions.map(async (option) => await getQuestionOptions(option.id).then((response) => get(response, 'data.results', [])));
          return Promise.all(answerPromises)
            .then((returnedOptions) => {
              const optionsObject = returnedOptions.reduce((acc, curr) => {
                if (curr.length > 0) {
                  const questionId = curr[0].question;
                  acc[questionId] = curr;
                }
                return acc;
              }, {});
              Object.entries(optionsObject).forEach(([key1, value1]) => {
                if (value1 && Array.isArray(value1)) {
                  value1?.sort((a, b) => (b?.value || b?.order || 0) - (a?.value || a?.order || 0));
                  optionsObject[key1] = value1;
                }
              });
              return optionsObject;
            });
        }
        setWorkProcesses((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        return [];
      })
      .then((options) => {
        setValueOptions((prevState) => ({
          ...prevState,
          data: options,
          isLoading: false,
        }));
        setWorkProcesses((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      })
      .catch(() => {
        setWorkProcesses((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        setValueOptions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPreviousWorkProcesses = () => {
    setPreviousWorkProcesses((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const filters = `&category_code=${workProcessRatingCategoryCode}&order_by=-finish_time&is_finished=true`;

    getWorkerSurveys(selectedWorker.id, filters)
      .then((res) => {
        setPreviousWorkProcesses({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => {
        setPreviousWorkProcesses((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const onFormChange = (name, value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const onCheckboxChange = (name, option) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: (prevFormValues[name] === option ? null : option),
    }));
  };

  const onWorkerEvaluationChange = async (val) => {
    setPreviousWorkProcesses((prevState) => ({
      ...prevState,
      selectedItem: val,
    }));
    setFormValues({});

    if (val) {
        setIsLoading(true);
        const answers = get(await getWorkProcessesRatingAnswers(val.id), 'data.results', []);
        const questions = answers
            ?.map((answer) => answer.question)
            ?.filter((question, index, self) => index === self.findIndex((q) => q.id === question.id));

        setWorkProcesses((prevState) => ({
            ...prevState,
            data: questions,
        }));

        const answerObject = answers?.reduce((acc, curr) => {
            if (curr) {
                const answerId = curr.question.id;
                if (curr.question.multi_select) {
                    acc[answerId] = acc[answerId] || [];
                    acc[answerId].push(curr);
                } else {
                    acc[answerId] = curr;
                }
            }
            return acc;
        }, {});
        
        Object.entries(answerObject).forEach(([key, value]) => {
            if (value && Array.isArray(value)) {
                value?.sort((a, b) => (b?.answer || 0) - (a?.answer || 0));
                answerObject[key] = value;
            }
        });

        const questionsOptionsPromises = questions.map(
            async (option) => (option?.question_type === 'select' || option?.question_type === 'choice') &&
            // eslint-disable-next-line no-return-await
            await getQuestionOptions(option.id).then((response) => get(response, 'data.results', [])),
        );

        const returnedOptions = await Promise.all(questionsOptionsPromises);
        const optionsObject = returnedOptions.reduce((acc, curr) => {
            const questionId = curr[0] && curr[0].question;
            acc[questionId] = curr;
            return acc;
        }, {});

        Object.entries(optionsObject).forEach(([key1, value1]) => {
            if (value1 && Array.isArray(value1)) {
            value1?.sort((a, b) => (b?.value || b?.order || 0) - (a?.value || a?.order || 0));
            optionsObject[key1] = value1;
            }
        });

        setValueOptions((prevState) => ({
            ...prevState,
            data: optionsObject,
        }));

        const filteredAnswers = {};
        Object.entries(answerObject).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                filteredAnswers[key] = [];
                value.forEach((value1) => {
                    const answer = value1.answer;
                    const matchingOptions = optionsObject[key]?.find((option) => option.value === answer);
                    if (matchingOptions) {
                        filteredAnswers[key].push(matchingOptions);
                    }
            });
            } else if (value?.question?.question_type === 'free_text') filteredAnswers[key] = value?.text_answer;
            else if (value?.question?.question_type === 'date') filteredAnswers[key] = new Date(value?.date_answer);
            else if (value?.question?.question_type === 'number') filteredAnswers[key] = value?.answer;
            else {
                const answer = value.answer;
                const matchingOptions = optionsObject[key]?.find((option) => option.value === answer);
                if (matchingOptions) {
                    filteredAnswers[key] = matchingOptions;
                }
            }
        });
        setFormValues(filteredAnswers);

        await getWorkerById(companyId, val.user)
            .then((worker) => {
              worker = get(worker, 'data.results', null);
              worker = worker?.length > 0 && worker[0];
              setPreviousWorkProcesses((prevState) => ({
                ...prevState,
                selectedItem: {
                  ...prevState.selectedItem,
                  user: worker,
                },
              }));
            });

        setIsLoading(false);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);

    // eslint-disable-next-line no-return-await
    const answerPromises = workProcesses?.data?.map(async (question) => await saveWorkProcessRatingAnswer(
      {
        question: question?.id,
        worker_survey: workProcesses?.survey?.id,
        answer:
          question?.question_type === 'free_text' || question?.question_type === 'number' ? formValues[question?.id] :
            question?.question_type === 'date' ? moment(formValues[question?.id]).format('YYYY-MM-DD') :
              question?.question_type === 'choice' ? formValues[question?.id]?.value :
                question?.question_type === 'select' && !question.multi_select ? formValues[question?.id]?.value :
                  Array.isArray(formValues[question?.id]) ? formValues[question?.id].map((val) => val.value).join(',') :
                    formValues[question?.id],
      },
    )
      .then((response) => response));
    await Promise.all(answerPromises)
      .then(() => finishSurvey({ worker_survey: workProcesses?.survey?.id })
        .then(() => {
          setFormValues({});
          setWorkProcesses({});
          setValueOptions({});
          fetchWorkProcesses();
          fetchPreviousWorkProcesses();
          setIsLoading(false);
        })
        .catch((error) => {
          Notification(
            'error',
            'An error occurred',
            (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.',
          );
          setIsLoading(false);
        }));
  };

  useEffect(() => {
    fetchWorkProcesses();
    fetchPreviousWorkProcesses();
  }, []);

  useEffect(() => {
    const allSelectsFilled = workProcesses?.data?.length > 0 && workProcesses?.data.every((value) => {
      if (value.is_required === false) return true;
      const option = formValues[value.id];
      return option !== undefined && option !== null && option !== '';
    });
    setIsSaveDisabled(allSelectsFilled);
  }, [workProcesses, formValues]);

  if (isLoading || workProcesses?.isLoading || valueOptions?.isLoading) {
    return <ContentLoader />;
  }

  if (!currentUser?.worker_id) {
    return (
      <span className="ratings__screen__warning">
        <IconWarning color={colors.red} height="18px" width="18px" />
        <p>{t('page_content.human_resources.rating.no_rating')}</p>
      </span>);
  }

  if (!workProcessRatingCategoryCode) {
    return (
      <span className="ratings__screen__warning">
        <IconWarning color={colors.red} height="18px" width="18px" />
        <p>{t('page_content.human_resources.rating.no_category_code')}</p>
      </span>);
  }

  const handleDeleteWorkProcess = (id) => {
    deleteWorkProcessRating(id)
      .then(() => {
        fetchPreviousWorkProcesses();
        setFormValues({});
        setWorkProcesses({});
        setValueOptions({});
        fetchWorkProcesses();
      })
  };

  return (
    <div className="ratings__screen">
      <div style={{ width: '500px' }}>
        <Select
          options={previousWorkProcesses?.data}
          getOptionLabel={(option) => <span>{t('page_content.human_resources.rating.evaluation')} - {moment(option?.finish_time).format(defaultDateFormat)} - {option?.survey?.name}</span>}
          getOptionValue={(option) => option}
          isClearable
          placeholder={t('page_content.human_resources.rating.view_previous_work_processes')}
          menuPosition="fixed"
          onChange={(e) => onWorkerEvaluationChange(e || null)}
          value={(previousWorkProcesses?.data?.find((sOption) => (sOption?.id === previousWorkProcesses?.selectedItem?.id))) || ''}
          styles={selectModalStyles}
        />
      </div>

      {
        previousWorkProcesses?.selectedItem?.user &&
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontWeight: '600', margin: 0 }}>
            {`${t('page_content.human_resources.rating.evaluator')}: ${previousWorkProcesses?.selectedItem?.user?.name} ${previousWorkProcesses?.selectedItem?.user?.last_name}`}
          </p>
          {
            userHaveFullAccess &&
            <Button
              type="delete"
              style={{ marginLeft: 'auto' }}
              onClick={() => handleDeleteWorkProcess(previousWorkProcesses?.selectedItem?.id)}
            >
              <IconRemove width="14px" height="14px" />
            </Button>
          }
        </div>
      }

      <div className="custom_inputs_view_work_processes">
        {
          workProcesses?.data?.length > 0 ? workProcesses?.data?.map((value) => (
            <div key={value.id} className="modal_row">
              <div className="left_text">
                <label>{value?.question} {value?.is_required && '*'}</label>
              </div>

              {
                value?.question_type === 'select' &&
                <div className="right_select">
                  <Select
                    options={valueOptions?.data[value.id]}
                    getOptionLabel={(option) => (userHaveFullAccess
                      ? `${option.label} - ${option.value} ${t('page_content.human_resources.rating.points')}`
                      : `${option.label}`)}
                    getOptionValue={(option) => option.id}
                    isSearchable
                    isMulti={value?.multi_select}
                    placeholder={t('page_content.human_resources.rating.select_question_type_placeholder')}
                    onChange={(opt) => onFormChange(value?.id, opt)}
                    value={formValues[value.id] || null}
                    styles={selectModalStyles}
                    isDisabled={previousWorkProcesses?.selectedItem}
                  />
                </div>
              }
              {
                value?.question_type === 'choice' &&
                <div className="multiple_right_choice">
                  {valueOptions?.data[value.id]?.map((question) => (
                    <div key={question.id} className="multiple_right_choice__input">
                      <div className="right_radio">
                        <input
                          type="radio"
                          style={{ scale: '1.35' }}
                          onChange={() => onCheckboxChange(value?.id, question)}
                          disabled={previousWorkProcesses?.selectedItem}
                          checked={formValues[value.id]?.id === question?.id}
                        />
                      </div>
                      <span>{question?.label}</span>
                      {
                        userHaveFullAccess &&
                        <span style={{ fontStyle: 'italic' }}> - {question?.value} {t('page_content.human_resources.rating.points')}</span>
                      }
                    </div>
                  ))}
                </div>
              }
              {
                (value?.question_type === 'free_text' || value?.question_type === 'number') &&
                <div className={value?.number_limit_to !== 0 ? 'right_input__below' : 'right_input'}>
                  <input
                    value={formValues[value.id] || ''}
                    max={value?.number_limit_to || ''}
                    min={value?.number_limit_from || ''}
                    disabled={previousWorkProcesses?.selectedItem}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (value?.question_type === 'number') {
                        const regex = /^\d*$/;
                        if (regex.test(inputValue)) {
                          const min = value?.number_limit_from || -Infinity;
                          const max = value?.number_limit_to || Infinity;
                          const numericValue = parseFloat(inputValue);
                          if (inputValue === '' ||
                            (!Number.isNaN(numericValue) && numericValue >= min && numericValue <= max)) onFormChange(value?.id, inputValue);
                        }
                      } else onFormChange(value?.id, inputValue);
                    }}
                    type="text"
                    placeholder={value?.question_type === 'number' ?
                      t('page_content.human_resources.rating.number_question_type_placeholder') :
                      t('page_content.human_resources.rating.text_question_type_placeholder')}
                  />
                  {
                    value?.question_type === 'number' && value?.number_limit_to !== 0 &&
                    <div className="input_feedback">
                      <p>{t('page_content.human_resources.rating.number_question_type_range_placeholder')} {value?.number_limit_from}-{value?.number_limit_to}</p>
                    </div>
                  }
                </div>
              }
              {
                value?.question_type === 'date' &&
                <DatePicker
                  className="datepicker_input"
                  placeholderText={t('page_content.human_resources.rating.date_question_type_placeholder')}
                  dateFormat="dd.MM.yyyy"
                  selected={formValues[value.id] || null}
                  disabled={previousWorkProcesses?.selectedItem}
                  onChange={(date) => onFormChange(value?.id, date)}
                  locale={getLocale(t)}
                />
              }
            </div>))
            :
            <span className="ratings__screen__warning">
              <IconWarning color={colors.red} height="18px" width="18px" />
              <p>{errorNote}</p>
            </span>
        }
      </div>
      {
        !previousWorkProcesses?.selectedItem && workProcesses?.data?.length > 0 &&
        <div className="save_button">
          <Button
            disabled={!isSaveDisabled}
            type="success"
            onClick={handleSave}
          >{t('page_content.human_resources.rating.save')}</Button>
        </div>
      }
    </div>
  );
};

WorkProcessesRating.propTypes = {
  t: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  selectedWorker: PropTypes.object.isRequired,
  workProcessRatingCategoryCode: PropTypes.string.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
    workProcessRatingCategoryCode: get(state, 'app.location.config.work_process_rating_category_code', ''),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(WorkProcessesRating));
