import api from 'helpers/api';
import { get } from 'lodash';
import { Notification } from 'shared';

export const getProcurement = async (id, filters = '') => {
  const url = `/api/v1/procurement/procurements/${id}/?${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching a procurement', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const editProcurement = async (companyId, id, data) => {
  return api.patch(`/api/v1/procurement/procurements/${id}/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Procurement item was successfully updated.');
      return res;
    })
    .catch((error) => {
      Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      return get(error, 'response.data', null);
    });
};

export const getProcurementItems = async (filters = '') => {
  const url = `/api/v1/procurement/items/?${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching procurement items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProcurementItem = async (companyId, itemId) => {
  const url = `/api/v1/procurement/items/${itemId}/?company=${companyId}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching procurement items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedProcurementItems = async (url) => {
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching procurement items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addProcurementItem = (companyId, data) => {
  return api.post(`/api/v1/procurement/items/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Procurement item was successfully created.');
      return res;
    })
    .catch((error) => {
      Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      return get(error, 'response.data', null);
    });
};

export const editProcurementItem = (companyId, itemId, data) => {
  return api.patch(`/api/v1/procurement/items/${itemId}/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Procurement item was successfully created.');
      return res;
    })
    .catch((error) => {
      Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      return get(error, 'response.data', null);
    });
};

export const deleteProcurementItem = async (companyId, itemId) => {
  const urlString = `/api/v1/procurement/items/${itemId}/?company=${companyId}`;
  try {
    const res = await api.delete(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res) {
      throw new Error('');
    } Notification('success', 'Delete successful', 'Procurement item was successfully deleted.');
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while deleting absence', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getMaterialTypes = async (filters = '') => {
  const url = `/api/v1/procurement/material_types/?${filters}`;
  try {
    const res = await api.get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching material types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
