import api from 'helpers/api';
import { Notification } from 'shared';

export const getProject = async (projectId, companyId, locationId) => {
  try {
    const res = await api.get(`/api/v1/projects/projects/${projectId}/?company=${companyId}&location=${locationId}&expand=true`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching project', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProjectParts = async (locationId, companyId, projectId, filterByPartName, filterByProductName, lotFilter, facadeFilter, orderGroupFilters) => {
  const urlString = '/api/v1/projects/parts/';
  let urlParams = '?limit=30';
  if (projectId) {
    urlParams += `&project=${projectId}`;
  }
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  if (filterByPartName) {
    urlParams += `&name_contains=${filterByPartName}`;
  }
  if (filterByProductName) {
    urlParams += `&product_type_name_contains=${filterByProductName}`;
  }
  if (lotFilter && lotFilter.length > 0) {
    urlParams += `&lot_in=${lotFilter.map((item) => item.value).join(',')}`;
  }
  if (facadeFilter || orderGroupFilters) {
    const isFacadeFilterValid = facadeFilter && facadeFilter.length > 0;
    const isOrderGroupFiltersValid = orderGroupFilters && orderGroupFilters.length > 0;
    if (isFacadeFilterValid || isOrderGroupFiltersValid) {
      const combinedArray = [];
      if (isFacadeFilterValid) {
        combinedArray.push(...facadeFilter.map((item) => item.id.toString()));
      }
      if (isOrderGroupFiltersValid) {
        combinedArray.push(...orderGroupFilters.map((item) => item.id.toString()));
      }
      urlParams += `&part_category_in=${combinedArray.join(',')}`;
    }
  }

  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching project parts.', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedProjectParts = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching project parts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getBomItems = async (projectId, companyId, locationId, filterByPartNumber, orderBy) => {
  const urlString = '/api/v1/product_boms/';
  let urlParams = `?limit=30&expand_children=${true}&parent_bom__isnull=${!(filterByPartNumber && filterByPartNumber.length)}`;
  if (projectId) {
    urlParams += `&project=${projectId}`;
  }
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  if (filterByPartNumber) {
    urlParams += `&external_id=${filterByPartNumber}`;
  }
  if (orderBy) {
    urlParams += `&order_by=${orderBy}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching BOM items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedBomItems = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching BOM items', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrders = async (url, filters = '') => {
  try {
    const res = await api
      .get(`${url}&include_lines=true${filters}&limit=100`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching orders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const saveOrder = (data) => {
  return api.post('/api/v1/orders/project_parts_order/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful', 'Order was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getProductTypes = async (companyId, filters, projectId) => {
  const urlString = '/api/v1/product_types/';
  let urlParams = `?limit=30&get_documents=${true}&get_boms=${true}`;
  if (projectId) {
    urlParams += `&project=${projectId}`;
  }
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  let targetUrl = urlString + urlParams;
  if (filters) {
    targetUrl += `${filters}`;
  }
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedProductTypes = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching product types', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const importParts = (data, projectId, fileType) => {
  const formData = new FormData();
  if (data) {
    formData.append('file', data);
  }
  if (projectId) {
    formData.append('project', projectId);
  }
  if (fileType) {
    formData.append('file_type', fileType);
  }

  return api.post('/api/v1/projects/files/', formData)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const verifyUpload = (fileId) => {
  return api.get(`/api/v1/projects/verify/?file_id=${fileId}`)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'Invalid document type');
      return [];
    });
};

export const initUpload = (data) => {
  return api.post('/api/v1/projects/import/', data)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const getUploadStatus = (fileId, projectId) => {
  return api.get(`/api/v1/projects/files/${fileId}/?project=${projectId}`)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const getProductBoms = async (companyId, locationId, projectId, productType, parentBom) => {
  const urlString = '/api/v1/product_boms/';
  let urlParams = `?limit=100&expand_children=${true}`;

  if (projectId) {
    urlParams += `&project=${projectId}`;
  }
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  if (productType) {
    urlParams += `&product_type=${productType}`;
  }
  if (parentBom) {
    urlParams += `&parent_bom=${parentBom}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching BOM info', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getBomChildren = async (companyId, locationId, projectId, parentBom) => {
  const urlString = '/api/v1/product_boms/';
  let urlParams = `?limit=100&expand_children=${true}`;

  if (projectId) {
    urlParams += `&project=${projectId}`;
  }
  if (companyId) {
    urlParams += `&company=${companyId}`;
  }
  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  if (parentBom) {
    urlParams += `&parent_bom=${parentBom}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching BOM info', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPartLots = (locationId, companyId, projectId) => {
  return api.get(`/api/v1/projects/parts/lots/?location=${locationId}&company=${companyId}&project=${projectId}`)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const getPartCategoryGroups = (companyId) => {
  return api.get(`/api/v1/projects/part_category_groups/?company=${companyId}`)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const getPartCategories = (companyId) => {
  return api.get(`/api/v1/projects/part_categories/?company=${companyId}`)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const getProductDepartments = (locationId) => {
  return api.get(`/api/v1/departments/?location=${locationId}&is_production_department=true`)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not perform your request, please try again.');
    });
};

export const editProductType = async (companyId, id, productTypeData) => {
  return api.put(`/api/v1/product_types/${id}/?company=${companyId}`, productTypeData)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } Notification('success', 'Save successful');
      return res;
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const bulkAsignProductDepartments = async (companyId, productTypeData) => {
  return api.post(`/api/v1/bulk_assign_department/?company=${companyId}`, productTypeData);
};

export const getDmsDocuments = async (locationId) => {
  const urlString = '/api/v1/dms/documents/';
  let urlParams = '?limit=9999';
  if (locationId) {
    urlParams += `&location=${locationId}`;
  }
  const targetUrl = urlString + urlParams;
  try {
    const res = await api.get(targetUrl);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching documents', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getGanttOrders = async (companyId, filters = '') => {
  try {
    const res = await api.get(`/api/v1/orders/order_gantt/?company=${companyId}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching gantt orders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getDepartmentSteps = async (departmentId) => {
  try {
    const res = await api.get(`/api/v1/work_operations/?department=${departmentId}&limit=9999&order_by=name`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching department steps', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
