import api from 'helpers/api';
import moment from 'moment';
import { Notification } from 'shared';

export const getAssets = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?company=${companyId}&location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductivity = (companyId, locationId, filters = null) => {
  const url = `/api/v1/workforce/productivity/?company=${companyId}&location_id=${locationId}${filters}`;

  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch productivity.');
    });
};

export const getProductivityPaginated = (url) => {
  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch productivity.');
    });
};

export const getProductivityUrl = (url) => {
  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch productivity.');
    });
};

export const getDaySummary = (companyId, locationId, date = null, filters = null) => {
  let url = `/api/v1/workforce/day-summary/?company=${companyId}&location=${locationId}`;
  if (!date) {
    date = moment();
  }

  const dateFilter = date.format('YYYY-MM-DD');
  url += `&date=${dateFilter}`;

  if (filters) {
    url += filters;
  }

  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch day summary.');
    });
};

export const getWorkdays = (companyId, locationId, filters = '', limit = 40) => {
  const url = `/api/v1/workforce/workdays/?company=${companyId}&location=${locationId}&limit=${limit}${filters}`;

  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch workdays.');
    });
};

export const getWorkdaysUrl = (url) => {
  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch workdays.');
    });
};

export const getWorkerCategories = (companyId) => {
  const url = `/api/v1/workforce/worker_categories/?company=${companyId}`;

  return api.get(url)
    .then((re) => {
      return re;
    })
    .catch(() => {
      Notification('error', 'An error occurred', 'We could not fetch worker categories.');
    });
};

export const getParameterGoals = async (filters = '') => {
  const urlString = `/api/v1/workforce/parameter_goals/?${filters}`;
  try {
    const res = await api.get(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching goals', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedParameterGoals = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching goals', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addParameterGoal = (companyId, data) => {
  return api.post(`/api/v1/workforce/parameter_goals/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return Notification('success', 'Save successful', 'Goal was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editParameterGoal = (companyId, id, data) => {
  return api.put(`/api/v1/workforce/parameter_goals/${id}/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'Goal was successfully updated.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const deleteParameterGoal = (id, companyId) => {
  return api.delete(`/api/v1/workforce/parameter_goals/${id}/?company=${companyId}`)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Delete successful', 'Goal was successfully deleted.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getParameterValues = async (filters = '') => {
  const urlString = `/api/v1/workforce/parameter_values/?${filters}`;
  try {
    const res = await api.get(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching values', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getPaginatedParameterValues = async (url) => {
  try {
    const res = await api
      .get(url);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching values', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const addParameterValue = (companyId, data) => {
  return api.post(`/api/v1/workforce/parameter_values/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }
      return Notification('success', 'Save successful', 'Value was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const editParameterValue = (companyId, id, data) => {
  return api.put(`/api/v1/workforce/parameter_values/${id}/?company=${companyId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'Value was successfully updated.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const deleteParameterValue = (id, companyId) => {
  return api.delete(`/api/v1/workforce/parameter_values/${id}/?company=${companyId}`)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Delete successful', 'Value was successfully deleted.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const getDefinitions = async (filters = '') => {
  const urlString = `/api/v1/workforce/parameter_definitions/?${filters}`;
  try {
    const res = await api.get(urlString);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching definitions', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getLineGroups = async (filters = '') => {
  try {
    const res = await api.get(`/api/v1/planning/line_groups/?${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching line groups', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductionLines = async (filters = '') => {
  try {
    const res = await api.get(`/api/v1/planning/lines?${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching production lines', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
