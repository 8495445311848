import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { checkAccessOnPage, redirectToHomePage, checkModules, checkAccess } from 'industry/helpers';
import { getCurrentUserSuccess } from 'user/actions';

import BookedWorkers from './components/BookedWorkers';
import Productivity from './components/Productivity';
import './styles.scss';
import ScoringParameters from './components/ScoringParameters';

class IndustryLocationWorkforceManagement extends Component {
  constructor(props) {
    super(props);

    let isReadOnly = false;

    if (window.resourceReadOnly && window.resourceReadOnly.length) {
      window.resourceReadOnly.forEach((resource) => {
        if (window.location.href.includes(resource)) {
          isReadOnly = true;
        }
      });
    }
    this.state = {
      isReadOnly,
      selectedTab: this?.props?.history?.location?.state?.tabIndex ? this.props.history.location.state.tabIndex : 0,
      isWorkforceScoring: false,
      isScoringReadOnly: true,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;
    checkModules(companyId)
      .then((re) => {
        const modules = re.data;
        const module = modules.find((m) => m.name === 'Workforce');
        if ((module && !module.is_active) || !module) {
          redirectToHomePage(companyId, locationId);
        }
      });

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) {
          redirectToHomePage(companyId, locationId);
        } else if (access === 1) {
          // read only
          this.setState({
            isReadOnly: true,
          });
        }
      });

    checkAccess(`/${companyId}/industry/location/${locationId}/workforce-scoring`, companyId)
      .then((re) => {
        const data = re?.data;
        const access = data?.access;
        if (access === 1) {
          this.setState({
            isWorkforceScoring: true,
            isScoringReadOnly: true,
          });
        }

        if (access === 10) {
          this.setState({
            isWorkforceScoring: true,
            isScoringReadOnly: false,
          });
        }
      });
  }

  render() {
    const { isWorkforceScoring, selectedTab, isScoringReadOnly } = this.state;
    const { t, locationId, companyId, company_short_code } = this.props;

    return (
      <div className="industry-tab workforce_management_container fullscreen">
        <Tabs
          selectedIndex={selectedTab}
          onSelect={(e) => this.setState({ selectedTab: e })}
        >
          <TabList>
            <Tab>{t('page_content.workforce_management.booked_workers_tab')}</Tab>
            {isWorkforceScoring && <Tab>{company_short_code && company_short_code === 'podravka' ? t('page_content.workforce_management.scoring_tab') : t('page_content.workforce_management.productivity_tab')}</Tab>}
            {isWorkforceScoring && <Tab>{t('page_content.workforce_management.scoring_parameters')}</Tab>}
          </TabList>

          <TabPanel>
            <BookedWorkers locationId={locationId} companyId={companyId} />
          </TabPanel>

          {
            isWorkforceScoring &&
            <TabPanel>
              <Productivity
                isReadOnly={isScoringReadOnly}
              />
            </TabPanel>
          }

          {
            isWorkforceScoring &&
            <TabPanel>
              <ScoringParameters
                t={t}
                isReadOnly={isScoringReadOnly}
                companyId={companyId}
                locationId={locationId}
              />
            </TabPanel>
          }
        </Tabs>
      </div>
    );
  }
}

IndustryLocationWorkforceManagement.propTypes = {
  t: PropTypes.func.isRequired,
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  history: PropTypes.any,
  company_short_code: PropTypes.string,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
  locationId: get(state, 'app.location.id'),
  customDepartment: get(state, 'app.companyConfig.config.custom_department'),
  currentUser: state.currentUser,
  company_short_code: state.app && state.app.companyConfig && state.app.companyConfig.short_code ? state.app.companyConfig.short_code : '',
});

const mapDispatchToProps = {
  setCurrentUser: getCurrentUserSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(IndustryLocationWorkforceManagement)));
